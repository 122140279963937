import { useCallback, useEffect, useMemo, useState } from 'react'

import { useConnect } from 'redux-bundler-hook'

import { Alert, InputAdornment, Stack } from '@mui/material'

import { Field } from 'formik'
import { DateTime } from 'luxon'
import * as Yup from 'yup'

import { LoadingOverlay } from '@common/components'
import { TextField } from '@common/components/Form'
import { StaticSelect } from '@common/components/Selects'
import { merge, parseApiErrors } from '@common/utils'
import { getAdjustmentReasonsOptions } from '@common/utils/adjustmentReasonsUtils'
import FormikStatePropagator from '@portal/UI/components/FormikStatePropagator'
import { FormDialog } from '@rest/UI/components'

import {
  REPEAT_OFFENCE_SUGGESTION,
  UNBOOKED_ROOM_WARNING,
  VACANT_ROOM_WARNING,
} from './constants'

const validationSchema = Yup.object().shape({
  adjustmentAmount: Yup.number()
    .typeError('Please enter a valid amount')
    .test(
      'is-decimal',
      'Please enter a valid amount',
      (value) =>
        value === undefined ||
        value === 0 ||
        `${value}`.match(/^[1-9]\d*(\.\d{0,2})?$/),
    ),
  adjustmentReason: Yup.string().when('adjustmentAmount', {
    is: (val) => val !== '' && val !== undefined,
    then: (field) => field.required('Please select a reason for adjustment'),
    otherwise: (field) => field.nullable(),
  }),
  comments: Yup.string().when('adjustmentReason', {
    is: (val) => val === 'OTHER',
    then: (field) =>
      field
        .required('Please enter a comment for adjustment')
        .matches(/\s*(?:[\w.]\s*){10,}$/, 'Please enter at least 10 characters'),
    otherwise: (field) => field.nullable(),
  }),
})

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {Function} props.onClose
 * @param {Object} [props.instance]
 * @param {string} props.instance.id
 * @param {Object} [props.instance.smokeFeedback]
 * @param {Object} props.property
 * @param {string} props.property.id
 * @param {string[]} [props.property.expandedFlags]
 */
export default function EditFeedback({
  onClose,
  instance: { id: eventId, smokeFeedback: feedback } = {},
  property,
}) {
  const {
    doEventFeedbackSave,
    doEventFeedbackCanWaive,
    doEventFeedbackRemoveEvent,
    doShowSnackbar,
    smokeAdjustmentReasons,
  } = useConnect(
    'doEventFeedbackSave',
    'doEventFeedbackCanWaive',
    'doEventFeedbackRemoveEvent',
    'doShowSnackbar',
    'selectSmokeAdjustmentReasons',
  )

  const [formikProps, setFormikProps] = useState({
    values: {},
    setValues: () => {},
    setFieldValue: () => {},
  })
  const [initialDataLoading, setInitialDataLoading] = useState(false)
  const [showRepeatOffenceSuggestion, setShowRepeatOffenceSuggestion] = useState(false)

  const repeatOffenceSuggestionAllowed = useMemo(
    () => property?.expandedFlags?.includes('ENABLE_REPEAT_OFFENSE'),
    [property],
  )

  const unbookedRoomSuggestionAllowed = useMemo(
    () => property?.expandedFlags?.includes('ENABLE_USER_REMOVE_EVENT'),
    [property],
  )

  const isRemoveEventFlow =
    unbookedRoomSuggestionAllowed && formikProps.values.adjustmentReason === 'UNBOOKED'

  const fetchEventCanWaive = useCallback(async () => {
    try {
      setInitialDataLoading(true)
      const result = await doEventFeedbackCanWaive(eventId)
      if (result) {
        setShowRepeatOffenceSuggestion(result?.canWaive)
        setInitialDataLoading(false)
      }
    } catch (err) {
      setInitialDataLoading(false)
      const parsedError = parseApiErrors(err?.response)
      doShowSnackbar(parsedError, 'error')
    }
  }, [eventId])

  useEffect(() => {
    if (repeatOffenceSuggestionAllowed) {
      fetchEventCanWaive()
    }
  }, [repeatOffenceSuggestionAllowed, fetchEventCanWaive])

  const initialValues = merge(
    {
      adjustmentAmount: '',
      adjustmentReason: null,
      chargeAmount: '',
      comments: '',
    },
    {
      ...feedback,
      adjustmentAmount: parseFloat(feedback.adjustmentAmount)
        ? feedback.netChargeAmount
        : '',
      adjustmentReason:
        feedback.adjustmentReason === '' ? null : feedback.adjustmentReason,
    },
  )

  const adjustmentReasonsOptions = useMemo(
    () =>
      getAdjustmentReasonsOptions({
        reasons: smokeAdjustmentReasons,
        initialReason: initialValues.adjustmentReason,
      }),
    [initialValues, smokeAdjustmentReasons],
  )

  const onFeedbackSave = useCallback(
    async (data) => {
      const final = {
        ...feedback,
        ...data,
        id: feedback.id,
        adjustmentAmount: data.adjustmentAmount || 0,
        adjustmentReason: data.adjustmentReason || '',
      }

      if (data.adjustmentAmount) {
        const initialCharge = parseFloat(feedback.chargeAmount)
        const newAdjustment = parseFloat(data.adjustmentAmount)

        final.adjustmentAmount = parseFloat(initialCharge - newAdjustment).toFixed(2)

        if (final.adjustmentAmount !== parseFloat(feedback.adjustmentAmount)) {
          final.adjustedOn = DateTime.now().toISO()
        }
        if (final.adjustmentAmount === data.netChargeAmount) {
          final.adjustmentAmount = 0
        }
        final.adjustmentAmount *= -1
      }

      const result = await doEventFeedbackSave(final)
      if (result?.error) throw parseApiErrors(result)
      if (isRemoveEventFlow) {
        await doEventFeedbackRemoveEvent(eventId)
      }
      return result
    },
    [doEventFeedbackSave, feedback, eventId, isRemoveEventFlow],
  )

  return (
    <FormDialog
      open
      data-testid="edit_feedback_dialog"
      onClose={onClose}
      initialValues={initialValues}
      label="Smoking Event Feedback"
      validationSchema={validationSchema}
      onSave={onFeedbackSave}
      isSaveEnabled={!initialDataLoading}
      saveButtonText={isRemoveEventFlow ? 'Remove Event' : 'Submit'}
      successMessage="Successfully adjusted feedback information"
    >
      <>
        <LoadingOverlay loading={initialDataLoading}>
          <Stack spacing={2} mt={0}>
            {showRepeatOffenceSuggestion && (
              <Alert severity="info">{REPEAT_OFFENCE_SUGGESTION}</Alert>
            )}
            <Field
              component={TextField}
              name="chargeAmount"
              label="Original Charge Amount"
              disabled
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                disabled: true,
              }}
            />
            <Field
              component={TextField}
              name="adjustmentAmount"
              label="Adjusted Charge Amount"
              placeholder="If adjusted, enter final dollar value for this event"
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
            />
            <Field
              component={StaticSelect}
              name="adjustmentReason"
              label="Adjustment Reason"
              options={adjustmentReasonsOptions}
              defaultValue={adjustmentReasonsOptions.find(
                (reason) => reason.id === initialValues.adjustmentReason,
              )}
            />
            {isRemoveEventFlow && (
              <Alert severity="warning">{UNBOOKED_ROOM_WARNING}</Alert>
            )}
            {formikProps.values.adjustmentReason === 'VACANT' && (
              <Alert severity="warning">{VACANT_ROOM_WARNING}</Alert>
            )}
            <Field
              component={TextField}
              name="comments"
              label="Comments"
              InputProps={{ multiline: true }}
            />
          </Stack>
        </LoadingOverlay>
        <FormikStatePropagator propSetter={setFormikProps} />
      </>
    </FormDialog>
  )
}
