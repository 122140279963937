import { memo } from 'react'

import { Grid2 } from '@mui/material'

import MetricCard from '../MetricCard'
import metrics from '../metrics'

const getPositionByApiValue = (value) =>
  Object.values(metrics).find((obj) => obj.apiValue === value)?.position ?? 99

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {Object} props.smokeMetrics
 * @param {Object} props.selectedMetrics
 * @param {string} props.selectedView
 * @param {Object} props.selectedInterval
 * @param {number} props.selectedInterval.value
 * @param {string} props.selectedInterval.type
 * @param {Object[]} props.dateRangeValue
 * @param {boolean} props.showTotal
 */
const GroupedByMetricView = memo(
  ({
    smokeMetrics,
    selectedMetrics,
    selectedView,
    selectedInterval,
    dateRangeValue,
    showTotal,
  }) =>
    Object.keys(smokeMetrics)
      .sort((a, b) => {
        const aPosition = getPositionByApiValue(a)
        const bPosition = getPositionByApiValue(b)
        return aPosition > bPosition ? 1 : -1
      })
      .filter((key) => selectedMetrics[key])
      .map((key) => {
        const data = smokeMetrics[key]
        const size = selectedView === 'graph' ? { xll: 4, sm: 6 } : { xll: 6, sm: 12 }
        return (
          <Grid2 key={key} size={size}>
            <MetricCard
              metric={key}
              selectedView={selectedView}
              metricData={data}
              dataInterval={selectedInterval}
              requestedDateRange={dateRangeValue}
              showTotal={showTotal}
            />
          </Grid2>
        )
      }),
)

export default GroupedByMetricView
