import { useCallback, useEffect, useState } from 'react'

import { useConnect } from 'redux-bundler-hook'

import { Box, Typography } from '@mui/material'

import { humanize } from 'inflection'

import {
  ErrorComponent,
  MobileList,
  MobileListDefaultCard,
  Picker,
} from '@common/components'
import { StaticSelect } from '@common/components/Selects'
import { objToHash, useSmallScreen } from '@common/utils'
import deviceUrls from '@portal/pages/Devices/urls'
import DeleteEventModal from '@portal/pages/EventDashboard/Tabs/Dashboard/DeleteEventModal'
import Filter from '@portal/UI/components/Filter'
import List from '@portal/UI/components/List'

import { EventTimeCell, EventTypeCell } from './cells'

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {'Organization'|'Account'|'Property'|'Unit'|'Device'} props.entity
 * @param {React.Node} [props.customButton]
 */
export default function EventLogTab({ entity, customButton = null }) {
  const [pageSize, setPageSize] = useState('')
  const [filterModalOpen, setFilterModalOpen] = useState(false)
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false)
  const [selectedEvent, setSelectedEvent] = useState(null)
  const [selectedIds, setSelectedIds] = useState([])

  const {
    doEventsListClearParams,
    doEventsListSetFilter,
    doEventsListSetPage,
    doEventsListSetPageSize,
    doEventsListSetOrdering,
    doEventsListSetSearch,
    doUpdateHash,
    doUpdateUrl,
    eventsList,
    eventsListApiParams,
    eventsListIsLoading,
    systemEventClasses,
    systemEventTypes,
  } = useConnect(
    'doEventsListClearParams',
    'doEventsListSetFilter',
    'doEventsListSetPage',
    'doEventsListSetPageSize',
    'doEventsListSetOrdering',
    'doEventsListSetSearch',
    'doUpdateHash',
    'doUpdateUrl',
    'selectEventsList',
    'selectEventsListApiParams',
    'selectEventsListIsLoading',
    'selectSystemEventClasses',
    'selectSystemEventTypes',
  )

  const isSmallScreen = useSmallScreen()

  const initialFilter = { eventClass: 'SMOKE' }

  const handleInitialParams = () => {
    doEventsListClearParams()
    doEventsListSetFilter(initialFilter)
  }

  useEffect(() => {
    handleInitialParams()
  }, [])

  const handlePageChange = async (page) => doEventsListSetPage(page)

  const handlePageSizeChange = (size) => {
    setPageSize((prevPageSize) => (prevPageSize === size ? pageSize : size))
    doEventsListSetPageSize(size)
  }

  const handleRowClick = (row) => {
    const fifteenMinutes = 900000
    const getStart = () => new Date(new Date(row.createdOn) - fifteenMinutes).getTime()
    const getEnd = () => new Date(row.endTime).getTime()

    const hash = objToHash({
      ...(row.createdOn && { start: getStart() }),
      ...(row.endTime && { end: getEnd() }),
    })

    if (entity === 'Device') {
      doUpdateHash({ device: hash })
    } else {
      const deviceDetail = deviceUrls.entity.replace(':id', row.device)
      doUpdateUrl({ pathname: deviceDetail, hash: `device=${hash}` })
    }
  }

  const listActions = {
    bulk: [{ action: () => setDeleteConfirmationOpen(true), title: 'Remove' }],
    delete: (id) => {
      setSelectedEvent(id)
      setDeleteConfirmationOpen(true)
    },
  }

  const actionsLabels = { delete: 'Remove' }
  const clickableEventClasses = ['SMOKE', 'NOISE']

  const columns = [
    {
      field: 'eventType',
      headerName: 'Type',
      sortable: false,
      renderCell: ({ row }) => <EventTypeCell type={row.eventType} />,
      flex: 0.05,
    },
    {
      field: 'eventClass',
      headerName: 'Event',
      sortable: false,
      isMainCell: true,
      isMainCellActive: (row) => row.isRowClickable,
      valueGetter: (_, row) => humanize(row.eventClass),
      flex: 0.2,
    },
    {
      field: 'createdOn',
      sortField: 'createdOn',
      headerName: 'Event Start/Duration',
      renderCell: ({ row }) => <EventTimeCell event={row} />,
      flex: 0.3,
    },
    {
      field: 'deviceSerial',
      headerName: 'Device',
      sortable: false,
      flex: 0.25,
    },
    {
      field: 'zoneName',
      headerName: 'Zone',
      sortable: false,
      flex: 0.25,
    },
    ...(entity === 'Property'
      ? [
          {
            field: 'unitName',
            headerName: 'Unit',
            sortable: false,
            flex: 0.25,
          },
        ]
      : []),
    ...(entity === 'Account'
      ? [
          {
            field: 'propertyName',
            headerName: 'Property',
            sortable: false,
            flex: 0.25,
          },
        ]
      : []),
    ...(entity === 'Organization'
      ? [
          {
            field: 'accountName',
            headerName: 'Account',
            sortable: false,
            flex: 0.25,
          },
        ]
      : []),
  ]

  const rows = eventsList?.results?.map((event) => ({
    id: event.id,
    eventClass: event.eventClass,
    eventType: event.eventType,
    createdOn: event.createdOn,
    endTime: event.endTime,
    organization: event.organization,
    organizationName: event.organizationName,
    property: event.property,
    propertyName: event.propertyName,
    unit: event.unit,
    unitName: event.unitName,
    zone: event.zone,
    zoneName: event.zoneName,
    device: event.device,
    deviceSerial: event.deviceSerial,
    occurrences: event.occurrences,
    actionableSeries: event.actionableSeries,
    parentEvent: event.parentEvent,
    smokeFeedback: event.smokeFeedback,
    timeZone: event.timeZone,
    isRowClickable: clickableEventClasses.includes(event.eventClass) && event.device,
  }))

  const MobileItemHeader = useCallback(
    ({ row }) => (
      <Box display="flex" alignItems="center" sx={{ '&&': { mb: 1 } }}>
        <Typography
          variant="caption"
          fontWeight="bold"
          color={row.device ? 'primary' : null}
          sx={{ textDecoration: row.device ? 'underline' : 'none', mr: 0.5 }}
          onClick={row.isRowClickable ? () => handleRowClick(row) : null}
        >
          {humanize(row.eventClass)}
        </Typography>
        <EventTypeCell type={row.eventType} />
      </Box>
    ),
    [],
  )

  const MobileListItem = useCallback(
    (row) =>
      MobileListDefaultCard({
        row,
        columns,
        ignoredFields: ['eventClass', 'eventType'],
        header: <MobileItemHeader row={row} />,
      }),
    [columns],
  )

  const eventIds = selectedEvent ? [selectedEvent] : selectedIds

  if (!eventsList.results)
    return <ErrorComponent title={`${entity} Events`} callback={handleInitialParams} />

  return (
    <>
      <DeleteEventModal
        open={deleteConfirmationOpen}
        eventIds={eventIds}
        onClose={() => {
          setDeleteConfirmationOpen(false)
          setSelectedEvent(null)
          setSelectedIds([])
        }}
      />
      <Box display="flex">
        <Box display="flex" marginRight={isSmallScreen ? 0 : 1} marginTop={1.5}>
          <Filter
            mode={isSmallScreen ? 'modal' : 'drawer'}
            disabled={eventsListIsLoading}
            apiParams={eventsListApiParams}
            setApiParams={doEventsListSetFilter}
            immutableFilters={initialFilter}
            dialogOpen={filterModalOpen}
            dialogOnClose={() => setFilterModalOpen(false)}
          >
            <StaticSelect
              label="Event Classes"
              filterName="eventClass"
              options={systemEventClasses.map((item) => ({
                id: item.value,
                label: item.name,
              }))}
            />
            <StaticSelect
              label="Event Types"
              filterName="eventType"
              options={systemEventTypes.map((item) => ({
                id: item.value,
                label: item.name,
              }))}
            />
            <Picker
              range
              type="dateTime"
              label="Created at"
              filterName="createdOn"
              lowerCondition="After"
              upperCondition="Before"
              conditionSeparator=""
            />
          </Filter>
        </Box>
        <Box flex={1} height="100%" overflow="hidden" sx={{ minHeight: '500px' }}>
          {isSmallScreen ? (
            <MobileList
              title="Event Log"
              loading={eventsListIsLoading}
              itemBuilder={MobileListItem}
              actions={listActions}
              actionsLabels={actionsLabels}
              listActions={
                selectedIds?.length
                  ? [
                      {
                        label: 'Remove',
                        onClick: () => setDeleteConfirmationOpen(true),
                      },
                    ]
                  : null
              }
              onFilterPressed={() => setFilterModalOpen(true)}
              page={eventsList.current || 1}
              pageChange={handlePageChange}
              pageSize={eventsList.pageSize}
              pageSizeChange={handlePageSizeChange}
              rows={rows}
              rowClick={handleRowClick}
              rowCount={eventsList.count || 0}
              setSearch={doEventsListSetSearch}
              rowSelectionModel={selectedIds}
              onRowSelectionModelChange={setSelectedIds}
            />
          ) : (
            <List
              columnsAutosize
              title="Event Log"
              loading={eventsListIsLoading}
              showAddButton={!!customButton}
              customAddButton={customButton}
              columns={columns}
              actions={listActions}
              actionsLabels={actionsLabels}
              page={eventsList.current || 1}
              pageChange={handlePageChange}
              pageSize={eventsList.pageSize}
              pageSizeChange={handlePageSizeChange}
              rows={rows}
              rowClick={handleRowClick}
              rowCount={eventsList.count || 0}
              setSearch={doEventsListSetSearch}
              sortChange={doEventsListSetOrdering}
              rowSelectionModel={selectedIds}
              onRowSelectionModelChange={setSelectedIds}
            />
          )}
        </Box>
      </Box>
    </>
  )
}
