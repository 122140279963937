import { Box, CircularProgress } from '@mui/material'

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {boolean} [props.loading]
 * @param {React.Node} [props.children]
 */
export default function LoadingOverlay({ loading = false, children }) {
  return (
    <Box display="flex" alignItems="center" justifyContent="center">
      {loading && <CircularProgress sx={{ position: 'absolute' }} />}
      <Box
        sx={{
          width: '100%',
          transitionProperty: 'opacity',
          transitionDuration: '300ms',
          opacity: loading ? 0.35 : 1,
          pointerEvents: loading ? 'none' : 'initial',
        }}
      >
        {children}
      </Box>
    </Box>
  )
}
