import { useMemo } from 'react'

import { StaticMultiSelect } from '@common/components/Selects'

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {Object[]} props.typeFilter
 * @param {Function} props.setTypeFilter
 * @param {Function} props.formatType
 * @param {boolean} props.isLoading
 * @param {Function} props.onSave
 */
export default function TypeFilter({
  typeFilter,
  setTypeFilter,
  formatType,
  isLoading,
  onSave,
}) {
  const typeOptions = useMemo(
    () =>
      ['command_bit', 'device_event', 'firmware_ota'].map((key) => ({
        id: key,
        label: formatType(key),
      })),
    [],
  )

  return (
    <StaticMultiSelect
      label="Type"
      options={typeOptions}
      value={typeFilter}
      disabled={isLoading}
      onChange={setTypeFilter}
      onSave={onSave}
      size="small"
      limitTags={2}
      sx={{ minWidth: 300 }}
    />
  )
}
