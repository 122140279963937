import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'

import AuditLogTab from '@portal/UI/components/AuditLogTab'

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {string} [props.issueId]
 * @param {boolean} props.open
 * @param {Function} props.onClose
 */
export default function IssuesAuditLog({ open, onClose, issueId = undefined }) {
  return (
    <Dialog
      fullWidth
      open={open}
      onClose={onClose}
      maxWidth="lg"
      PaperProps={{ sx: { minHeight: '700px' } }}
    >
      <DialogTitle sx={{ fontSize: '1.7rem' }}>Issue Audit Log</DialogTitle>
      <DialogContent>
        <AuditLogTab excludeDateFilter objectId={issueId} filterDrawOpen={false} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  )
}
