import { useState } from 'react'

import { pick } from 'ramda'

import { AddCircle as AddCircleIcon } from '@mui/icons-material'
import { Box, Button, Chip, IconButton, TextField } from '@mui/material'

import UnitRateCodeForm from './UnitRateCodeForm'

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {Object} props.values
 * @param {Object} props.property
 * @param {string} [props.values.smokeAutocharge]
 * @param {Object} [props.values.smokeAutochargeParams]
 * @param {Object} [props.values.smokeAutochargeParams.rateCodes]
 * @param {Function} props.setValues
 */
export default function RateCodeTab({ values, setValues, property }) {
  const [newRateCode, setNewRateCode] = useState('')
  const [newRateCodeValue, setNewRateCodeValue] = useState('')
  const [rateCodeError, setRateCodeError] = useState(false)
  const [unitRateCodeFormOpen, setUnitRateCodeFormOpen] = useState(false)

  const rateCodes = values.smokeAutochargeParams?.rateCodes

  const handleDelete = (key) => {
    const currentKeys = Object.keys(rateCodes).filter((code) => code !== key)
    setValues({
      ...values,
      smokeAutochargeParams: {
        ...values.smokeAutochargeParams,
        rateCodes: pick(currentKeys, rateCodes),
      },
    })
  }

  const handleAddRateCode = () => {
    if (newRateCode in rateCodes) {
      setRateCodeError(true)
      return
    }
    setRateCodeError(false)
    setValues({
      ...values,
      smokeAutochargeParams: {
        ...values.smokeAutochargeParams,
        rateCodes: {
          ...rateCodes,
          [newRateCode]: newRateCodeValue,
        },
      },
    })
    setNewRateCode('')
    setNewRateCodeValue('')
  }

  return (
    <>
      <UnitRateCodeForm
        property={property}
        rateCodes={rateCodes}
        open={unitRateCodeFormOpen}
        onClose={() => setUnitRateCodeFormOpen(false)}
      />
      <Box
        gap={3}
        pt={2}
        display="flex"
        flexDirection="column"
        minHeight={65}
        justifyContent="flex-end"
      >
        <Box display="flex" flexWrap="wrap" gap={1}>
          {Object.entries(rateCodes).map(([key, value]) => (
            <Box key={key} display="flex" alignItems="center" gap={1}>
              <Chip
                color="secondary"
                label={`${key}: $${value}`}
                onDelete={() => handleDelete(key)}
                sx={{ fontSize: 15, fontWeight: 'bold' }}
              />
            </Box>
          ))}
        </Box>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="flex-end"
          width="100%"
          gap={2}
        >
          <TextField
            fullWidth
            variant="standard"
            onChange={(e) => setNewRateCode(e.target.value.toUpperCase().trim())}
            label="Add rate code"
            placeholder="Enter unique code"
            value={newRateCode}
            error={rateCodeError}
            helperText={rateCodeError ? 'That code already exists' : ''}
            sx={{ flex: 1 }}
          />
          <TextField
            fullWidth
            variant="standard"
            type="number"
            onChange={(e) => {
              setNewRateCodeValue(e.target.value === '' ? '' : Number(e.target.value))
            }}
            label="Add value"
            value={newRateCodeValue}
            sx={{ flex: 0.8 }}
          />
          <IconButton
            size="small"
            onClick={handleAddRateCode}
            disabled={newRateCodeValue === '' || newRateCode === ''}
          >
            <AddCircleIcon
              color={
                newRateCodeValue === '' || newRateCode === '' ? 'disabled' : 'success'
              }
            />
          </IconButton>
        </Box>
        <Button
          onClick={() => setUnitRateCodeFormOpen(true)}
          endIcon={<AddCircleIcon />}
        >
          Assign Rate Code to Units
        </Button>
      </Box>
    </>
  )
}
