import { useMemo } from 'react'

import { sum, uniq } from 'ramda'

import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'

import { formatMetricValue, getMetricsDateRange } from '@common/utils'

const stickyStyle = {
  position: 'sticky',
  left: 0,
  zIndex: 800,
  background: 'white',
  maxWidth: 340,
}

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {string} props.id
 * @param {Object} props.data
 * @param {Object[]} props.data.data
 * @param {string} props.data.data[].metric
 * @param {Object[]} props.data.data[].data
 * @param {string} props.data.data[].symbol
 * @param {string} props.data.data[].symbolPosition
 * @param {Object} props.data.summary
 * @param {Object} props.systemMetric
 * @param {string} [props.systemMetric.template]
 */
export default function MetricTable({ id, data: { data, summary } }) {
  const { headerDates, propertyDateMap, dateValues, summaryValues } = useMemo(() => {
    const dateMap = {}
    const entityValues = {}

    const dates = uniq(
      data.reduce((acc, entity) => {
        acc.push(
          ...entity.data.map((d) => {
            dateMap[`${entity.key}|${d.date}`] = d.value
            if (!(d.date in entityValues)) {
              entityValues[d.date] = []
            }
            entityValues[d.date].push(d.value)
            return {
              start: d.date,
              end: d.bucketEndDate,
              displayData: d.displayData,
            }
          }),
        )
        return acc
      }, []),
    ).sort()

    const processedSummaryData = summary?.data?.reduce(
      (acc, item) => ({
        ...acc,
        [item.date]: [item.value],
      }),
      {},
    )

    return {
      headerDates: dates,
      propertyDateMap: dateMap,
      dateValues: entityValues,
      summaryValues: processedSummaryData,
    }
  }, [data])

  const calculatePercentageDifference = (oldValue, newValue) =>
    ((newValue - oldValue) / ((Math.abs(oldValue) + Math.abs(newValue)) / 2)) * 100

  const getDiffData = (prevValue, currentValue) => {
    const formatValue = (value) => {
      if (Number.isNaN(value)) return 0
      if (!Number.isFinite(value)) return 100
      return Math.abs(value.toFixed(1))
    }

    const diff =
      typeof prevValue === 'number' && typeof currentValue === 'number'
        ? calculatePercentageDifference(prevValue, currentValue)
        : null
    if (diff === null) {
      return null
    }

    let color = 'teal'
    let icon = ''

    if (diff > 0) {
      icon = '↑'
      color = 'green'
    } else if (diff < 0) {
      icon = '↓'
      color = 'red'
    }

    return {
      formattedValue: formatValue(diff),
      icon,
      color,
    }
  }

  const getPrevDiffItem = (i) => {
    let prevItem = i !== 0 ? headerDates[i - 1] : null
    if (prevItem !== null && !!prevItem.displayData?.showSuffix) {
      prevItem = i !== 0 ? headerDates.at(i - 2) : null
    }
    return prevItem
  }

  const { symbol, symbolPosition } = data[0]

  return (
    <TableContainer data-testid={`${id}_table_card`} component={Paper} sx={{ mt: 2 }}>
      <Table stickyHeader size="small">
        <TableHead>
          <TableRow>
            <TableCell style={stickyStyle}>Property</TableCell>
            {headerDates.map((pointDate) => {
              const displayPointDate = (() => {
                const { displayData } = pointDate
                if (displayData) {
                  return displayData.end
                }
                return pointDate.end ?? pointDate.start
              })()
              const displayBucketStart = (() => {
                const { displayData } = pointDate
                if (displayData) {
                  return displayData.start
                }
                return pointDate.end ? pointDate.start : null
              })()

              const suffix = (() => {
                const { displayData } = pointDate
                if (!displayData || !displayData.showSuffix) {
                  return null
                }
                return displayData.isProjected ? 'projected' : 'actual'
              })()

              return (
                <TableCell
                  key={pointDate.start}
                  style={{ whiteSpace: 'nowrap', textAlign: 'center' }}
                >
                  {getMetricsDateRange(displayPointDate, displayBucketStart, suffix)}
                </TableCell>
              )
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((d) => (
            <TableRow key={d.name}>
              <TableCell style={stickyStyle}>{d.name}</TableCell>
              {headerDates.map((hd, i) => {
                const prevItem = getPrevDiffItem(i)
                const prevValue = prevItem
                  ? propertyDateMap?.[`${d.key}|${prevItem.start}`]
                  : null
                const currentValue = propertyDateMap?.[`${d.key}|${hd.start}`]
                const diffData = getDiffData(prevValue, currentValue)

                return (
                  <TableCell key={hd.start} style={{ textAlign: 'right' }}>
                    <Box display="flex" justifyContent="flex-end">
                      {formatMetricValue({
                        value: currentValue,
                        symbol: d.symbol,
                        position: d.symbolPosition,
                      })}
                      {diffData && (
                        <Typography
                          display="flex"
                          color={diffData.color}
                          sx={{ fontSize: 13, ml: 1 }}
                        >{`(${diffData.icon}${diffData.formattedValue}%)`}</Typography>
                      )}
                    </Box>
                  </TableCell>
                )
              })}
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TableCell style={stickyStyle}>Average</TableCell>
            {headerDates.map((hd, i) => {
              const dataSource = symbol === '%' ? summaryValues : dateValues

              const prevItem = getPrevDiffItem(i)
              const prevValue = prevItem
                ? sum(dataSource[prevItem.start]) / dataSource[prevItem.start].length
                : null
              const currentValue =
                sum(dataSource[hd.start]) / dataSource[hd.start].length
              const diffData = getDiffData(prevValue, currentValue)

              return (
                <TableCell key={hd.start} style={{ textAlign: 'right' }}>
                  <Box display="flex" justifyContent="flex-end" alignItems="center">
                    {formatMetricValue({
                      value: currentValue,
                      symbol,
                      position: symbolPosition,
                    })}
                    {diffData && (
                      <Typography
                        display="flex"
                        color={diffData.color}
                        sx={{ fontSize: 12, ml: 1, opacity: 0.65 }}
                      >{`(${diffData.icon}${diffData.formattedValue}%)`}</Typography>
                    )}
                  </Box>
                </TableCell>
              )
            })}
          </TableRow>
          {symbol !== '%' ? (
            <TableRow>
              <TableCell style={stickyStyle}>Total</TableCell>
              {headerDates.map((hd, i) => {
                const prevItem = getPrevDiffItem(i)
                const prevValue = prevItem ? sum(dateValues[prevItem.start]) : null
                const currentValue = sum(dateValues[hd.start])
                const diffData = getDiffData(prevValue, currentValue)
                return (
                  <TableCell key={hd.start} style={{ textAlign: 'right' }}>
                    <Box display="flex" justifyContent="flex-end" alignItems="center">
                      {formatMetricValue({
                        value: currentValue,
                        symbol,
                        position: symbolPosition,
                      })}
                      {diffData && (
                        <Typography
                          display="flex"
                          color={diffData.color}
                          sx={{ fontSize: 12, ml: 1, opacity: 0.65 }}
                        >{`(${diffData.icon}${diffData.formattedValue}%)`}</Typography>
                      )}
                    </Box>
                  </TableCell>
                )
              })}
            </TableRow>
          ) : null}
        </TableFooter>
      </Table>
    </TableContainer>
  )
}
