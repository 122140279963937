import convert from 'color-convert'

import {
  CATEGORY_CO2_TVOC,
  CATEGORY_ENVIRONMENT,
  CATEGORY_MODEL_SCORES,
  CATEGORY_PM,
  CATEGORY_PM_RATIOS,
  CATEGORY_SOUND_LEVELS,
  CATEGORY_VOC_NOX,
} from '@common/utils/grafanaViewDataProcessor'

export const GRAFANA_GREEN = '#63b556'
export const GRAFANA_YELLOW = '#eec30f'
export const GRAFANA_BLUE = '#78a7ff'
export const GRAFANA_ORANGE = '#fc620d'
export const GRAFANA_RED = '#ec304a'
export const GRAFANA_DARK_BLUE = '#4a6fb5'
export const GRAFANA_DARK_GREEN = '#21914a'

const generateColors = () => {
  const generatedColors = []
  const hueIncrement = 360 / 30
  let currentHue = 0

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < 30; i++) {
    const randomSaturation = 50 + Math.random() * 50
    const randomBrightness = 50 + Math.random() * 30
    const color = convert.hsl.hex([currentHue, randomSaturation, randomBrightness])
    generatedColors.push(`#${color}`)
    currentHue += hueIncrement
  }

  return generatedColors
}

export const eventTypesColorsPool = [
  GRAFANA_GREEN,
  GRAFANA_YELLOW,
  GRAFANA_BLUE,
  GRAFANA_ORANGE,
  GRAFANA_RED,
  ...generateColors(),
]

const eventTypesMeta = {
  mc_1p0_mean: {
    key: 'mc_1p0_mean',
    systemName: 'mc_1p0_mean',
    tooltipLabel: 'PM 0.3-1.0',
    color: GRAFANA_GREEN,
    category: CATEGORY_PM,
    visible: true,
  },
  mc_2p5_mean: {
    key: 'mc_2p5_mean',
    systemName: 'mc_2p5_mean',
    tooltipLabel: 'PM 1.0-2.5',
    color: GRAFANA_YELLOW,
    category: CATEGORY_PM,
    visible: true,
  },
  mc_4p0_mean: {
    key: 'mc_4p0_mean',
    systemName: 'mc_4p0_mean',
    tooltipLabel: 'PM 2.5-4.0',
    color: GRAFANA_BLUE,
    category: CATEGORY_PM,
    visible: true,
  },
  mc_10p0_mean: {
    key: 'mc_10p0_mean',
    systemName: 'mc_10p0_mean',
    tooltipLabel: 'PM 4.0-10.0',
    color: GRAFANA_ORANGE,
    category: CATEGORY_PM,
    visible: true,
  },
  tpm_mean: {
    key: 'tpm_mean',
    systemName: 'tpm_mean',
    tooltipLabel: 'particle size',
    color: GRAFANA_RED,
    category: CATEGORY_PM,
    unit: 'particle size',
    visible: true,
  },

  nrs: {
    key: 'nrs',
    systemName: 'nrs',
    tooltipLabel: 'nrs',
    color: GRAFANA_GREEN,
    category: CATEGORY_SOUND_LEVELS,
    unit: 'NRS',
    visible: true,
  },
  dba_mean: {
    key: 'dba_mean',
    systemName: 'dba_mean',
    tooltipLabel: 'dba_mean',
    color: GRAFANA_YELLOW,
    category: CATEGORY_SOUND_LEVELS,
    visible: true,
  },

  mc_1p0_ratio: {
    key: 'mc_1p0_ratio',
    color: GRAFANA_GREEN,
    tooltipLabel: 'PM 0.3-1.0',
    category: CATEGORY_PM_RATIOS,
    unit: '%',
    template: '{{ value }} %',
    visible: true,
    fillArea: true,
  },
  mc_2p5_ratio: {
    key: 'mc_2p5_ratio',
    color: GRAFANA_YELLOW,
    tooltipLabel: 'PM 1.0-2.5',
    category: CATEGORY_PM_RATIOS,
    unit: '%',
    template: '{{ value }} %',
    visible: true,
    fillArea: true,
  },
  mc_4p0_ratio: {
    key: 'mc_4p0_ratio',
    color: GRAFANA_BLUE,
    tooltipLabel: 'PM 2.5-4.0',
    category: CATEGORY_PM_RATIOS,
    unit: '%',
    template: '{{ value }} %',
    visible: true,
    fillArea: true,
  },
  mc_10p0_ratio: {
    key: 'mc_10p0_ratio',
    color: GRAFANA_ORANGE,
    tooltipLabel: 'PM 4.0-10.0',
    category: CATEGORY_PM_RATIOS,
    unit: '%',
    template: '{{ value }} %',
    visible: true,
    fillArea: true,
  },

  temp: {
    key: 'temp',
    systemName: 'temp_mean',
    tooltipLabel: 'temp',
    color: GRAFANA_YELLOW,
    category: CATEGORY_ENVIRONMENT,
    visible: true,
  },
  rel_hum: {
    key: 'rel_hum',
    systemName: 'hum_mean',
    tooltipLabel: 'rel_hum',
    color: GRAFANA_GREEN,
    category: CATEGORY_ENVIRONMENT,
    visible: true,
  },
  abs_hum: {
    key: 'abs_hum',
    tooltipLabel: 'abs_hum',
    color: GRAFANA_BLUE,
    category: CATEGORY_ENVIRONMENT,
    unit: 'g/m³',
    template: '{{ value }} g/m³',
    visible: true,
  },

  tvoc_mean: {
    key: 'tvoc_mean',
    systemName: 'tvoc_mean',
    tooltipLabel: 'tvoc',
    color: GRAFANA_YELLOW,
    category: CATEGORY_CO2_TVOC,
    visible: true,
  },
  eco2_mean: {
    key: 'eco2_mean',
    systemName: 'eco2_mean',
    tooltipLabel: 'co2',
    color: GRAFANA_GREEN,
    category: CATEGORY_CO2_TVOC,
    visible: true,
  },

  voc_mean: {
    key: 'voc_mean',
    systemName: 'voc_mean',
    tooltipLabel: 'voc',
    color: GRAFANA_GREEN,
    category: CATEGORY_VOC_NOX,
    visible: true,
  },
  nox_mean: {
    key: 'nox_mean',
    systemName: 'nox_mean',
    tooltipLabel: 'nox',
    color: GRAFANA_YELLOW,
    category: CATEGORY_VOC_NOX,
    visible: true,
  },
}

export function getEventTypesMeta(systemDataTypes, systemSmokeProfiles, extraTypes) {
  const reversedProfiles = [...systemSmokeProfiles].reverse()
  const profilesMeta = reversedProfiles.reduce(
    (acc, profile, i) => ({
      ...acc,
      [profile.id]: {
        key: profile.id,
        tooltipLabel: `${profile.modelKey} - ${profile.name}`,
        color: eventTypesColorsPool[i % eventTypesColorsPool.length],
        category: CATEGORY_MODEL_SCORES,
        unit: '%',
        template: '{{ value }} %',
        visible: true,
        scatter: true,
        scatterShowNull: true,
        scatterNullRenderValue: 50,
        scatterNullRenderStyle: { color: 'red', radius: 4 },
      },
    }),
    {},
  )

  const smokeModelsMeta =
    extraTypes?.reduce(
      (acc, data, i) => ({
        ...acc,
        [data.type]: {
          key: data.type,
          tooltipLabel: data.type,
          color: eventTypesColorsPool[i % eventTypesColorsPool.length],
          category: data.category,
          unit: '',
          template: '{{ value }}',
          visible: true,
          scatter: false,
        },
      }),
      {},
    ) ?? {}

  return Object.fromEntries(
    Object.entries({ ...eventTypesMeta, ...profilesMeta, ...smokeModelsMeta }).map(
      ([k, v]) => {
        const systemDataType = systemDataTypes.find((type) =>
          type.key.startsWith(v.systemName),
        )
        let { template, unit } = v
        if (systemDataType) {
          template ??= systemDataType.template
          unit ??= systemDataType.template.replace('{{ value }}', '').trim()
          if (unit.startsWith('/')) {
            unit = `#${unit}`
          }
        }
        return [k, { ...v, unit, template }]
      },
    ),
  )
}
