import { Card, Typography } from '@mui/material'

import MetricTable from './MetricTable'

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {string} props.dataKey
 * @param {Object} props.data
 * @param {string} props.data.metric
 * @param {Object[]} props.data.data
 * @param {Object} props.selectedMetrics
 * @param {Object} [props.sx]
 */
export default function EntityCard({ dataKey, data, selectedMetrics, sx = {} }) {
  const cardTitle = dataKey?.split(':').at(-1) ?? '--'

  return (
    <Card key={dataKey} sx={sx}>
      <Typography variant="body2" ml={2} mt={2}>
        {cardTitle}
      </Typography>
      {/* pass only data, summary is not available for entity view */}
      <MetricTable data={{ data }} groupBy="entity" selectedMetrics={selectedMetrics} />
    </Card>
  )
}
