import { useCallback, useEffect, useState } from 'react'

import { useConnect, useReduxBundlerStore } from 'redux-bundler-hook'

import { Alert, Box, Button, Link, Typography } from '@mui/material'

import { Field, Form, Formik } from 'formik'
import * as Yup from 'yup'

import logo from '@assets/logo_dark.svg'
import TextField from '@common/components/Form/TextField'
import { getApiFetch, useSmallScreen } from '@common/utils'

import { loginBoxStyles, loginFormStyles, loginLogoStyles } from './login.styles'
import loginUrls from './urls'

export default function ForgotPassword() {
  const [error, setError] = useState()
  const [emailSent, setEmailSent] = useState(false)
  const [statusMessage, setStatusMessage] = useState('')
  const { meIsLoading, routeQuery } = useConnect(
    'selectMeIsLoading',
    'selectRouteQuery',
  )

  const isSmallScreen = useSmallScreen()
  const store = useReduxBundlerStore()
  const apiFetch = getApiFetch(store)

  const validationSchema = Yup.object().shape({
    email: Yup.string().email('Please enter a valid email').required(''),
  })

  useEffect(() => {
    if (routeQuery.tokenExpired === '1') {
      setError(`
        Your link to reset your password has expired or been used.
        For your security, email links expire after 48 hours.
        Please submit the form below to receive a new link.
      `)
    }
  }, [routeQuery])

  const handleFormSubmit = useCallback(
    async (values) => {
      setError(null)
      const result = await apiFetch('/password/forgot/', values, {
        method: 'POST',
        useAuth: false,
        useOrgHeader: false,
        useAccountHeader: false,
      })

      if (result?.error?.response?.detail) {
        setError(result.error.response.detail)
        return false
      }
      setStatusMessage(result?.detail?.join(' '))
      setEmailSent(true)
      return true
    },
    [apiFetch],
  )

  const width = isSmallScreen ? '100%' : loginFormStyles.width

  return (
    <Box style={loginBoxStyles}>
      <img alt="Rest" src={logo} style={loginLogoStyles} />
      <Box width={width} align="center" mb={3}>
        <Typography variant="h6">Please enter your email address.</Typography>
      </Box>
      <Formik
        initialValues={{
          email: '',
        }}
        validationSchema={validationSchema}
        validateOnMount
        onSubmit={handleFormSubmit}
      >
        {({ isSubmitting, isValid }) => (
          <Form style={{ ...loginFormStyles, width }}>
            {error && (
              <Alert data-testid="forgot-pw-alert" severity="error">
                {error}
              </Alert>
            )}
            <Field
              data-testid="forgot-pw-email"
              component={TextField}
              name="email"
              label="Email"
              placeholder="Email"
              disabled={emailSent}
            />
            <Button
              data-testid="forgot-pw-submit"
              variant="contained"
              color="primary"
              type="submit"
              disabled={!isValid || isSubmitting || meIsLoading || emailSent}
            >
              Request password reset link
            </Button>
          </Form>
        )}
      </Formik>
      <Box mt={3} align="center">
        <Typography data-testid="forgot-pw-status" variant="h6">
          {statusMessage}
        </Typography>
        <Link data-testid="login-nav-link" href={loginUrls.login}>
          Click here to return to the login page
        </Link>
      </Box>
    </Box>
  )
}
