import { useConnect } from 'redux-bundler-hook'

import {
  AccountBalanceOutlined as AccountBalanceIcon,
  ApartmentOutlined as ApartmentIcon,
  AttachMoney as AttachMoneyIcon,
  Dashboard as MetricsDashboardIcon,
  DashboardOutlined as DashboardIcon,
  DescriptionOutlined as ScenariosIcon,
  DeviceHubOutlined as DeviceHubIcon,
  GroupsOutlined as GroupsIcon,
  House as HouseIcon,
  ListAlt as ListAltIcon,
  PendingActions as PendingActionsIcon,
  PlayArrowRounded as PlayIcon,
  SmokingRooms as SmokingRoomsIcon,
  Store as StoreIcon,
  WarningAmberRounded as IssuesIcon,
} from '@mui/icons-material'
import { Box } from '@mui/material'
import Divider from '@mui/material/Divider'
import List from '@mui/material/List'

import logo from '@assets/logo.svg'
import logoBug from '@assets/logo_bug.svg'
import { Loading } from '@common/components'
import { ShipBob } from '@common/icons'
import { useSmallScreen } from '@common/utils'
import accountUrls from '@portal/pages/Accounts/urls'
import deviceUrls from '@portal/pages/Devices/urls'
import eventUrls from '@portal/pages/EventDashboard/urls'
import financeUrls from '@portal/pages/Finance/urls'
import homeUrls from '@portal/pages/Home/urls'
import issuesUrls from '@portal/pages/Issues/urls'
import orgUrls from '@portal/pages/Organizations/urls'
import propertyUrls from '@portal/pages/Properties/urls'
import propertySmokeProfilesUrls from '@portal/pages/PropertySmokeProfiles/urls'
import scenarioUrls from '@portal/pages/Scenarios/urls'
import scheduledReportPrefUrls from '@portal/pages/ScheduledReportPreferences/urls'
import sensorTestingUrls from '@portal/pages/SensorTesting/urls'
import shipbobOrdersUrls from '@portal/pages/ShipbobOrders/urls'
import metricUrls from '@portal/pages/SmokeMetrics/urls'
import smokeProfilesUrls from '@portal/pages/SmokeProfiles/urls'
import unitUrls from '@portal/pages/Units/urls'
import userUrls from '@portal/pages/Users/urls'

import { smokeProfileEnsemblesUrls } from '../SmokeProfileEnsembles'
import { LeftDrawerButton, RightDrawerButton } from './DrawerButton'
import { Drawer, DrawerHeader, logoStyles, miniLogo } from './Sidebar.styles'
import SidebarMenuItem from './SidebarMenuItem'
import UserMenu from './UserMenu'

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {Function} props.handleDrawer
 * @param {boolean} props.open
 */
export default function Sidebar({ handleDrawer, open }) {
  const isSmallScreen = useSmallScreen()

  const {
    me,
    routeInfo: { url: currentURL },

    doCloseDrawer,
  } = useConnect('selectMe', 'selectRouteInfo', 'doCloseDrawer')

  if (!me) {
    // we can do better than this
    return <Loading />
  }

  const topMenuOptions = [
    {
      icon: DashboardIcon,
      label: 'Dashboard',
      href: homeUrls.home,
      permissions: [],
    },
    {
      icon: IssuesIcon,
      label: 'Issues',
      href: issuesUrls.list,
      permissions: [],
    },
    {
      icon: GroupsIcon,
      label: 'Users',
      href: userUrls.list,
      permissions: [],
    },
    {
      icon: StoreIcon,
      label: 'Organizations',
      href: orgUrls.list,
      permissions: [],
    },
    {
      icon: AccountBalanceIcon,
      label: 'Accounts',
      href: accountUrls.list,
      permissions: [],
    },
    {
      icon: HouseIcon,
      label: 'Properties',
      href: propertyUrls.list,
      permissions: [],
    },
    {
      icon: ApartmentIcon,
      label: 'Units',
      href: unitUrls.list,
      permissions: [],
    },
    {
      icon: DeviceHubIcon,
      label: 'Devices',
      href: deviceUrls.list,
      permissions: [],
    },
    {
      icon: PendingActionsIcon,
      label: 'Scheduled Reports',
      href: scheduledReportPrefUrls.list,
      permissions: [],
    },
    {
      icon: ListAltIcon,
      label: 'Smoke Profiles',
      subitems: [
        {
          label: 'Ensembles List',
          href: smokeProfileEnsemblesUrls.list,
        },
        {
          label: 'Profiles List',
          href: smokeProfilesUrls.list,
        },
        {
          label: 'Properties List',
          href: propertySmokeProfilesUrls.list,
        },
      ],
      permissions: [],
    },
    {
      icon: MetricsDashboardIcon,
      label: 'Metrics Dashboard',
      href: metricUrls.metrics,
      permissions: [],
    },
    {
      icon: SmokingRoomsIcon,
      label: 'Event Dashboard',
      href: eventUrls.list,
      permissions: [],
    },
    {
      icon: ScenariosIcon,
      label: 'Scenarios',
      href: scenarioUrls.list,
      permissions: [],
    },
    {
      icon: ShipBob,
      label: 'ShipBob Orders',
      href: shipbobOrdersUrls.list,
      permissions: [],
    },
    {
      icon: AttachMoneyIcon,
      label: 'Finance',
      href: financeUrls.dashboard,
      permissions: [],
    },
    {
      icon: PlayIcon,
      label: 'Sensor Testing',
      href: sensorTestingUrls.testing,
      permissions: [],
    },
  ]

  return (
    <Drawer
      variant={isSmallScreen ? 'temporary' : 'permanent'}
      anchor="left"
      open={open}
      PaperProps={{
        sx: {
          backgroundColor: 'background.dark',
          color: 'white',
        },
      }}
      sx={{ direction: 'rtl' }}
      onClose={doCloseDrawer}
    >
      <Box
        sx={{
          direction: 'ltr',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
        }}
      >
        <DrawerHeader>
          {open ? (
            <>
              <img src={logo} alt="Rest Potal" style={logoStyles} />
              {!isSmallScreen && (
                <LeftDrawerButton leftOpen={open} handleDrawer={handleDrawer} />
              )}
            </>
          ) : (
            <>
              <img src={logoBug} alt="Rest Potal" style={miniLogo} />
              {!isSmallScreen && (
                <RightDrawerButton leftOpen={!open} handleDrawer={handleDrawer} />
              )}
            </>
          )}
        </DrawerHeader>
        <List sx={{ '& .MuiListItemIcon-root': { minWidth: '39px' } }}>
          <Divider sx={{ mb: 1, backgroundColor: 'grey.500' }} />
          {topMenuOptions.map((menuItem) => (
            <SidebarMenuItem
              key={menuItem.label}
              open={open}
              icon={menuItem.icon}
              label={menuItem.label}
              href={menuItem.href}
              subitems={menuItem.subitems}
              currentURL={currentURL}
            />
          ))}
        </List>
        <Box style={{ marginTop: 'auto' }}>
          <Divider sx={{ backgroundColor: 'grey.500' }} />
          <UserMenu me={me} />
        </Box>
      </Box>
    </Drawer>
  )
}
