const apiValues = [
  'events_total',
  'billable_events_total',
  'events_charged',
  'billable_events_charged',
  'events_removed_staff',
  'events_removed_user',
  'incident_rate',
  'booked_incident_rate',
  'charge_rate',
  'billable_net_charge_rate',
  'gross_charge_rate',
  'billable_gross_charge_rate',
  'adjustment_rate',
  'recidivism_rate',
  'net_charges',
  'net_charges_per_event',
  'net_charges_per_device',
  'net_charges_per_charged_event',
  'net_charges_per_billable_event',
  'missed_revenue',
  'connectivity',

  // adjustment reasons
  'adjustments_airline_group',
  'adjustments_card_declined',
  'adjustments_no_card',
  'adjustments_guest_complaint',
  'adjustments_no_evidence',
  'adjustments_repeat_offense',
  'adjustments_already_charged',
  'adjustments_unbooked',
  'adjustments_vip',
  'adjustments_chargeback',
  'adjustments_charged_later',
  'adjustments_guest_warned',
  'adjustments_other',
]

function toCamelCase(str) {
  return str.replace(/([-_]\w)/g, (match) => match.toUpperCase().replace('_', ''))
}

export default apiValues.reduce((acc, value, index) => {
  const obj = { [`${toCamelCase(value)}`]: { position: index, apiValue: value } }
  return { ...acc, ...obj }
}, {})
