import { createSelector } from 'redux-bundler'

import { getAsyncActionIdentifiers } from '@common/bundles/utils'
import { clearTokens, Project, setTokens } from '@common/config'
import { PORTAL_PROJECT } from '@common/config/project'
import { getApiFetch } from '@common/utils'
import cache from '@common/utils/cache'
import { loginUrls } from '@rest/pages/Login'
import { propertyUrls } from '@rest/pages/Properties'

export const defaultState = {
  authenticated: false,
  login: {
    status: null,
    message: null,
  },
  logout: {
    status: null,
    message: null,
  },
}

const loginActions = getAsyncActionIdentifiers('login', 'auth')
const logoutActions = getAsyncActionIdentifiers('logout', 'auth')

const authBundle = {
  name: 'auth',
  getExtraArgs: (store) => ({
    apiFetch: getApiFetch(store),
  }),
  reducer: (state, action) => {
    switch (action.type) {
      case loginActions.types.started:
        return {
          ...state,
          authenticated: false,
          login: { ...defaultState.login, status: 'started' },
        }
      case loginActions.types.failed:
        return {
          ...state,
          authenticated: false,
          login: { ...defaultState.login, status: 'failed' },
        }
      case loginActions.types.succeeded:
        return {
          ...state,
          authenticated: true,
          login: { ...defaultState.login, status: 'succeeded' },
        }
      case logoutActions.types.started:
        return {
          ...state,
          logout: { ...defaultState.logout, status: 'started' },
        }
      case logoutActions.types.failed:
        return {
          ...state,
          logout: { ...defaultState.logout, status: 'failed' },
        }
      case logoutActions.types.succeeded:
        return {
          ...state,
          authenticated: false,
          logout: { ...defaultState.logout, status: 'succeeded' },
        }
      default:
        return state || defaultState
    }
  },
  selectAuth: (state) => state.auth,
  selectIsAuthenticated: createSelector('selectAuth', (auth) => auth.authenticated),
  doForceAuthenticate:
    (payload) =>
    ({ dispatch }) => {
      setTokens({
        refresh: payload.refresh,
        access: payload.access,
        account: payload.account,
        organization: payload.organization,
      })

      const { types } = loginActions
      dispatch({ type: types.succeeded })
    },
  [loginActions.actionName]:
    (payload, path = '/token/') =>
    async ({ apiFetch, store, dispatch }) => {
      const { types } = loginActions

      dispatch({ type: types.started })

      try {
        const preloginResponse =
          path === '/token/'
            ? await apiFetch(
                `${path}prelogin/`,
                { token: payload.email },
                {
                  method: 'POST',
                  useAuth: false,
                  useOrgHeader: false,
                  useAccountHeader: false,
                },
              )
            : null

        if (preloginResponse?.portal) {
          const portalTokens = await apiFetch(path, payload, {
            method: 'POST',
            useAuth: false,
            useOrgHeader: false,
            useAccountHeader: false,
            project: PORTAL_PROJECT,
          })
          setTokens(portalTokens, PORTAL_PROJECT)

          try {
            const restTokens = await apiFetch(path, payload, {
              method: 'POST',
              useAuth: false,
              useOrgHeader: false,
              useAccountHeader: false,
            })
            setTokens(restTokens)
          } catch (err) {
            const isMembershipError =
              err?.error?.status === 401 &&
              err?.error?.response?.detail
                ?.toLowerCase()
                ?.includes('no memberships found')
            if (!isMembershipError) {
              throw err
            }
          } finally {
            dispatch({ type: types.succeeded })
            globalThis.location.replace(`${globalThis.location.origin}/portal`)
          }
          return {}
        }

        const auth = await apiFetch(path, payload, {
          method: 'POST',
          useAuth: false,
          useOrgHeader: false,
          useAccountHeader: false,
        })
        setTokens(auth)
        dispatch({ type: types.succeeded })
        store.doMarkHeaderAsOutdated()
        store.doMarkSystemAsOutdated()
        return auth
      } catch (error) {
        dispatch({ type: types.failed, payload: error })
        return error
      }
    },
  [logoutActions.actionName]:
    () =>
    async ({ dispatch }) => {
      const { types } = logoutActions

      dispatch({ type: types.started })

      let success
      try {
        clearTokens()
        dispatch({ type: types.succeeded })
        await cache.clear()
        success = true
      } catch (error) {
        success = false
        dispatch({ type: types.failed, error })
      }

      return success
    },
  reactRedirectToHome: createSelector(
    'selectRouteInfo',
    'selectIsAuthenticated',
    'selectMeRaw',
    ({ url }, authenticated, me) => {
      if (url.startsWith(loginUrls.login) && authenticated && me.currentAccount) {
        return { actionCreator: 'doUpdateUrl', args: [propertyUrls.list] }
      }
      if (Project.isRest && url.startsWith('/portal')) {
        Project.switch(url)
        return undefined
      }
      return undefined
    },
  ),
  reactRedirectToLogin: createSelector(
    'selectRoute',
    'selectRouteInfo',
    'selectIsAuthenticated',
    (route, { url }, authenticated) => {
      if (!route.publicAccess && !authenticated && !url.startsWith('/portal')) {
        globalThis.location.replace(loginUrls.login)
      }
      return undefined
    },
  ),
  reactRedirectToProperties: createSelector(
    'selectRouteInfo',
    'selectIsAuthenticated',
    'selectFlags',
    ({ url }, authenticated) => {
      if (url === '/' && authenticated) {
        return { actionCreator: 'doUpdateUrl', args: [propertyUrls.list] }
      }
      return undefined
    },
  ),
}

export default authBundle
