export const INVOICE_STATUS_PAID = 'PAID'
export const INVOICE_STATUS_PAST_DUE = 'PAST_DUE'
export const INVOICE_STATUS_VOIDED = 'VOIDED'
export const INVOICE_STATUS_WAITING_FOR_PAYMENT = 'WAITING_FOR_PAYMENT'

export const INVOICE_STATUSES_OPTIONS = [
  INVOICE_STATUS_PAID,
  INVOICE_STATUS_PAST_DUE,
  INVOICE_STATUS_VOIDED,
  INVOICE_STATUS_WAITING_FOR_PAYMENT,
]
