import { createListBundle } from '@common/utils'
import eventUrls from '@portal/pages/EventDashboard/urls'
import issuesUrls from '@portal/pages/Issues/urls'

const entityName = 'auditLog'

const auditLogListBundle = createListBundle({
  entityName,
  fetchHandler: ({ apiFetch, params }) => {
    // prevent unscoped calls
    if (!params.objectId && !params.modifiedBy) {
      return null
    }
    return apiFetch('/audit_logs/', params, { cancelationPrefix: entityName })
  },
  urlTest: (url, _, hash, queryObject) =>
    ['auditLog', 'actions'].includes(hash) ||
    [eventUrls.list, issuesUrls.list].includes(url) ||
    queryObject?.auditLog,
})

export default auditLogListBundle
