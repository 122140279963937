import { createListBundle, getEntityFromUrl } from '@common/utils'
import propertyUrls from '@rest/pages/Properties/urls'

import invoicesUrls from './urls'

const entityName = 'propertyInvoices'

const invoicesListBundle = createListBundle({
  entityName,
  fetchHandler: ({ apiFetch, store, params }) => {
    const { entityType, entityId } = getEntityFromUrl(store)
    return apiFetch(
      '/property_invoices/',
      {
        ...params,
        ...(entityType && entityId && { [entityType]: entityId }),
      },
      {
        useOrgHeader: false,
        cancelationPrefix: entityName,
      },
    )
  },
  urlTest: (url, pattern) =>
    url === invoicesUrls.list || pattern === propertyUrls.entity,
})

export default invoicesListBundle
