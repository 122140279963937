import './PhoneNumber.css'

import { getIn } from 'formik'
import { humanize, underscore } from 'inflection'
import { validatePhoneNumberLength } from 'libphonenumber-js'
import { MuiTelInput } from 'mui-tel-input'

import { Project } from '@common/config'

const formatError = (err) => humanize(underscore(err))

const formatErrors = ({ field, form }) => {
  const error = getIn(form.errors, field.name)
  if (!error) return [false, '']
  const errorText = Array.isArray(error) ? error.map(formatError) : formatError(error)
  return [true, errorText]
}

export const handlePhoneChange = (v, info) => {
  const sanitizedValue = v.replace(/[()\s]/g, '')
  const validationStatus = validatePhoneNumberLength(sanitizedValue, info.countryCode)
  return validationStatus === 'TOO_LONG' ? sanitizedValue.slice(0, -1) : sanitizedValue
}

function PhoneNumber(formikProps) {
  const { field, form, showErrorsOnTouched, ...props } = formikProps
  const [hasError, errorText] = formatErrors(formikProps)
  const { touched } = form.getFieldMeta(field.name)
  const showError =
    form.dirty && touched && hasError && (showErrorsOnTouched ? touched : true)

  const extraProps = Project.isPortal
    ? {
        variant: 'standard',
        sx: { width: '288px' },
      }
    : {
        variant: 'outlined',
        sx: { width: '100%' },
      }

  return (
    <MuiTelInput
      disableDropdown
      defaultCountry="US"
      error={showError}
      helperText={showError && errorText}
      name={field.name}
      onBlur={field.onBlur}
      onChange={(v, info) => {
        const sanitizedValue = handlePhoneChange(v, info)
        form.setFieldValue(field.name, sanitizedValue)
      }}
      value={getIn(form.values, field.name)}
      {...props}
      {...extraProps}
    />
  )
}

export default PhoneNumber
