/* eslint-disable react/no-array-index-key */
import React, { useCallback, useEffect, useMemo } from 'react'

import { useConnect } from 'redux-bundler-hook'

import { CloseOutlined, CopyAll } from '@mui/icons-material'
import {
  Alert,
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'

import { DateTime } from 'luxon'

import { Loading } from '@common/components'
import { formatCurrency, parseApiErrors, useSmallScreen } from '@common/utils'
import { formatDayWithOrdinal } from '@common/utils/formatters'

import InvoiceActions from './InvoiceActions'
import InvoiceStatusChip from './InvoiceStatusChip'

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {boolean} props.open
 * @param {function} props.onClose
 * @param {Object} [props.invoice]
 * @param {string} props.invoice.id
 * @param {string} [props.invoice.status]
 */
export default function InvoiceDetailModal({ open, onClose, invoice }) {
  const isSmallScreen = useSmallScreen()
  const {
    propertyInvoiceFetch: invoiceData,
    propertyInvoiceFetchStatus,
    propertyInvoiceFetchError,
    doPropertyInvoiceFetch,
    doShowSnackbar,
  } = useConnect(
    'selectPropertyInvoiceFetch',
    'selectPropertyInvoiceFetchStatus',
    'selectPropertyInvoiceFetchError',
    'doPropertyInvoiceFetch',
    'doShowSnackbar',
  )

  const fetchInvoice = useCallback(async () => {
    try {
      await doPropertyInvoiceFetch(invoice.id)
    } catch (err) {
      // no need to show snackbar or smth. We use `propertyInvoiceFetchError` to handle error
    }
  }, [invoice])

  useEffect(() => {
    if (invoice) {
      fetchInvoice()
    }
  }, [invoice])

  const isLoading = propertyInvoiceFetchStatus === 'loading'

  const DetailItem = useCallback(
    ({ label, value, align }) => (
      <Box
        display="flex"
        flexDirection="column"
        alignItems={align ?? 'start'}
        justifyContent="space-between"
      >
        <Typography variant="body" fontWeight={500} color="grey.800">
          {label}
        </Typography>
        <Typography variant="body2" color="grey.700">
          {value ?? '--'}
        </Typography>
      </Box>
    ),
    [],
  )

  const formattedIssuedOn = useMemo(() => {
    const parsedDate = invoice?.invoiceCreatedDate
      ? DateTime.fromISO(invoice.invoiceCreatedDate)
      : null
    return parsedDate
      ? `${parsedDate.toFormat('MMM.')} ${formatDayWithOrdinal(
          parsedDate.day,
        )}, ${parsedDate.toFormat('yyyy')}`
      : null
  }, [invoice])

  const formattedInvocePeriod = useMemo(() => {
    const start = DateTime.fromISO(invoice?.startDate)
    const end = DateTime.fromISO(invoice?.endDate)

    const isValidDate = start.isValid && end.isValid
    if (!isValidDate) return null

    let formattedRange = null
    if (start.month === end.month && start.year === end.year) {
      formattedRange = `${start.toFormat('MMM. d')}-${end.toFormat('d, yyyy')}`
    } else {
      const formattedStart = start.toFormat('MMM. d yyyy')
      const formattedEnd = end.toFormat('MMM. d yyyy')
      formattedRange = `${formattedStart} - ${formattedEnd}`
    }
    return formattedRange
  }, [invoice])

  const BodyComponent = useCallback(
    ({ data }) => {
      if (isSmallScreen) {
        return (
          <Box display="flex" flexDirection="column" gap={2} mt={2} mx={1}>
            {data.lineItems?.map((item, index) => (
              <React.Fragment key={index}>
                <Divider />
                <Box display="flex" flexDirection="column" gap={1}>
                  <DetailItem label="Description" value={item.description} />
                  <Box display="flex" justifyContent="space-between" gap={1}>
                    <DetailItem
                      label="Unit Price"
                      value={
                        typeof item.unitPrice === 'number'
                          ? formatCurrency(item.unitPrice)
                          : null
                      }
                    />
                    <DetailItem label="Quantity" value={item.quantity} align="center" />
                    <DetailItem
                      label="Amount"
                      value={
                        typeof item.amount === 'number'
                          ? formatCurrency(item.amount)
                          : null
                      }
                      align="end"
                    />
                  </Box>
                </Box>
              </React.Fragment>
            ))}
            <Divider />
            <Box display="flex" justifyContent="space-between" gap={1}>
              {data.discountAmount ? (
                <DetailItem
                  label="Discount"
                  value={formatCurrency(-Math.abs(data.discountAmount))}
                />
              ) : (
                <Box />
              )}
              <DetailItem
                label="Amount Due"
                value={formatCurrency(data.amountDue)}
                align="end"
              />
            </Box>
          </Box>
        )
      }

      const rowStyle = { '&:last-child td, &:last-child th': { border: 0 } }
      return (
        <TableContainer sx={{ mt: 2 }}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Description</TableCell>
                <TableCell align="right">Unit Price</TableCell>
                <TableCell align="right">Quantity</TableCell>
                <TableCell align="right">Amount</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.lineItems?.map((item, index) => (
                <TableRow key={index} sx={rowStyle}>
                  <TableCell component="th" scope="row">
                    {item.description}
                  </TableCell>
                  <TableCell align="right">
                    {typeof item.unitPrice === 'number'
                      ? formatCurrency(item.unitPrice)
                      : null}
                  </TableCell>
                  <TableCell align="right">{item.quantity}</TableCell>
                  <TableCell align="right">
                    {typeof item.amount === 'number'
                      ? formatCurrency(item.amount)
                      : null}
                  </TableCell>
                </TableRow>
              ))}
              {data.discountAmount && (
                <TableRow key="discount" sx={rowStyle}>
                  <TableCell component="th" scope="row">
                    Discount
                  </TableCell>
                  <TableCell align="right">
                    {formatCurrency(-Math.abs(data.discountAmount))}
                  </TableCell>
                  <TableCell align="right">1</TableCell>
                  <TableCell align="right">
                    {formatCurrency(-Math.abs(data.discountAmount))}
                  </TableCell>
                </TableRow>
              )}
              <TableRow key="amountDue" sx={rowStyle}>
                <TableCell colSpan={4} align="right">
                  <Box fontWeight={600} display="inline-flex" mr={1}>
                    Amount Due:
                  </Box>
                  {formatCurrency(data.amountDue)}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      )
    },
    [isSmallScreen],
  )

  return (
    <Dialog maxWidth="lg" open={open} onClose={onClose}>
      <DialogTitle
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          pt: 3,
          pb: 0,
          px: 4,
          gap: 4,
        }}
      >
        <Box display="flex" justifyContent="space-between" alignItems="start" width={1}>
          <Box display="flex" flexDirection="column">
            <Box display="flex" gap={2} alignItems="center">
              Invoice Detail
              <InvoiceStatusChip status={invoice?.invoicePaymentStatus ?? ''} />
            </Box>
            <Box display="flex" alignItems="center">
              <Typography variant="caption" color="text.secondary">
                id: {invoice?.stripeInvoiceNumber}
              </Typography>
              <CopyAll
                sx={{ m: 0.5, cursor: 'pointer', fontSize: 16 }}
                onClick={() => {
                  navigator.clipboard.writeText(invoice?.id)
                  doShowSnackbar('ID copied to clipboard')
                }}
              />
            </Box>
            {formattedInvocePeriod && (
              <Typography variant="caption" color="text.secondary">
                Invoice Period: {formattedInvocePeriod}
              </Typography>
            )}
            {formattedIssuedOn && (
              <Typography variant="caption" color="text.secondary">
                Issued On: {formattedIssuedOn}
              </Typography>
            )}
          </Box>
          <IconButton onClick={onClose}>
            <CloseOutlined fontSize="small" />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        {isLoading && <Loading height={200} size={50} />}
        {!isLoading && invoiceData && !propertyInvoiceFetchError && (
          <Box display="flex" flexDirection="column" gap={4}>
            <BodyComponent data={invoiceData} />
            <InvoiceActions invoice={invoiceData} mode="modal" />
          </Box>
        )}
        {!isLoading && propertyInvoiceFetchError && (
          <Alert severity="error" sx={{ mt: 2 }}>
            {parseApiErrors(propertyInvoiceFetchError) || 'Invoice details unavailable'}
          </Alert>
        )}
      </DialogContent>
    </Dialog>
  )
}
