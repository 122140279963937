import { isEmpty } from 'ramda'
import { createSelector } from 'redux-bundler'

import { createEntityBundle } from '@common/utils'

const entityName = 'devices'

const deviceBundle = createEntityBundle({
  name: entityName,
  customActions: [
    {
      actionName: 'fetch_communications',
      handler: ({ apiFetch, apiUrl, payload }) =>
        apiFetch(
          `/${apiUrl}/${payload.id}/communications/`,
          {
            start: payload.dateRange[0].startOf('day').toISO(),
            end: payload.dateRange[1].endOf('day').toISO(),
            ...(!isEmpty(payload.types)
              ? { type: payload.types.map((type) => type.id).join(',') }
              : {}),
          },
          {
            method: 'GET',
            cancelationPrefix: entityName,
          },
        ),
    },
    {
      actionName: 'send_command',
      handler: ({ apiFetch, apiUrl, payload }) =>
        apiFetch(`/${apiUrl}/commands/`, payload, {
          method: 'POST',
          cancelationPrefix: entityName,
        }),
    },
    {
      actionName: 'assign',
      handler: ({ apiFetch, apiUrl, payload }) =>
        apiFetch(`/${apiUrl}/${payload?.id}/assign/`, payload, {
          method: 'PUT',
          cancelationPrefix: entityName,
        }),
    },
    {
      actionName: 'unassign',
      handler: ({ apiFetch, apiUrl, payload }) =>
        apiFetch(`/${apiUrl}/unassign/`, payload, {
          method: 'PUT',
          cancelationPrefix: entityName,
        }),
    },
    {
      actionName: 'export',
      handler: ({ apiFetch, apiUrl, payload }) =>
        apiFetch(`/${apiUrl}/${payload?.id}/export/`, payload.data, {
          method: 'POST',
          cancelationPrefix: entityName,
        }),
    },
  ],
})

export default {
  ...deviceBundle,
  selectDeviceCommunications: createSelector(
    'selectDevice',
    (device) => device?.fetch_communications?.response,
  ),
  selectDeviceCommunicationsIsLoading: createSelector(
    'selectDevice',
    (device) => device?.fetch_communications?.meta?.status === 'loading',
  ),
}
