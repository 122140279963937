import { createRouteBundle } from 'redux-bundler'

import NotFoundPage from '@common/components/NotFoundPage'
import { configureUrls, Messaging, ThresholdProfiles } from '@rest/pages/Configure'
import ThresholdList from '@rest/pages/Configure/ThresholdProfiles/ThresholdList'
import Home from '@rest/pages/Home'
import { Invoices, invoiceUrls } from '@rest/pages/Invoices'
import {
  EphemeralLogin,
  ForgotPassword,
  Login,
  loginUrls,
  PasswordReset,
} from '@rest/pages/Login'
import { PrivacyStatement, privacyStatementUrls } from '@rest/pages/PrivacyStatement'
import { PropertyDetail, PropertyList, propertyUrls } from '@rest/pages/Properties'
import { Reservations, reservationUrls } from '@rest/pages/Reservations'
import { SmokeMetrics, smokeMetricsUrls } from '@rest/pages/SmokeMetrics'
import { UnitDetail, unitUrls } from '@rest/pages/Units'
import { UserSettings, userSettingsUrls } from '@rest/pages/UserSettings'

export default createRouteBundle({
  '/': {
    showSidebar: true,
    showEventsDrawer: true,
    showHeader: true,
    component: Home,
  },
  [loginUrls.login]: {
    component: Login,
    publicAccess: true,
    showSidebar: false,
    showEventsDrawer: false,
    showHeader: false,
  },
  '/ephemeral/:token': {
    component: EphemeralLogin,
    publicAccess: true,
    showSidebar: false,
    showEventsDrawer: false,
    showHeader: false,
  },
  [loginUrls.passwordReset]: {
    component: PasswordReset,
    publicAccess: true,
    showHeader: false,
    showEventsDrawer: false,
  },
  [loginUrls.passwordCreate]: {
    component: PasswordReset,
    publicAccess: true,
    showHeader: false,
    showEventsDrawer: false,
    isNew: true,
  },
  [loginUrls.forgotPassword]: {
    component: ForgotPassword,
    publicAccess: true,
    showHeader: false,
    showEventsDrawer: false,
  },
  [propertyUrls.list]: PropertyList,
  [propertyUrls.entity]: PropertyDetail,
  [propertyUrls.thresholds]: ThresholdList,
  [unitUrls.entity]: UnitDetail,
  [configureUrls.messaging]: Messaging,
  [configureUrls.thresholdsList]: ThresholdProfiles,
  [userSettingsUrls.userSettings]: UserSettings,
  [smokeMetricsUrls.metrics]: SmokeMetrics,
  [reservationUrls.reservations]: Reservations,
  [invoiceUrls.list]: Invoices,
  [privacyStatementUrls.entity]: { component: PrivacyStatement, publicAccess: true },
  '*': NotFoundPage,
})
