import { useCallback, useEffect, useRef, useState } from 'react'

import CloseIcon from '@mui/icons-material/Close'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import { Alert, Box, IconButton, Link, Stack, Tooltip, Typography } from '@mui/material'

import { MarkerClusterer } from '@googlemaps/markerclusterer'
import { InfoWindow, useMap, useMapsLibrary } from '@vis.gl/react-google-maps'

import propertyUrls from '@portal/pages/Properties/urls'
import DetailItem from '@portal/UI/components/DetailItem'

import MapControl from './MapControl'
import PropertyMarker from './PropertyMarker'

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {Object[]} props.properties
 * @param {number} props.properties[].lat
 * @param {number} props.properties[].lng
 */
export default function PropertyMarkers({
  properties,
  selectedProperty,
  setSelectedProperty,
}) {
  const [markers, setMarkers] = useState({})

  const map = useMap()
  const placesLib = useMapsLibrary('places')

  const clusterer = useRef(null)

  const handleClusterClicked = (cluster) => {
    const currentCluster = clusterer?.current?.clusters?.find((c) =>
      c.position.equals(cluster.latLng),
    )
    map.fitBounds(currentCluster.bounds)
    map.setZoom(map.getZoom() - 2)
  }

  useEffect(() => {
    if (!placesLib || !map) return
    // eslint-disable-next-line no-new
    new placesLib.PlacesService(map)

    if (!clusterer.current) {
      clusterer.current = new MarkerClusterer({
        map,
        onClusterClick: handleClusterClicked,
      })
    }
  }, [map])

  useEffect(() => {
    clusterer.current?.clearMarkers()
    clusterer.current?.addMarkers(Object.values(markers))
  }, [markers])

  const handleMarkerClick = useCallback((prop) => {
    setSelectedProperty(prop)
  }, [])

  const handleInfoWindowClose = useCallback(() => {
    setSelectedProperty(null)
  }, [])

  const setMarkerRef = useCallback((marker, id) => {
    setMarkers((prevMarkers) => {
      if ((marker && prevMarkers[id]) || (!marker && !prevMarkers[id]))
        return prevMarkers

      if (marker) {
        return { ...prevMarkers, [id]: marker }
      }
      const { [id]: _, ...newMarkers } = prevMarkers
      return newMarkers
    })
  }, [])

  const address = `${selectedProperty?.address1}${selectedProperty?.address2}
    ${selectedProperty?.city} ${selectedProperty?.state} ${selectedProperty?.zipCode}`

  const detailStyles = { fontSize: 12 }
  const hasCurrentIssue = Boolean(
    selectedProperty?.issueStart && !selectedProperty?.issueEnd,
  )

  const propertyCategories = properties.reduce(
    (acc, prop) => {
      const hasLocation = Boolean(prop.latitude && prop.longitude)
      const hasIssue = Boolean(prop.issueStart && !prop.issueEnd)

      if (hasIssue) acc.issueProps.push(prop)
      if (hasLocation) acc.locationProps.push(prop)
      else acc.nonLocationProps.push(prop)

      return acc
    },
    { locationProps: [], nonLocationProps: [], issueProps: [] },
  )

  return (
    <>
      <MapControl map={map} />
      {propertyCategories.locationProps.map((prop) => (
        <PropertyMarker
          key={prop.id}
          property={prop}
          onClick={handleMarkerClick}
          setMarkerRef={setMarkerRef}
        />
      ))}
      {selectedProperty && (
        <InfoWindow headerDisabled anchor={markers[selectedProperty?.id]}>
          <Box>
            <Stack direction="row" justifyContent="space-between">
              <Link
                noWrap
                onClick={(e) => e.stopPropagation()}
                href={propertyUrls.entity.replace(':id', selectedProperty?.id)}
              >
                <Typography variant="h6">{selectedProperty?.name}</Typography>
              </Link>

              <IconButton size="small" onClick={handleInfoWindowClose}>
                <CloseIcon />
              </IconButton>
            </Stack>
            <Stack direction="row" gap={2}>
              <DetailItem
                label="Address"
                value={address}
                titleSx={detailStyles}
                sx={detailStyles}
              />
              <DetailItem
                label="Online/Total Devices"
                value={`${selectedProperty?.onlineSensorCount}/${selectedProperty?.deviceCount}`}
                titleSx={{ ...detailStyles, textAlign: 'right' }}
                sx={{ ...detailStyles, textAlign: 'center' }}
              />
            </Stack>
            {hasCurrentIssue && (
              <Alert
                severity="error"
                icon={
                  <Tooltip placement="top" title={selectedProperty?.issueComments}>
                    <ErrorOutlineIcon />
                  </Tooltip>
                }
                sx={{
                  textAlign: 'center',
                  justifyContent: 'center',
                  fontSize: '0.8rem',
                  marginTop: '8px',
                  padding: '4px 8px 0 8px',
                  '& .MuiAlert-icon': {
                    padding: '4px 0 0 8px',
                    fontSize: '0.5rem',
                  },
                }}
              >
                {selectedProperty?.issueCategory}
              </Alert>
            )}
          </Box>
        </InfoWindow>
      )}
    </>
  )
}
