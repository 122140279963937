import { useCallback, useEffect, useState } from 'react'

import { useConnect } from 'redux-bundler-hook'

import { FindInPage as FindInPageIcon } from '@mui/icons-material'
import { Box, Button, Stack, Typography } from '@mui/material'

import { titleize } from 'inflection'
import { DateTime } from 'luxon'

import { MobileList, MobileListCardRow, Picker } from '@common/components'
import { StaticSelect } from '@common/components/Selects'
import { useSmallScreen } from '@common/utils'
import Filter from '@portal/UI/components/Filter'
import List from '@portal/UI/components/List'

import LivePreviewModal from './LivePreviewModal'

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {boolean} [props.renderAsTab]
 */
export default function NotificationList({ renderAsTab = false }) {
  const [filterModalOpen, setFilterModalOpen] = useState(false)
  const [livePreviewOpen, setLivePreviewOpen] = useState(false)
  const [livePreviewContent, setLivePreviewContent] = useState(null)

  const isSmallScreen = useSmallScreen()

  const {
    system,
    notificationList,
    notificationListApiParams,
    notificationListIsLoading,
    doNotificationListSetFilter,
    doNotificationListSetPage,
    doNotificationListSetPageSize,
    doNotificationListSetSearch,
    doNotificationListSetOrdering,
    doMarkNotificationListAsOutdated,
  } = useConnect(
    'selectSystem',
    'selectNotificationList',
    'selectNotificationListApiParams',
    'selectNotificationListIsLoading',
    'doNotificationListSetFilter',
    'doNotificationListSetPage',
    'doNotificationListSetPageSize',
    'doNotificationListSetSearch',
    'doNotificationListSetOrdering',
    'doMarkNotificationListAsOutdated',
  )

  const handleLivePreview = (content) => {
    setLivePreviewOpen(true)
    setLivePreviewContent(content)
  }

  const columns = [
    {
      flex: 1.2,
      field: 'ts',
      headerName: 'Timestamp',
      renderCell: ({ row }) =>
        DateTime.fromISO(row.ts).toLocaleString(DateTime.DATETIME_MED),
    },
    {
      flex: 1.8,
      field: 'notificationType',
      headerName: 'Type',
      renderCell: ({ row }) => titleize(row.notificationType.replace('_', ' ')),
    },
    {
      flex: 0.8,
      field: 'modality',
      headerName: 'Modality',
      renderCell: ({ row }) => titleize(row.modality),
    },
    {
      flex: 1.4,
      field: 'destination',
      headerName: 'Destination',
      maxWidth: 400,
    },
    {
      flex: 1,
      minWidth: 180,
      field: 'content',
      headerName: 'Live Preview',
      headerAlign: 'center',
      align: 'center',
      renderCell: ({ row }) => (
        <Button
          size="small"
          variant="outlined"
          sx={{ whiteSpace: 'nowrap', minWidth: 'max-content' }}
          color="primary"
          onClick={() => handleLivePreview(row.content)}
          startIcon={<FindInPageIcon />}
          disabled={row?.events?.length < 1}
        >
          Live Preview
        </Button>
      ),
    },
  ]

  const handleLivePreviewClose = () => {
    setLivePreviewOpen(false)
    setLivePreviewContent(null)
  }

  const MobileListItem = useCallback((row) => {
    console.warn('row', row)
    return (
      <Stack>
        <Box display="flex" alignItems="center" mb={1}>
          <Typography variant="caption" fontWeight="bold">
            {DateTime.fromISO(row.ts).toLocaleString(DateTime.DATETIME_MED)}
          </Typography>
        </Box>
        <MobileListCardRow
          label="Notification Type"
          value={titleize(row.notificationType.replace('_', ' '))}
        />
        <MobileListCardRow label="Modality" value={titleize(row.modality)} />
        <MobileListCardRow label="Destination" value={row.destination} />
      </Stack>
    )
  }, [])

  useEffect(() => {
    setLivePreviewOpen(false)
    setLivePreviewContent(null)
    doMarkNotificationListAsOutdated()
  }, [])

  return (
    <>
      <LivePreviewModal
        open={livePreviewOpen}
        onClose={handleLivePreviewClose}
        content={livePreviewContent}
      />
      <Box display="flex" minHeight={900}>
        <Box display="flex" marginRight={isSmallScreen ? 0 : 2} marginTop={1.5}>
          <Filter
            mode={isSmallScreen ? 'modal' : 'drawer'}
            disabled={notificationListIsLoading}
            apiParams={notificationListApiParams}
            setApiParams={doNotificationListSetFilter}
            dialogOpen={filterModalOpen}
            dialogOnClose={() => setFilterModalOpen(false)}
          >
            <Picker
              range
              type="dateTime"
              label="Timestamp"
              disableMaskedInput
              filterName="ts"
              conditionSeparator=""
              lowerCondition="After"
              upperCondition="Before"
              value={notificationListApiParams?.ts}
            />
            <StaticSelect
              label="Modality"
              filterName="modality"
              options={system?.notificationModalities}
            />
            <StaticSelect
              label="Type"
              filterName="notificationType"
              options={system?.notificationTypes}
            />
          </Filter>
        </Box>
        {isSmallScreen ? (
          <Box display="flex" flexDirection="column" width="100%">
            <MobileList
              title="Notifications"
              loading={notificationListIsLoading}
              itemBuilder={MobileListItem}
              onFilterPressed={() => setFilterModalOpen(true)}
              page={notificationList.current || 1}
              pageChange={doNotificationListSetPage}
              pageSize={notificationList.pageSize}
              pageSizeChange={doNotificationListSetPageSize}
              rows={notificationList?.results}
              rowCount={notificationList.count || 0}
              setSearch={doNotificationListSetSearch}
            />
          </Box>
        ) : (
          <Box
            marginTop={1.5}
            flexDirection="column"
            display="flex"
            flex={1}
            height="100%"
            overflow="hidden"
          >
            {!renderAsTab && <Typography variant="h5">Notifications</Typography>}
            <List
              columnsAutosize
              getRowId={(row) => row.ts}
              title="Notifications"
              showAddButton={false}
              showActions={false}
              loading={notificationListIsLoading}
              columns={columns}
              page={notificationList.current || 1}
              pageChange={doNotificationListSetPage}
              pageSize={notificationList.pageSize}
              pageSizeChange={doNotificationListSetPageSize}
              rows={notificationList?.results}
              rowCount={notificationList.count || 0}
              setSearch={doNotificationListSetSearch}
              sortChange={doNotificationListSetOrdering}
            />
          </Box>
        )}
      </Box>
    </>
  )
}
