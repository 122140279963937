import { useCallback, useEffect, useMemo, useState } from 'react'

import { useConnect } from 'redux-bundler-hook'

import { FileDownload, PieChartRounded } from '@mui/icons-material'
import { Box, Button, Stack } from '@mui/material'

import { DateTime } from 'luxon'

import { SearchBox } from '@common/components'
import {
  downloadFile,
  isAbortError,
  parseApiErrors,
  useSmallScreen,
} from '@common/utils'
import { buildReasonsById } from '@common/utils/adjustmentReasonsUtils'
import { METRICS_7_DAYS_INTERVAL } from '@common/utils/propertyDetailMetricsUtils'
import { AdjustmentSummaryPopover } from '@rest/UI/components'
import { formatCSVData } from '@rest/Utils/csv'

import EventsIntervalPicker from './EventsIntervalPicker'
import MetricsDetailStack from './MetricsDetailStack'
import PropertyEventList from './PropertyEventList'

export const getDefaultInterval = () => {
  const defaultEnd = DateTime.now()
  const defaultStart = DateTime.now().minus({ days: 7 })

  return {
    interval: METRICS_7_DAYS_INTERVAL,
    range: [defaultStart, defaultEnd],
  }
}

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {Object} props.property
 * @param {string} props.property.id
 * @param {string} props.property.name
 * @param {Function} [props.onDateRangeChanged]
 * @param {string[]} [props.property.expandedFlags]
 */
export default function PropertyEvents({ property, onDateRangeChanged }) {
  const {
    propertyEventListRaw: { filter = {} },
    smokeAdjustmentReasons,
    doPropertyEventListSetFilter,
    doPropertyEventListSetOrdering,
    doPropertyEventListSetSearch,
    doPropertyEventListExport,
    doFetchPropertyEventsMetrics,
    doShowSnackbar,
  } = useConnect(
    'selectPropertyEventListRaw',
    'selectSmokeAdjustmentReasons',
    'doPropertyEventListSetFilter',
    'doPropertyEventListSetOrdering',
    'doPropertyEventListSetSearch',
    'doPropertyEventListExport',
    'doFetchPropertyEventsMetrics',
    'doShowSnackbar',
  )

  const isSmallScreen = useSmallScreen()

  const [dateRangeInterval, setDateRangeInterval] = useState(getDefaultInterval())
  const [adjustmentSummaryAnchorEl, setAdjustmentSummaryAnchorEl] = useState(false)

  const fetchMetricsData = useCallback(async () => {
    try {
      await doFetchPropertyEventsMetrics({
        property: property.id,
        range: dateRangeInterval.range,
      })
    } catch (err) {
      if (!isAbortError(err)) {
        const parsedError = parseApiErrors(err?.response)
        doShowSnackbar(parsedError, 'error')
      }
    }
  }, [property, dateRangeInterval])

  useEffect(() => {
    doPropertyEventListSetSearch()
    doPropertyEventListSetOrdering(['createdOn', 'desc'])
  }, [property])

  useEffect(() => {
    const startDate = dateRangeInterval.range[0] ? dateRangeInterval.range[0] : null
    const endDate = dateRangeInterval.range[1] ? dateRangeInterval.range[1] : null

    if (startDate && endDate && startDate < endDate) {
      let eventClasses = 'SMOKE'
      if (property.expandedFlags?.includes('NOISE')) {
        eventClasses += ',NOISE'
      }

      doPropertyEventListSetFilter({
        ...filter,
        startDate,
        endDate,
        eventClass: eventClasses,
      })
      fetchMetricsData()
    }
  }, [dateRangeInterval, property])

  const reasonsById = useMemo(
    () => buildReasonsById(smokeAdjustmentReasons),
    [smokeAdjustmentReasons],
  )

  return (
    <>
      {adjustmentSummaryAnchorEl && (
        <AdjustmentSummaryPopover
          anchorEl={adjustmentSummaryAnchorEl}
          dateRange={dateRangeInterval.range}
          reasonsById={reasonsById}
          onClose={() => setAdjustmentSummaryAnchorEl(null)}
          onAdjustmentClick={(data) => {
            doPropertyEventListSetFilter({
              ...filter,
              id: data.eventsIds.join(','),
              reason: data.reason,
            })
          }}
        />
      )}
      <Stack
        direction={isSmallScreen ? 'column' : 'row'}
        justifyContent="space-between"
        alignItems={isSmallScreen ? 'start' : 'center'}
        spacing={2}
        mb={3}
        mt={1.5}
      >
        {!isSmallScreen && (
          <SearchBox
            title="Events"
            onSetSearch={doPropertyEventListSetSearch}
            minLength={0}
          />
        )}
        <Box display="flex" flexDirection="row" width={isSmallScreen ? '100%' : null}>
          <Button
            fullWidth={isSmallScreen}
            data-testid="adjustment_summary"
            variant="contained"
            startIcon={!isSmallScreen ? <PieChartRounded /> : null}
            onClick={(event) => setAdjustmentSummaryAnchorEl(event.currentTarget)}
          >
            Adjustment Summary
          </Button>
          <Button
            fullWidth={isSmallScreen}
            data-testid="export_events"
            variant="contained"
            sx={{ ml: 1 }}
            onClick={async () => {
              const result = await doPropertyEventListExport()
              const data = formatCSVData(result, (event) => ({
                unitName: 'Unit Name',
                zoneName: 'Zone Name',
                eventClass: 'Type',
                createdOn: 'Created On',
                endTime: 'End Time',
                lastOccurrence: 'Last Occurrence',
                billable: 'Billable',
                [`${
                  event.eventClass === 'NOISE' ? 'noiseFeedback' : 'smokeFeedback'
                }.comments`]: 'Comments',
                [`${
                  event.eventClass === 'NOISE' ? 'noiseFeedback' : 'smokeFeedback'
                }.modifiedByName`]: 'Modified By',
                [`${
                  event.eventClass === 'NOISE' ? 'noiseFeedback' : 'smokeFeedback'
                }.modifiedOn`]: 'Modified On',
                'smokeFeedback.adjustmentReason': 'Adjustment Reason',
                'smokeFeedback.adjustedOn': 'Adjusted On',
                'smokeFeedback.netChargeAmount': 'Net Charge Amount',
                'reservation.confirmationNumber': 'Confirmation Number',
              }))
              downloadFile({
                data: [data],
                fileName: `${property.name}_events_export.csv`,
                fileType: 'text/csv',
              })
            }}
            startIcon={!isSmallScreen ? <FileDownload /> : null}
          >
            Export
          </Button>
        </Box>
      </Stack>

      <Stack
        direction={isSmallScreen ? 'column' : 'row'}
        justifyContent="space-between"
        alignItems="center"
        spacing={3}
        mb={3}
      >
        <MetricsDetailStack
          intervalData={dateRangeInterval}
          mobileLayout={isSmallScreen}
        />
        <EventsIntervalPicker
          intervalData={dateRangeInterval}
          currentFilter={filter}
          reasonsById={reasonsById}
          onChange={(data) => {
            onDateRangeChanged(data.range)
            setDateRangeInterval(data)
          }}
        />
      </Stack>
      <PropertyEventList id={property.id} property={property} />
    </>
  )
}
