import { uniq } from 'ramda'
import { createSelector } from 'redux-bundler'
import createAsyncResourceBundle from 'redux-bundler/dist/create-async-resource-bundle'

import * as Sentry from '@sentry/react'
import ms from 'milliseconds'

import { getStoredTokens } from '@common/config'

const CURRENT_ORGANIZATION_UPDATED = 'CURRENT_ORGANIZATION_UPDATED'
const CURRENT_ACCOUNT_UPDATED = 'CURRENT_ACCOUNT_UPDATED'
const RESET_ME = 'RESET_ME'

const entityName = 'me'

const meBundle = createAsyncResourceBundle({
  name: entityName,
  retryAfter: ms.seconds(10),
  persist: true,
  getPromise: async ({ apiFetch }) =>
    apiFetch('/me/', null, { useOrgHeader: true, cancelationPrefix: entityName }),
})

export default {
  ...meBundle,
  reducer: (state, action) => {
    if ([CURRENT_ORGANIZATION_UPDATED, CURRENT_ACCOUNT_UPDATED].includes(action.type)) {
      return { ...state, ...action.payload }
    }

    if (action.type === RESET_ME) {
      const token = getStoredTokens()
      return {
        ...state,
        currentOrganization: token.organization,
        currentAccount: token.account,
        isOutdated: true,
      }
    }

    if (action.type === 'ME_FETCH_FINISHED') {
      const newState = meBundle.reducer(state, action)
      const token = getStoredTokens()

      let organization = newState?.currentOrganization ?? token.organization
      let account = newState?.currentAccount ?? token.account

      // If for some reason we don't have a current organization or account, try to set it to the first one available
      if (!organization || !account) {
        const org = newState?.data?.organizations?.[0]
        if (org) {
          const acc = newState?.data?.accounts?.filter(
            (a) => a.organization === org.id,
          )?.[0]
          if (acc) {
            organization = org.id
            account = acc.id
          }
        }
      }

      return {
        ...newState,
        currentOrganization: organization,
        currentAccount: account,
      }
    }

    if (action.type === 'ME_FETCH_FAILED') {
      if (action?.error?.error?.status === 401) {
        return {
          ...state,
          unauthorized: true,
        }
      }
    }
    return meBundle.reducer(state, action)
  },
  reactMeFetch: createSelector(
    'selectIsAuthenticated',
    'selectMeShouldUpdate',
    (authenticated, shouldUpdate) => {
      if (authenticated && shouldUpdate) {
        return { actionCreator: 'doFetchMe' }
      }
      return undefined
    },
  ),
  reactSetSentry: createSelector('selectMe', (me) => {
    if (me) {
      Sentry.setUser({ email: me.email, id: me.id, username: me.name })
    } else {
      Sentry.setUser(null)
    }
    return undefined
  }),
  reactUnauthorized: createSelector('selectMeRaw', (meRaw) => {
    if (meRaw?.unauthorized) {
      return { actionCreator: 'doAuthLogout' }
    }
    return undefined
  }),
  selectRouteQuery: createSelector(
    'selectRouteParams',
    () =>
      new Proxy(new URLSearchParams(globalThis.location.search), {
        get: (searchParams, prop) => searchParams.get(prop),
      }),
  ),
  selectCurrentOrganization: createSelector(
    'selectMeRaw',
    (me) => me?.currentOrganization,
  ),
  selectCurrentOrganizationDetails: createSelector(
    'selectCurrentOrganization',
    'selectAvailableOrganizations',
    (currentOrganization, availableOrganizations) =>
      availableOrganizations.find((org) => org.id === currentOrganization),
  ),
  selectCurrentAccount: createSelector('selectMeRaw', (me) => me?.currentAccount),
  selectCurrentAccountDetails: createSelector(
    'selectCurrentAccount',
    'selectAvailableAccounts',
    (currentAccount, availableAccounts) =>
      availableAccounts.find((acc) => acc.id === currentAccount),
  ),
  selectCurrentRole: createSelector(
    'selectMe',
    'selectCurrentProperty',
    (me, currentProperty) => {
      if (!me?.role && currentProperty) {
        const roleId = me.roles?.propertyRoles?.find(
          (item) => item.id === currentProperty.id,
        )?.role
        return me.roles?.roles?.find((role) => role.id === roleId)
      }
      return me?.role
    },
  ),
  selectAvailableOrganizations: createSelector('selectMeRaw', (me) => {
    const availableOrgs = me?.data?.accounts?.map((acc) => acc.organization) ?? []
    return (
      me?.data?.organizations?.filter((org) => availableOrgs.indexOf(org.id) !== -1) ??
      []
    )
  }),
  selectAvailableAccounts: createSelector('selectMeRaw', (me) => {
    const currentOrg = me?.currentOrganization
    return me?.data?.accounts?.filter((acc) => acc.organization === currentOrg) ?? []
  }),
  selectAvailableProperties: createSelector('selectMeRaw', (me) =>
    me?.data?.properties?.filter((property) => property.account === me?.currentAccount),
  ),
  selectFlags: createSelector(
    'selectSystem',
    'selectCurrentAccount',
    'selectAvailableAccounts',
    'selectMe',
    (system, currentAccount, availableAccounts, me) => {
      const systemFlags = system?.flags ?? []
      const userFlags = me?.flags ?? []
      const accountFlags =
        availableAccounts.find((acc) => acc.id === currentAccount)?.flags ?? []
      return uniq([...systemFlags, ...accountFlags, ...userFlags])
    },
  ),
  selectGuestMessageMirroring: createSelector(
    'selectMeRaw',
    (me) => me?.data?.guestMessageMirroringEnabled ?? false,
  ),
  selectIsStaff: createSelector('selectMeRaw', (me) => me?.data?.isStaff ?? false),
  selectIsSuperuser: createSelector(
    'selectMeRaw',
    (me) => me?.data?.isSuperuser ?? false,
  ),
  selectIsPortalUser: createSelector('selectMeRaw', (me) => !!me?.data?.portalRole),
  doSetCurrentOrganization:
    (payload) =>
    ({ dispatch, store }) => {
      const me = store.selectMeRaw()
      const selectedAccount = me?.data?.accounts.find(
        (acc) => acc.organization === payload,
      )

      if (selectedAccount) {
        dispatch({
          type: CURRENT_ORGANIZATION_UPDATED,
          payload: {
            currentAccount: selectedAccount.id,
            currentOrganization: selectedAccount.organization,
          },
        })
        store.doMarkHeaderAsOutdated()
        store.doMarkMeAsOutdated()
        return true
      }
      return false
    },
  doSetCurrentAccount:
    (payload) =>
    ({ dispatch, store }) => {
      const me = store.selectMeRaw()
      const selectedAccount = me?.data?.accounts.find((acc) => acc.id === payload)

      if (selectedAccount) {
        dispatch({
          type: CURRENT_ACCOUNT_UPDATED,
          payload: {
            currentAccount: selectedAccount.id,
            currentOrganization: selectedAccount.organization,
          },
        })
        store.doMarkHeaderAsOutdated()
        store.doMarkMeAsOutdated()
        store.doUpdateUrl('/')
        return true
      }
      return false
    },
  doResetMe:
    () =>
    ({ dispatch }) => {
      dispatch({ type: RESET_ME })
    },
  persistActions: [
    ...meBundle.persistActions,
    CURRENT_ORGANIZATION_UPDATED,
    CURRENT_ACCOUNT_UPDATED,
  ],
}
