import { useEffect, useState } from 'react'

import { useConnect } from 'redux-bundler-hook'

import Box from '@mui/material/Box'

import { APIProvider, Map } from '@vis.gl/react-google-maps'

import { config } from '@common/config'

import ClusteredPropertyMarkers from './ClusteredPropertyMarkers'

export const DEFAULT_ZOOM = 5

export default function ClientMap() {
  const [selectedProperty, setSelectedProperty] = useState(null)
  const { allProperties, allIssues, doPropertyListFetchAll, doIssueListFetchAll } =
    useConnect(
      'selectAllProperties',
      'selectAllIssues',
      'doPropertyListFetchAll',
      'doIssueListFetchAll',
    )

  useEffect(() => {
    doPropertyListFetchAll({ active: true, hasGoLiveDate: true })
    doIssueListFetchAll()
  }, [])

  const properties = allProperties?.map((p) => {
    const issue = allIssues?.find((i) => i.property === p.id)
    return {
      ...p,
      issueCategory: issue?.category,
      issueComments: issue?.comments,
      issueStart: issue?.start,
      issueEnd: issue?.end,
      issueSnoozedUntil: issue?.snoozedUntil,
    }
  })

  return (
    <Box>
      <APIProvider apiKey={config.GOOGLE_MAPS_KEY}>
        <Map
          disableDefaultUI
          defaultZoom={DEFAULT_ZOOM}
          gestureHandling="greedy"
          mapId="242635a03229aece"
          defaultCenter={{ lat: 38.5, lng: -95 }}
          style={{ width: '100%', height: '90vh' }}
          onClick={() => setSelectedProperty(null)}
        />
        <ClusteredPropertyMarkers
          properties={properties || []}
          selectedProperty={selectedProperty}
          setSelectedProperty={setSelectedProperty}
        />
      </APIProvider>
    </Box>
  )
}
