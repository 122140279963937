import { useCallback, useEffect, useState } from 'react'

import { pathOr } from 'ramda'
import { useConnect, useReduxBundlerStore } from 'redux-bundler-hook'

import { Alert, Box, Button, Tooltip, Typography } from '@mui/material'

import { Field, Form, Formik } from 'formik'
import * as Yup from 'yup'

import logo from '@assets/logo_dark.svg'
import { Loading } from '@common/components'
import { TextField } from '@common/components/Form'
import { getApiFetch, parseApiErrors, useSmallScreen } from '@common/utils'

import { loginBoxStyles, loginFormStyles, loginLogoStyles } from './login.styles'
import loginUrls from './urls'

export default function PasswordReset() {
  const [error, setError] = useState()
  const [loadingTokenValidation, setLoadingTokenValidation] = useState(true)
  const {
    route,
    routeParams: { token },
    doUpdateUrl,
    doShowSnackbar,
  } = useConnect('selectRoute', 'selectRouteParams', 'doUpdateUrl', 'doShowSnackbar')

  const isSmallScreen = useSmallScreen()
  const store = useReduxBundlerStore()
  const apiFetch = getApiFetch(store)

  const validationSchema = Yup.object().shape({
    password1: Yup.string()
      .required()
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
        'Must contain at least 8 characters, one uppercase, one lowercase, one number, and one special character.',
      ),
    password2: Yup.string()
      .oneOf([Yup.ref('password1'), null], 'Passwords must match')
      .required('This is a required field.'),
  })

  let welcomeText = 'Please enter a new password.'
  let buttonText = 'Reset Password'
  let successMessage = 'Password successfully updated.'

  if (pathOr(false, ['isNew'], route)) {
    welcomeText =
      'Welcome to Rest!! Please enter a new password to finish setting up your account.'
    buttonText = 'Create Password'
    successMessage = 'Password successfully created.'
  }

  useEffect(() => {
    async function checkToken() {
      try {
        await apiFetch(
          '/password/validate/',
          { token },
          {
            useAuth: false,
            useOrgHeader: false,
            useAccountHeader: false,
          },
        )
        setLoadingTokenValidation(false)
      } catch (e) {
        doUpdateUrl(`${loginUrls.forgotPassword}?tokenExpired=1`)
      }
    }
    checkToken()
  }, [])

  const handleFormSubmit = useCallback(
    async (values) => {
      setError(null)
      try {
        await apiFetch('/password/reset/', values, {
          method: 'POST',
          useAuth: false,
          useOrgHeader: false,
          useAccountHeader: false,
        })
        doShowSnackbar(successMessage)
        doUpdateUrl(loginUrls.login)
        return true
      } catch (e) {
        const errorMessage = parseApiErrors(e?.response)
        if (errorMessage) {
          setError(errorMessage)
        }
        return false
      }
    },
    [apiFetch],
  )

  const width = isSmallScreen ? '100%' : loginFormStyles.width

  if (loadingTokenValidation) {
    return <Loading />
  }

  return (
    <Box style={loginBoxStyles}>
      <img alt="Rest" src={logo} style={loginLogoStyles} />
      <Box width={width} mb={3}>
        <Typography variant="h6">{welcomeText}</Typography>
      </Box>
      <Formik
        initialValues={{
          token,
          password1: '',
          password2: '',
        }}
        validationSchema={validationSchema}
        validateOnMount
        validateOnChange
        onSubmit={handleFormSubmit}
      >
        {({ isSubmitting, isValid }) => (
          <Form style={{ ...loginFormStyles, width }}>
            {error && <Alert severity="error">{error}</Alert>}
            <Tooltip
              title="Must contain at least 8 characters, one uppercase, one lowercase, one number, and one special character"
              arrow
            >
              <div>
                <Field
                  data-testid="reset-pw-password1"
                  component={TextField}
                  name="password1"
                  label="New password"
                  type="password"
                  fullWidth
                />
              </div>
            </Tooltip>
            <Field
              data-testid="reset-pw-password2"
              component={TextField}
              name="password2"
              label="Confirm password"
              type="password"
            />
            <Button
              data-testid="reset-pw-submit"
              variant="contained"
              color="primary"
              type="submit"
              disabled={!isValid || isSubmitting}
            >
              {buttonText}
            </Button>
          </Form>
        )}
      </Formik>
    </Box>
  )
}
