import { useMemo } from 'react'

import { Chip } from '@mui/material'

import { titleize } from 'inflection'

import {
  INVOICE_STATUS_PAID,
  INVOICE_STATUS_PAST_DUE,
  INVOICE_STATUS_VOIDED,
  INVOICE_STATUS_WAITING_FOR_PAYMENT,
} from './constants'

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {string} props.status
 * @param {string} [props.size]
 */
export default function InvoiceStatusChip({ status, size = undefined }) {
  const label = useMemo(() => titleize(status.toLowerCase()), [status])

  const color = useMemo(() => {
    switch (status) {
      case INVOICE_STATUS_PAID:
        return 'success.main'
      case INVOICE_STATUS_PAST_DUE:
        return 'error.main'
      case INVOICE_STATUS_WAITING_FOR_PAYMENT:
        return '#cbf5fd'
      case INVOICE_STATUS_VOIDED:
      default:
        return 'grey.500'
    }
  }, [status])

  const textColor = useMemo(
    () => (status === INVOICE_STATUS_WAITING_FOR_PAYMENT ? 'grey.800' : 'white'),
    [status],
  )

  return (
    <Chip
      size={size ?? 'small'}
      label={label}
      sx={{
        backgroundColor: color,
        '& .MuiChip-label': { color: textColor },
      }}
    />
  )
}
