import { useMemo } from 'react'

import { useConnect } from 'redux-bundler-hook'

import { KeyboardArrowDown } from '@mui/icons-material'
import {
  Box,
  FormControl,
  MenuItem,
  Select,
  Tab,
  Tabs,
  Typography,
} from '@mui/material'

import { useSmallScreen } from '@common/utils'
import invoiceUrls from '@rest/pages/Invoices/urls'
import propertyUrls from '@rest/pages/Properties/urls'
import reservationUrls from '@rest/pages/Reservations/urls'
import smokeMetricsUrls from '@rest/pages/SmokeMetrics/urls'
import { usePrevilegeCheck } from '~/rest/Utils/PrivilegeCheck'

export default function NavigationTabs() {
  const { routeInfo, doUpdateUrl, flags } = useConnect(
    'selectRouteInfo',
    'doUpdateUrl',
    'selectFlags',
  )

  const isSmallScreen = useSmallScreen()
  const isInvoicesEnabled = usePrevilegeCheck({
    flags: ['BILLING_HISTORY'],
    permissions: ['view_invoice'],
  })

  const tabs = [
    { label: 'Properties', route: propertyUrls.list, alsoMatch: ['rooms'] },
    ...(flags.includes('RESERVATION_INPUT')
      ? [{ label: 'Reservations', route: reservationUrls.reservations }]
      : []),
    ...(isInvoicesEnabled ? [{ label: 'Invoices', route: invoiceUrls.list }] : []),
    { label: 'Metrics', route: smokeMetricsUrls.metrics },
  ]

  const tabValue = useMemo(() =>
    tabs.findIndex((tab) =>
      routeInfo.url.length > 1
        ? routeInfo.url.includes(tab.label.toLowerCase()) ||
          tab.alsoMatch?.some((value) => routeInfo.url.includes(value))
        : routeInfo.url === tab.route,
    ),
  )

  return (
    <Box data-testid="nav_tabs" sx={{ mb: isSmallScreen ? 2 : 3 }}>
      {isSmallScreen ? (
        <FormControl fullWidth size="small" sx={{ mt: 1 }}>
          <Select
            value={tabValue}
            IconComponent={KeyboardArrowDown}
            onChange={(event) => {
              const newIndex = event.target.value
              if (newIndex !== -1) {
                doUpdateUrl(tabs.at(newIndex).route)
              }
            }}
          >
            {tabs.map(({ label, route }) => (
              <MenuItem
                key={route}
                value={tabs.findIndex((tab) => tab.route === route)}
              >
                {label} View
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ) : (
        <Tabs value={tabValue} textColor="primary" indicatorColor="primary">
          {tabs.map(({ label, route }) => (
            <Tab
              onClick={() => doUpdateUrl(route)}
              href={route}
              key={route}
              label={
                <Typography variant="h5" sx={{ textTransform: 'none' }}>
                  {label}
                </Typography>
              }
            />
          ))}
        </Tabs>
      )}
    </Box>
  )
}
