import { useState } from 'react'

import { RotateLeft as RotateLeftIcon } from '@mui/icons-material'
import { Box } from '@mui/material'

import { ControlPosition, MapControl } from '@vis.gl/react-google-maps'
import { IconButton } from 'rsuite'

/**
 * A component that handles map control logic.
 *
 * @component
 * @param {Object} props - The props for the component.
 * @param {Object} props.map
 */
export default function Control({ map }) {
  const [showResetZoom, setShowResetZoom] = useState(false)

  map?.addListener('zoom_changed', () => setShowResetZoom(true))

  return (
    <MapControl position={ControlPosition.TOP_LEFT}>
      <Box gap={2} sx={{ padding: 2, display: 'flex', alignItems: 'flex-start' }}>
        {showResetZoom && (
          <IconButton
            sx={{ background: 'white', margin: 1 }}
            size="lg"
            icon={<RotateLeftIcon />}
            onClick={() => {
              map.setZoom(5)
              setShowResetZoom(false)
            }}
          />
        )}
      </Box>
    </MapControl>
  )
}
