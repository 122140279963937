import { useCallback, useMemo, useState } from 'react'

import { isEmpty } from 'ramda'

import { KeyboardArrowDownRounded, KeyboardArrowUpRounded } from '@mui/icons-material'
import { Box, Collapse, Typography } from '@mui/material'

import { useSmallScreen } from '@common/utils'

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {Object} props.data
 */
export default function CollapsableData({ data }) {
  const isSmallScreen = useSmallScreen()

  const [open, setOpen] = useState(false)

  const sort = (a, b) => {
    const prioriryFields = ['operation', 'eventType']
    if (prioriryFields.includes(a)) return -1
    return prioriryFields.includes(b) ? 1 : 0
  }

  const sortedDataKeys = useMemo(
    () => (data ? Object.keys(data).sort(sort) : []),
    [data],
  )

  const ActionIcon = open ? KeyboardArrowUpRounded : KeyboardArrowDownRounded

  const DataRow = useCallback(
    ({ dataKey }) => (
      <Typography
        noWrap
        key={`${dataKey}_${data[dataKey]}`}
        variant="body2"
        textAlign={isSmallScreen ? 'end' : 'start'}
        sx={{ fontSize: '12px', overflow: 'auto', textOverflow: 'unset' }}
      >
        <strong>{dataKey}</strong>: {data[dataKey]}
      </Typography>
    ),
    [data, isSmallScreen],
  )

  if (isEmpty(sortedDataKeys)) {
    return '--'
  }

  return (
    <Box width={1}>
      <Box
        display="flex"
        gap={0.5}
        alignItems="center"
        justifyContent={isSmallScreen ? 'end' : 'start'}
        width={1}
      >
        <DataRow dataKey={sortedDataKeys[0]} />
        {sortedDataKeys.length > 1 && (
          <ActionIcon
            sx={{ fontSize: 16, cursor: 'pointer' }}
            onClick={() => setOpen((v) => !v)}
          />
        )}
      </Box>
      {sortedDataKeys.length > 1 && (
        <Collapse in={open}>
          <Box width="100%">
            {sortedDataKeys.slice(1).map((key) => (
              <DataRow key={key} dataKey={key} />
            ))}
          </Box>
        </Collapse>
      )}
    </Box>
  )
}
