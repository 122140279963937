import { useCallback, useState } from 'react'

import { useConnect, useReduxBundlerStore } from 'redux-bundler-hook'

import { Box } from '@mui/material'

import { Field } from 'formik'
import { DateTime } from 'luxon'
import * as yup from 'yup'

import countryList from '@assets/country_list.json'
import { GoogleAutocomplete, TextField } from '@common/components/Form'
import {
  FormikDynamicSelect,
  FreeSoloDynamicSelect,
  StaticSelect,
} from '@common/components/Selects'
import { getApiFetch, humanizePropertyType, parseApiErrors } from '@common/utils'
import FormDialog from '@portal/UI/components/FormDialog'
import FormikStatePropagator from '@portal/UI/components/FormikStatePropagator'

import CountryField from './CountryField'

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {boolean} props.open
 * @param {Function} props.onClose
 * @param {Object} [props.accountFilter]
 * @param {Object} [props.instance={}]
 * @param {string} [props.instance.id]
 * @param {string} [props.instance.address1]
 * @param {string} [props.instance.address2]
 * @param {string} [props.instance.city]
 * @param {string} [props.instance.state]
 * @param {string} [props.instance.country]
 * @param {string} [props.instance.zipCode]
 * @param {string} [props.instance.account]
 * @param {string} [props.instance.name]
 * @param {string} [props.instance.timezone]
 * @param {string} [props.instance.propertyType]
 * @param {string} [props.instance.group]
 * @param {string} [props.instance.salesforceAccountId]
 * @param {string} [props.instance.goLiveDate]
 * @param {string[]} [props.instance.expandedFlags]
 *
 */
export default function PropertyForm({
  open,
  onClose,
  instance = {},
  accountFilter = undefined,
}) {
  const {
    doShowSnackbar,
    doPropertySave,
    propertySaveStatus,
    systemTimeZones,
    propertyBrands,
    systemPropertyTypes,
  } = useConnect(
    'doShowSnackbar',
    'doPropertySave',
    'selectPropertySaveStatus',
    'selectSystemTimeZones',
    'selectPropertyBrands',
    'selectSystemPropertyTypes',
  )

  const validationSchema = yup.object().shape({
    address1: yup.string().max(40).required(),
    address2: yup.string().max(40),
    city: yup.string().max(25).required(),
    state: yup.string().max(25).required(),
    country: yup.string().max(25).nullable().required(),
    zipCode: yup.string().max(10).required(),
    account: yup.string().max(150).required('Please enter an account'),
    name: yup.string().max(255).required('Please enter a name'),
    timezone: yup.string().max(25).required('Please select a timezone'),
    propertyType: yup.string().max(10).required('Please select a property type'),
    salesforceAccountId: yup.string().nullable(),
    brand: yup.string().max(50),
    group: yup.string().nullable(),
    goLiveDate: yup.date().nullable(),
    smokingFee: yup
      .number()
      .typeError('Please enter a valid amount')
      .test(
        'is-decimal',
        'Please enter a valid amount',
        (value) =>
          value === undefined ||
          value === 0 ||
          `${value}`.match(/^[1-9]\d*(\.\d{0,2})?$/),
      ),
  })

  const initialValues = {
    id: '',
    address1: '',
    address2: '',
    brand: '',
    city: '',
    state: '',
    country: '',
    zipCode: '',
    account: '',
    name: '',
    timezone: '',
    propertyType: '',
    salesforceAccountId: '',
    group: '',
    goLiveDate: null,
    smokingFee: '250.00',
    liveSmokeAlerts: false,
    autopopulateSmokeFee: false,
    installationApproved: false,
    latitude: null,
    longitude: null,
  }

  if (instance?.id) {
    Object.keys(initialValues).forEach((field) => {
      initialValues[field] = instance[field] ?? initialValues[field]
    })
    initialValues.goLiveDate = instance.goLiveDate
      ? DateTime.fromISO(instance.goLiveDate)
      : null
  }

  const [formikProps, setFormikProps] = useState({
    values: {},
    setFieldValue: () => {},
  })
  const [countryValue, setCountryValue] = useState(initialValues.country)

  const sharedStyles = { flex: 1 }

  const stateOptions = countryList
    .find(
      (country) =>
        country.iso3.toLowerCase() === countryValue?.toLowerCase() ||
        country.iso2.toLowerCase() === countryValue?.toLowerCase(),
    )
    ?.regions?.map((element) => element.code)

  const humanizeStateLabel = (value) => {
    const countryWithStates = countryList.filter((element) => element.regions)
    if (countryWithStates?.length) {
      const states = []
      countryWithStates.forEach((element) => states.push(...element.regions))
      return (
        states.find((element) => element.code.toLowerCase() === value.toLowerCase())
          ?.name || value
      )
    }
    return value
  }

  const store = useReduxBundlerStore()
  const apiFetch = getApiFetch(store)

  const createGroup = useCallback(async ({ groupName, account, id: propertyId }) => {
    const result = await apiFetch(
      '/property_groups/',
      { name: groupName, properties: propertyId ? [propertyId] : [], account },
      { method: 'POST', cancelationPrefix: 'property_form' },
    )
    return result?.id
  }, [])

  const updateProperty = async (params) => {
    try {
      const newGroupId = params.groupName ? await createGroup(params) : null
      const finalGroup = newGroupId ?? params.group

      const saveResult = await doPropertySave({
        ...params,
        address2: params.address2 || '',
        group: finalGroup,
        goLiveDate: params.goLiveDate ? params.goLiveDate.toISODate() : null,
      })

      return saveResult
    } catch (err) {
      const parsedError = parseApiErrors(err?.response)
      doShowSnackbar(parsedError, 'error')
      throw err
    }
  }

  return (
    <FormDialog
      label="Property"
      open={open}
      onSave={updateProperty}
      onClose={onClose}
      initialValues={initialValues}
      validationSchema={validationSchema}
      isLoading={propertySaveStatus === 'loading'}
    >
      <Box display="flex" flexDirection="column" gap="1rem">
        <Box display="flex" justifyContent="space-evenly" gap="2rem">
          <Field
            required
            component={TextField}
            label="Name"
            name="name"
            sx={sharedStyles}
          />
          <Field
            required
            disabled={instance?.id}
            name="account"
            label="Account"
            endpoint="accounts"
            variant="standard"
            component={FormikDynamicSelect}
            onChange={() => formikProps.setFieldValue('group', null)}
            filters={accountFilter}
            sx={sharedStyles}
          />
        </Box>
        <Box display="flex" justifyContent="space-evenly" gap="2rem">
          <Field
            required
            component={StaticSelect}
            label="Property Type"
            name="propertyType"
            variant="standard"
            options={systemPropertyTypes}
            optionLabelFormatter={humanizePropertyType}
            sx={sharedStyles}
          />
          <Field
            autoSelect
            name="group"
            altField="groupName"
            label="Property Group"
            endpoint="property_groups"
            initialValue={initialValues.group}
            filters={{ account: formikProps.values.account || instance?.account }}
            component={FreeSoloDynamicSelect}
            disabled={!formikProps.values.account}
            sx={sharedStyles}
          />
        </Box>
        <Box display="flex" justifyContent="space-evenly" gap="2rem">
          <Field
            required
            component={GoogleAutocomplete}
            label="Address 1"
            name="address1"
            variant="standard"
            sx={sharedStyles}
            onSelected={(data, form) => {
              const { address, city, state, country, zipCode } = data

              form.setFieldValue('address1', address || '')
              form.setFieldValue('address2', '')
              form.setFieldValue('city', city || '')
              form.setFieldValue('country', country || '')
              form.setFieldValue('state', state || '')
              form.setFieldValue('zipCode', zipCode || '')
            }}
          />
          <Field
            component={TextField}
            label="Address 2"
            name="address2"
            sx={sharedStyles}
          />
        </Box>
        <Box display="flex" justifyContent="space-evenly" gap="2rem">
          <Field
            required
            component={TextField}
            label="City"
            name="city"
            sx={sharedStyles}
          />
          <Field
            required
            freeSolo={!stateOptions}
            component={StaticSelect}
            label="State"
            name="state"
            variant="standard"
            options={stateOptions}
            optionLabelFormatter={humanizeStateLabel}
            sx={sharedStyles}
          />
        </Box>
        <Box display="flex" justifyContent="space-evenly" gap="2rem">
          <CountryField
            sx={sharedStyles}
            countryStateValue={countryValue}
            setCountryValue={setCountryValue}
          />
          <Field
            required
            component={TextField}
            label="Zip code"
            name="zipCode"
            sx={sharedStyles}
          />
        </Box>
        <Box display="flex" gap="2rem">
          <Field
            required
            component={StaticSelect}
            label="Timezone"
            name="timezone"
            options={systemTimeZones}
            variant="standard"
            sx={sharedStyles}
          />
          <Field
            component={StaticSelect}
            label="Brand"
            name="brand"
            options={propertyBrands}
            variant="standard"
            sx={sharedStyles}
          />
        </Box>
        <Field
          component={TextField}
          label="Salesforce ID"
          name="salesforceAccountId"
          sx={sharedStyles}
        />
      </Box>
      <FormikStatePropagator propSetter={setFormikProps} />
    </FormDialog>
  )
}
