import { useState } from 'react'

import { useConnect, useReduxBundlerStore } from 'redux-bundler-hook'

import { Box } from '@mui/material'
import { DateTimePicker } from '@mui/x-date-pickers'

import { Field } from 'formik'
import { DateTime } from 'luxon'
import * as yup from 'yup'

import { TextField } from '@common/components/Form'
import { getApiFetch, parseApiErrors } from '@common/utils'
import FormDialog from '@portal/UI/components/FormDialog'
import FormikStatePropagator from '@portal/UI/components/FormikStatePropagator'

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {boolean} props.open
 * @param {Function} props.onClose
 * @param {Object} [props.instance]
 * @param {string} props.instance.id
 * @param {string} [props.instance.snoozedUntil]
 * @param {string} [props.instance.comments]
 */
export default function IssueForm({ open, onClose, instance = undefined }) {
  const { doShowSnackbar } = useConnect('doShowSnackbar')

  const [formikProps, setFormikProps] = useState({
    values: {},
    setFieldValue: () => {},
  })

  const validationSchema = yup.object().shape({
    snoozedUntil: yup.string().nullable(),
    comments: yup.string(),
  })

  const initialValues = {
    id: '',
    snoozedUntil: '',
    comments: '',
  }

  if (instance) {
    Object.keys(initialValues).forEach((field) => {
      initialValues[field] = instance[field]
    })
  }

  const store = useReduxBundlerStore()
  const apiFetch = getApiFetch(store)

  const updateIssue = async (payload) => {
    try {
      const result = await apiFetch(
        '/issues/',
        { ...payload, snoozedUntil: payload.snoozedUntil || null },
        {
          method: 'PUT',
          cancelationPrefix: 'issue_form',
        },
      )
      return result
    } catch (err) {
      const parsedError = parseApiErrors(err?.response)
      doShowSnackbar(parsedError, 'error')
    }

    return null
  }

  const sx = { flex: 1 }

  return (
    <FormDialog
      label="Update Issue"
      open={open}
      onSave={updateIssue}
      onClose={onClose}
      initialValues={initialValues}
      validationSchema={validationSchema}
      successMessage="Issue has been updated!"
    >
      <Box display="flex" flexDirection="column" gap={1}>
        <Field
          component={DateTimePicker}
          variant="standard"
          label="Snoozed Until"
          name="snoozedUntil"
          slotProps={{
            textField: { variant: 'standard' },
            field: { clearable: true },
          }}
          value={
            formikProps?.values?.snoozedUntil
              ? DateTime.fromISO(formikProps?.values?.snoozedUntil)
              : null
          }
          onChange={(value) => {
            formikProps?.setFieldValue('snoozedUntil', value?.toISO())
          }}
          sx={sx}
        />
        <Field component={TextField} label="Comments" name="comments" sx={sx} />
      </Box>
      <FormikStatePropagator propSetter={setFormikProps} />
    </FormDialog>
  )
}
