import { useEffect, useRef, useState } from 'react'

import { isEmpty } from 'ramda'
import { useConnect, useReduxBundlerStore } from 'redux-bundler-hook'

import DeleteIcon from '@mui/icons-material/Delete'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material'

import { SearchBox } from '@common/components'
import { getApiFetch, parseApiErrors } from '@common/utils'
import { List } from '@rest/UI/components'
import { StaticSelect } from '~/common/components/Selects'

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {Object} props.rateCodes
 * @param {Object} props.property
 * @param {string} [props.maxWidth]
 * @param {Function} [props.onClose]
 * @param {boolean} props.open
 */
export default function UnitRateCodeForm({
  rateCodes,
  open,
  property,
  onClose = () => {},
  maxWidth = 'sm',
}) {
  const selectedUnits = useRef()
  const [rows, setRows] = useState([])
  const [seletedRateCode, setSeletedRateCode] = useState('')

  const {
    unitList,
    unitListIsLoading,
    doShowSnackbar,
    doUnitListSetFilter,
    doUnitListSetSearch,
    doUnitListSetPageSize,
    doFetchUnitList,
    doUnitListSetPage,
    doUnitListSetOrdering,
  } = useConnect(
    'selectUnitList',
    'selectUnitListIsLoading',
    'doShowSnackbar',
    'doUnitListSetFilter',
    'doUnitListSetSearch',
    'doUnitListSetPageSize',
    'doFetchUnitList',
    'doUnitListSetPage',
    'doUnitListSetOrdering',
  )

  useEffect(() => {
    doUnitListSetFilter({ property: property.id, active: true })
    doUnitListSetPageSize(100)
    doUnitListSetSearch('')
    doFetchUnitList()
  }, [])

  useEffect(() => {
    const selectedIds = selectedUnits?.current?.map((unit) => unit.id) || []
    const nonSelectedUnits = unitList?.results?.filter(
      (unit) => !selectedIds.includes(unit.id),
    )
    setRows([...(selectedUnits?.current || []), ...nonSelectedUnits])
  }, [unitList])

  const store = useReduxBundlerStore()
  const apiFetch = getApiFetch(store)

  const handleClose = () => {
    setRows([])
    doUnitListSetSearch('')
    setSeletedRateCode('')
    onClose()
  }

  const handleApiErr = (err) => {
    const parsedError = parseApiErrors(err?.response)
    doShowSnackbar(parsedError)
  }

  const handleRowSelection = (selection) => {
    const units = rows.filter((row) => selection.includes(row.id))
    const nonSelectedUnits = rows.filter((row) => !selection.includes(row.id))
    selectedUnits.current = units
    setRows([...units, ...nonSelectedUnits])
  }

  const handleUpdate = async (units, rateCode, deletePressed = false) => {
    const payload = units?.map((unit) => ({ id: unit.id, rateCode }))
    try {
      const result = await apiFetch('/units/', payload, {
        method: 'PUT',
        cancelationPrefix: 'unit_rate_code_form',
      })

      const message =
        rateCode === ''
          ? `Successfully removed rate code from ${result.length} units`
          : `Successfully applied rate code ${seletedRateCode} to ${result.length} units`

      doShowSnackbar(message)
      doFetchUnitList()

      if (!deletePressed) handleClose()
      handleRowSelection([])
    } catch (err) {
      handleApiErr(err)
    }
  }

  const columns = [
    {
      field: 'name',
      flex: 1,
      renderCell: ({ row }) => (
        <Box display="flex" flexDirection="column" width="100%" alignItems="flex-start">
          <Typography variant="h6">{row.name}</Typography>
          <Typography variant="caption" color="info">
            {row.rateCode}
          </Typography>
        </Box>
      ),
    },
    {
      field: '',
      flex: 0.2,
      renderCell: ({ row }) =>
        row?.rateCode ? (
          <Tooltip title="Remove rate code" placement="top">
            <IconButton
              edge="end"
              onClick={() => handleUpdate([row], '', true)}
              disabled={selectedUnits?.current?.length > 0}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        ) : null,
    },
  ]

  const handlePageChange = (page) => {
    doUnitListSetPage(page)
    doFetchUnitList()
  }

  const handlePageSizeChange = (pageSize) => {
    doUnitListSetPageSize(pageSize)
    doFetchUnitList()
  }

  return (
    <Dialog fullWidth open={open} onClose={handleClose} maxWidth={maxWidth}>
      <DialogTitle display="flex" justifyContent="center" sx={{ fontSize: '1.7rem' }}>
        Apply Rate Code to {selectedUnits?.current?.length || 0} Units
      </DialogTitle>
      <DialogContent sx={{ padding: 3, marginBottom: 2 }}>
        <Box gap={2} display="flex" alignItems="center" justifyContent="space-between">
          <StaticSelect
            size="small"
            label="Rate Code"
            value={seletedRateCode}
            onChange={(code) => setSeletedRateCode(code)}
            options={['None', ...Object.keys(rateCodes)]}
            sx={{ paddingTop: 1, width: '300px' }}
          />
          <SearchBox
            title="Units"
            minLength={0}
            onSetSearch={doUnitListSetSearch}
            sx={{ width: '300px' }}
          />
        </Box>
        <List
          density="standard"
          checkbox
          disableColumnMenu
          hideFooterSelectedRowCount
          noValuePlaceholder="--"
          rows={rows || []}
          page={unitList?.current ?? 0}
          pageSize={unitList?.pageSize ?? 100}
          rowCount={unitList?.count ?? 0}
          pageChange={handlePageChange}
          pageSizeChange={handlePageSizeChange}
          pageSizeOptions={[10, 25, 50, 100, 250, 500]}
          sortChange={doUnitListSetOrdering}
          loading={unitListIsLoading}
          onRowSelectionModelChange={handleRowSelection}
          columns={columns}
          sx={{
            '& .MuiDataGrid-columnHeaders': { display: 'none' },
            maxHeight: 600,
          }}
        />
      </DialogContent>
      <DialogActions display="flex" sx={{ justifyContent: 'space-between' }}>
        <Button color="error" onClick={handleClose}>
          Close
        </Button>
        <Button
          onClick={() =>
            handleUpdate(
              selectedUnits.current,
              seletedRateCode === 'None' ? '' : seletedRateCode,
            )
          }
          disabled={isEmpty(selectedUnits.current) || !seletedRateCode}
        >
          Update
        </Button>
      </DialogActions>
    </Dialog>
  )
}
