import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { equals, uniq } from 'ramda'
import { useConnect } from 'redux-bundler-hook'

import HolidayVillageIcon from '@mui/icons-material/HolidayVillage'
import HouseIcon from '@mui/icons-material/House'
import TableChartIcon from '@mui/icons-material/TableChart'
import TimelineIcon from '@mui/icons-material/Timeline'
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid2,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from '@mui/material'

import { pluralize, titleize } from 'inflection'
import { DateTime, Duration } from 'luxon'

import { Breadcrumbs, Loading } from '@common/components'
import { StaticMultiSelect } from '@common/components/Selects'
import { parseApiErrors } from '@common/utils'
import NavigationTabs from '@rest/UI/Navigation/NavigationTabs'

import getSmokeMetricsConstants from './constants'
import CustomDateModal from './CustomDateModal'
import ExportMetrics from './ExportMetrics'
import MetricCard from './MetricCard'
import metrics from './metrics'
import MonthWeekModal from './MonthWeekModal'

const { availableIntervals, datePresets, weeklyRanges, monthlyRanges } =
  getSmokeMetricsConstants()

export default function SmokeMetrics() {
  const [selectedProperties, setSelectedProperties] = useState([])

  const [selectedAggregationRange, setSelectedAggregationRange] = useState(4)
  const [selectedInterval, setSelectedInterval] = useState(availableIntervals.weekly)
  const [selectedView, setSelectedView] = useState('graph')
  const [selectedGrouping, setSelectedGrouping] = useState('property')
  const [datePreset, setDatePreset] = useState(datePresets.threeMonths.value)
  const [openCustomDate, setOpenCustomDate] = useState(false)
  const [openMonthWeekModal, setOpenMonthWeekModal] = useState(false)

  const [showTotal, setShowTotal] = useState(false)

  const calculateWeeksIntervalTimeframe = useCallback(
    (now) => {
      const offset = Duration.fromObject({ days: 1 })
      const start = now
        .minus({ weeks: selectedAggregationRange - 1 })
        .plus(offset)
        .startOf('week')
        .minus(offset)
      const end = now.endOf('week').minus(offset)
      return [start, end]
    },
    [selectedAggregationRange],
  )

  const [defaultStart, defaultEnd] = calculateWeeksIntervalTimeframe(DateTime.now())
  const [dateRangeValue, setDateRangeValue] = useState([defaultStart, defaultEnd])

  const requestParams = useRef({})

  const {
    smokeMetrics,
    smokeMetricsIsLoading,
    smokeMetricsError,
    smokeMetricProperties: availableProperties,
    currentOrganizationDetails: organization,
    doFetchSmokeMetrics,
    doFetchSmokeMetricsProperties,
    doResetSmokeMetricsState,
    doShowSnackbar,
    doSetHeaderLevel,
  } = useConnect(
    'selectSmokeMetrics',
    'selectSmokeMetricsIsLoading',
    'selectSmokeMetricsError',
    'selectSmokeMetricProperties',
    'selectCurrentOrganizationDetails',
    'doFetchSmokeMetrics',
    'doFetchSmokeMetricsProperties',
    'doResetSmokeMetricsState',
    'doShowSnackbar',
    'doSetHeaderLevel',
  )

  const enableGrouping = useMemo(
    () => availableProperties?.map((property) => property.group).length > 1,
    [availableProperties],
  )

  useEffect(() => {
    if (selectedInterval !== 1) {
      const now = DateTime.now()
      let start
      let end
      if (selectedInterval.value === 30) {
        start = now.minus({ months: selectedAggregationRange - 1 }).startOf('month')
        end = now.endOf('month')
      } else if (selectedInterval.value === 7) {
        const [calcStart, calcEnd] = calculateWeeksIntervalTimeframe(now)
        start = calcStart
        end = calcEnd
      }
      setDateRangeValue([start, end])
    }
  }, [selectedInterval, selectedAggregationRange])

  const fetchMetrics = async (params) => {
    const properties = params?.properties ?? selectedProperties
    if (properties.length !== 0 && dateRangeValue.every((value) => value !== null)) {
      requestParams.current = {
        start: dateRangeValue[0].toFormat('yyyy-MM-dd'),
        end: dateRangeValue[1].toFormat('yyyy-MM-dd'),
        intervalType: selectedInterval.type,
        interval: 1,
        metrics: [
          metrics.incidentRate,
          metrics.netCharges,
          metrics.netChargesPerEvent,
          metrics.netChargesPerDevice,
          metrics.netChargesPerChargedEvent,
          metrics.missedRevenue,
          metrics.chargeRate,
          metrics.grossChargeRate,
          metrics.adjustmentRate,
          metrics.eventsTotal,
          metrics.eventsCharged,
          metrics.connectivity,
        ].map((item) => item.apiValue),
      }

      if (selectedGrouping === 'group') {
        requestParams.current.grouping = 'property_group'
        requestParams.current.accounts = uniq(
          properties.map((property) => property.account),
        )
      } else {
        requestParams.current.grouping = 'property'
        requestParams.current.properties = properties.map((property) => property.id)
      }

      try {
        await doFetchSmokeMetrics(
          requestParams.current,
          selectedInterval.type,
          selectedInterval.value,
        )
      } catch (err) {
        const parsedError = parseApiErrors(err?.response)
        doShowSnackbar(parsedError, 'error')
      }
    }
  }

  useEffect(() => {
    doSetHeaderLevel('organization')
    async function fetchData() {
      try {
        const properties = await doFetchSmokeMetricsProperties()
        setSelectedProperties(properties ?? [])
        fetchMetrics({ properties })
      } catch (err) {
        const parsedError = parseApiErrors(err?.response)
        doShowSnackbar(parsedError, 'error')
      }
    }
    fetchData()
    return () => doResetSmokeMetricsState()
  }, [])

  useEffect(() => {
    fetchMetrics()
  }, [selectedGrouping, dateRangeValue])

  useEffect(() => {
    if (smokeMetricsError) {
      const parsedError = parseApiErrors(smokeMetricsError?.response)
      doShowSnackbar(parsedError, 'error')
    }
  }, [smokeMetricsError])

  const getPositionByApiValue = (value) =>
    Object.values(metrics).find((obj) => obj.apiValue === value)?.position ?? 99

  const isEmptyResult = smokeMetrics ? Object.keys(smokeMetrics).length === 0 : true

  const handlePresetChange = (value) => {
    if (value === datePresets.custom.value) {
      setOpenCustomDate(true)
      return
    }

    let start
    let end
    switch (value) {
      case datePresets.week.value: {
        start = DateTime.now().minus({ days: 7 })
        end = DateTime.now()
        break
      }
      case datePresets.month.value: {
        start = DateTime.now().minus({ month: 1 })
        end = DateTime.now()
        break
      }
      case datePresets.twoMonths.value: {
        start = DateTime.now().minus({ month: 2 })
        end = DateTime.now()
        break
      }
      case datePresets.threeMonths.value: {
        start = DateTime.now().minus({ month: 3 })
        end = DateTime.now()
        break
      }
      case datePresets.monthToDate.value: {
        start = DateTime.now().startOf('month')
        end = DateTime.now()
        break
      }
      case datePresets.lastMonth.value: {
        const prevMonth = DateTime.now().minus({ month: 1 })
        start = prevMonth.startOf('month')
        end = prevMonth.endOf('month')
        break
      }
      case datePresets.yearToDate.value: {
        start = DateTime.now().startOf('year')
        end = DateTime.now()
        break
      }
      default:
        throw Error(`${value} preset is not supported`)
    }
    setDatePreset(value)
    setDateRangeValue([start, end])
  }

  const aggregatedDateRanges = useMemo(() => {
    if (selectedInterval.value !== 1) {
      return selectedInterval.value === 7 ? weeklyRanges : monthlyRanges
    }
    return null
  }, [selectedInterval])

  const handleAggregatedDateRangeChange = (value) => {
    if (value === datePresets.custom.value) {
      setOpenMonthWeekModal(true)
      return
    }
    setSelectedAggregationRange(value)
  }

  const visibleDatePresetKeys = (() => {
    if (equals(selectedInterval, availableIntervals.weekly)) {
      return Object.keys(datePresets).filter(
        (key) => datePresets[key].value !== datePresets.week.value,
      )
    }
    if (equals(selectedInterval, availableIntervals.monthly)) {
      return Object.keys(datePresets).filter(
        (key) =>
          ![datePresets.week.value, datePresets.month.value].includes(
            datePresets[key].value,
          ),
      )
    }
    return Object.keys(datePresets)
  })()

  useEffect(() => {
    if (selectedInterval.value === 1) {
      handlePresetChange(datePresets.threeMonths.value)
    }
  }, [selectedInterval])

  return (
    <>
      <MonthWeekModal
        open={openMonthWeekModal}
        entity={selectedInterval.value === 7 ? 'week' : 'month'}
        onCancel={() => setOpenMonthWeekModal(false)}
        onSave={(value) => {
          setSelectedAggregationRange(value)
          setOpenMonthWeekModal(false)
        }}
      />
      <CustomDateModal
        open={openCustomDate}
        onCancel={() => setOpenCustomDate(false)}
        onSave={(dateRange) => {
          setDateRangeValue(dateRange)
          setDatePreset(datePresets.custom.value)
          setOpenCustomDate(false)
        }}
      />
      <Box>
        <Breadcrumbs
          links={[{ label: organization?.name || '--' }, { label: 'Metrics' }]}
        />
        <NavigationTabs />
        <Box display="flex" justifyContent="space-between" mb={2}>
          <Box display="flex" alignItems="end" gap={2}>
            <Stack direction="column" spacing={1}>
              <Typography variant="body1">Properties</Typography>
              <StaticMultiSelect
                required
                data-testid="property_selector"
                options={availableProperties}
                size="small"
                value={selectedProperties}
                onChange={setSelectedProperties}
                onSave={fetchMetrics}
                limitTags={1}
                groupBy={enableGrouping ? (option) => option.expandedGroupName : null}
                sortByFields={enableGrouping ? ['expandedGroupName', 'name'] : null}
                disabled={smokeMetricsIsLoading}
                renderGroup={(params) => (
                  <li key={params.key}>
                    <Typography
                      variant="h6"
                      sx={{
                        px: 2,
                        py: 1,
                        display: 'flex',
                        '&:hover > button': { visibility: 'visible' },
                        backgroundColor: 'primary.main',
                        color: 'white',
                      }}
                    >
                      {params.group}
                      <Button
                        variant="text"
                        size="small"
                        onClick={(e) => {
                          e.stopPropagation()
                          setSelectedProperties(
                            availableProperties.filter(
                              (p) => p.expandedGroupName === params.group,
                            ),
                          )
                          setSelectedGrouping('property')
                        }}
                        sx={{
                          marginLeft: 'auto',
                          visibility: 'hidden',
                          color: 'white',
                        }}
                      >
                        only
                      </Button>
                    </Typography>
                    <ul style={{ padding: 0 }}>{params.children}</ul>
                  </li>
                )}
                onlyButton={availableProperties?.length > 1}
                onlyButtonCallback={() => {
                  setSelectedGrouping('property')
                }}
              />
            </Stack>
            <Stack direction="column" spacing={1}>
              <Typography variant="body1">Aggregation</Typography>
              <ToggleButtonGroup
                data-testid="aggregation_button_group"
                color="primary"
                value={selectedInterval}
                exclusive
                onChange={(_, selectedValue) => {
                  if (selectedValue !== null) {
                    setSelectedInterval(selectedValue)
                  }
                }}
                disabled={smokeMetricsIsLoading}
              >
                {Object.keys(availableIntervals).map((key) => {
                  const interval = availableIntervals[key]
                  return (
                    <ToggleButton
                      key={key}
                      value={interval}
                      disabled={selectedInterval === interval}
                      sx={{ py: 0.8 }}
                    >
                      <Tooltip title={interval.tooltip}>
                        <div>{interval.label}</div>
                      </Tooltip>
                    </ToggleButton>
                  )
                })}
              </ToggleButtonGroup>
            </Stack>
            <Stack direction="column" spacing={1}>
              <Typography variant="body1">Display</Typography>
              <ToggleButtonGroup
                data-testid="display_button_group"
                color="primary"
                value={selectedView}
                exclusive
                onChange={(_, selectedValue) => {
                  if (selectedValue !== null) {
                    setSelectedView(selectedValue)
                  }
                }}
                disabled={smokeMetricsIsLoading}
              >
                <ToggleButton
                  value="graph"
                  aria-label="graph"
                  disabled={selectedView === 'graph'}
                  sx={{ py: 0.8 }}
                >
                  <Tooltip title="Graph view">
                    <TimelineIcon />
                  </Tooltip>
                </ToggleButton>
                <ToggleButton
                  value="table"
                  aria-label="table"
                  disabled={selectedView === 'table'}
                  sx={{ py: 0.8 }}
                >
                  <Tooltip title="Table view">
                    <TableChartIcon />
                  </Tooltip>
                </ToggleButton>
              </ToggleButtonGroup>
            </Stack>
            {enableGrouping && (
              <Stack direction="column" spacing={1}>
                <Typography variant="body1">Grouping</Typography>
                <ToggleButtonGroup
                  data-testid="grouping_button_group"
                  color="primary"
                  value={selectedGrouping}
                  exclusive
                  onChange={(_, selectedValue) => {
                    if (selectedValue !== null) {
                      setSelectedGrouping(selectedValue)
                    }
                  }}
                  disabled={smokeMetricsIsLoading}
                >
                  <ToggleButton
                    value="property"
                    aria-label="property"
                    disabled={selectedGrouping === 'property'}
                    sx={{ py: 0.8 }}
                  >
                    <Tooltip title="By Property">
                      <HouseIcon />
                    </Tooltip>
                  </ToggleButton>
                  <ToggleButton
                    value="group"
                    aria-label="group"
                    disabled={selectedGrouping === 'group'}
                    sx={{ py: 0.8 }}
                  >
                    <Tooltip title="By Property Group">
                      <HolidayVillageIcon />
                    </Tooltip>
                  </ToggleButton>
                </ToggleButtonGroup>
              </Stack>
            )}
            {selectedView === 'graph' && (
              <FormControlLabel
                slotProps={{ typography: { fontSize: 14 } }}
                sx={{ pl: 1 }}
                control={
                  <Checkbox
                    checked={showTotal}
                    size="small"
                    onChange={() => setShowTotal((v) => !v)}
                  />
                }
                label="Show total"
              />
            )}
          </Box>
          <Box display="flex" alignItems="flex-start">
            <Stack direction="column" spacing={1}>
              <Typography variant="body1">Date Range</Typography>
              <Stack direction="row">
                {!aggregatedDateRanges && (
                  <ToggleButtonGroup
                    data-testid="daterange_button_group"
                    color="primary"
                    variant="outlined"
                    disabled={smokeMetricsIsLoading}
                  >
                    {visibleDatePresetKeys.map((key) => {
                      const customTooltipFormat = 'yyyy-MM-dd'
                      const { value } = datePresets[key]
                      const tooltip = (() => {
                        const showCustomDateTooltip =
                          value === datePresets.custom.value &&
                          datePreset === datePresets.custom.value
                        if (showCustomDateTooltip) {
                          const start = dateRangeValue[0]?.toFormat(customTooltipFormat)
                          const end = dateRangeValue[1]?.toFormat(customTooltipFormat)
                          return `${start} - ${end}`
                        }
                        return datePresets[key]?.tooltip
                      })()
                      return (
                        <Tooltip key={value} title={tooltip}>
                          <ToggleButton
                            value={value}
                            selected={datePreset === value}
                            onClick={(_, selectedValue) =>
                              handlePresetChange(selectedValue)
                            }
                            sx={{ py: 0.8 }}
                          >
                            {value.toUpperCase()}
                          </ToggleButton>
                        </Tooltip>
                      )
                    })}
                  </ToggleButtonGroup>
                )}
                {aggregatedDateRanges && (
                  <ToggleButtonGroup
                    data-testid="daterange_button_group"
                    color="primary"
                    variant="outlined"
                    disabled={smokeMetricsIsLoading}
                  >
                    {Object.keys(aggregatedDateRanges).map((key) => {
                      const { value, label } = aggregatedDateRanges[key]
                      const isCustomValue = !Object.values(aggregatedDateRanges)
                        .map((i) => i.value)
                        .includes(selectedAggregationRange)
                      const tooltip = (() => {
                        if (isCustomValue && value === 'custom') {
                          const entity = selectedInterval.value === 7 ? 'week' : 'month'
                          return `${selectedAggregationRange} ${titleize(
                            pluralize(entity),
                          )}`
                        }
                        return aggregatedDateRanges[key]?.tooltip
                      })()
                      return (
                        <Tooltip key={value} title={tooltip}>
                          <ToggleButton
                            value={value}
                            selected={
                              selectedAggregationRange === value ||
                              (isCustomValue && value === 'custom')
                            }
                            onClick={(_, selectedValue) =>
                              handleAggregatedDateRangeChange(selectedValue)
                            }
                            sx={{ py: 0.8 }}
                          >
                            {(label ?? value.toString()).toUpperCase()}
                          </ToggleButton>
                        </Tooltip>
                      )
                    })}
                  </ToggleButtonGroup>
                )}
                {requestParams.current?.start && (
                  <ExportMetrics
                    requestParams={requestParams.current}
                    intervalType={selectedInterval.type}
                    interval={selectedInterval.value}
                    sx={{ ml: 1 }}
                  />
                )}
              </Stack>
            </Stack>
          </Box>
        </Box>
        <Box
          data-testid="data_container"
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="calc(100vh - 130px)"
        >
          {selectedProperties.length === 0 ? (
            <Typography variant="h4" color="grey">
              No Selected Entities
            </Typography>
          ) : (
            <>
              {smokeMetricsIsLoading && <Loading />}
              {smokeMetrics && isEmptyResult && (
                <Typography variant="h4" color="grey">
                  No Data For Selected Filters
                </Typography>
              )}
              {smokeMetrics && !isEmptyResult && !smokeMetricsIsLoading && (
                <Grid2 container spacing={2} columns={12} mb={4}>
                  {Object.keys(smokeMetrics)
                    .sort((a, b) => {
                      const aPosition = getPositionByApiValue(a)
                      const bPosition = getPositionByApiValue(b)
                      return aPosition > bPosition ? 1 : -1
                    })
                    .map((key) => {
                      const data = smokeMetrics[key]

                      const size =
                        selectedView === 'graph'
                          ? { xll: 4, sm: 6 }
                          : { xll: 6, sm: 12 }

                      return (
                        <Grid2 key={key} size={size}>
                          <MetricCard
                            metric={key}
                            selectedView={selectedView}
                            metricData={data}
                            dataInterval={selectedInterval}
                            requestedDateRange={dateRangeValue}
                            showTotal={showTotal}
                          />
                        </Grid2>
                      )
                    })}
                </Grid2>
              )}
            </>
          )}
        </Box>
      </Box>
    </>
  )
}
