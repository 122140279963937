import { useConnect } from 'redux-bundler-hook'

import { Card, Typography } from '@mui/material'

import { titleize } from 'inflection'

import MetricChart from './MetricChart'
import MetricTable from './MetricTable'
import getMetricTypesMeta from './metricTypesMeta'

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {string} props.metric
 * @param {Object} props.metricData
 * @param {Object[]} props.metricData.data
 * @param {string} props.metricData.data[].metric
 * @param {Object[]} props.metricData.data[].data
 * @param {Object} props.metricData.summary
 * @param {Object} props.dataInterval
 * @param {number} props.dataInterval.value
 * @param {string} props.dataInterval.type
 * @param {string} props.selectedView
 * @param {Object[]} props.requestedDateRange
 * @param {boolean} props.showTotal
 * @param {Object} [props.sx]
 */
export default function MetricCard({
  metric,
  metricData,
  dataInterval,
  requestedDateRange,
  selectedView,
  showTotal,
  sx = {},
}) {
  const { reportMetrics } = useConnect('selectReportMetrics')

  const systemMetric = reportMetrics.find((obj) => obj.key === metric)
  const cardTitle = systemMetric?.name ?? titleize(metric)
  const dataTypes = getMetricTypesMeta(metricData.data)

  return (
    <Card key={metric} sx={sx}>
      <Typography variant="body2" ml={2} mt={2}>
        {cardTitle}
      </Typography>
      {selectedView === 'graph' ? (
        <MetricChart
          id={metric}
          data={metricData.data}
          dataTypes={dataTypes}
          dataInterval={dataInterval}
          requestedDateRange={requestedDateRange}
          showTotal={showTotal}
        />
      ) : (
        <MetricTable id={metric} data={metricData} systemMetric={systemMetric} />
      )}
    </Card>
  )
}
