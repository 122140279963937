import { pick } from 'ramda'
import { useConnect } from 'redux-bundler-hook'

import { Box, Button, Dialog, DialogContent, Stack, Typography } from '@mui/material'

import { pluralize, titleize } from 'inflection'

import { Picker } from '@common/components'
import {
  DynamicSelect,
  StaticMultiSelect,
  StaticSelect,
} from '@common/components/Selects'

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {boolean} props.open
 * @param {Function} props.onClose
 * @param {Object} props.defaultEntities
 * @param {Object[]} props.eventTypesOptions
 * @param {Object[]} props.eventClassesOptions
 * @param {Object} props.hierarchyFilters
 * @param {(Function|Object[])[]} props.selectedEventClassesState
 * @param {Function} props.handleDateChange
 * @param {Function} props.handleFilterChange
 * @param {Function} props.handleRestFilterChange
 * @param {Function} props.handleClear
 */
export default function EventsMobileFilter({
  open,
  onClose,
  defaultEntities,
  eventTypesOptions,
  eventClassesOptions,
  hierarchyFilters,
  selectedEventClassesState,
  handleDateChange,
  handleFilterChange,
  handleRestFilterChange,
  handleClear,
}) {
  const { eventsListApiParams, doMarkEventsListAsOutdated } = useConnect(
    'selectEventsListApiParams',
    'doMarkEventsListAsOutdated',
  )

  const [selectedEventClasses, setSelectedEventClasses] = selectedEventClassesState

  const getValue = (filter) => eventsListApiParams[filter] ?? null

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent>
        <Typography sx={{ '&&': { mb: 2 } }}>Dashboard Filter</Typography>
        <Stack direction="column" spacing={2} alignItems="center">
          <Picker
            range
            type="date"
            label="Created at"
            disableMaskedInput
            conditionSeparator=""
            filterName="localCreatedOnDate"
            lowerCondition="After"
            upperCondition="Before"
            onChange={handleDateChange}
            value={pick(
              ['localCreatedOnDateBefore', 'localCreatedOnDateAfter'],
              eventsListApiParams,
            )}
          />
          {Object.keys(defaultEntities).map((type) => (
            <DynamicSelect
              size="small"
              key={type}
              filters={hierarchyFilters}
              getFullEntity
              filterName={type}
              value={getValue(type)}
              endpoint={pluralize(type)}
              label={titleize(pluralize(type))}
              onChange={(ent) => handleFilterChange(ent, type)}
              sx={{ minWidth: 250 }}
            />
          ))}
          <StaticSelect
            fullWidth
            getFullEntity
            label="Event Type"
            size="small"
            value={eventTypesOptions.find((opt) => opt.id === getValue('eventType'))}
            onChange={(opt) => handleRestFilterChange(opt?.id, 'eventType')}
            options={eventTypesOptions}
          />
          <StaticMultiSelect
            fullWidth
            dynamicWidth
            disableClearable
            label="Event Class"
            size="small"
            limitTags={2}
            chipMaxWidth={130}
            value={selectedEventClasses}
            onChange={(opt) => setSelectedEventClasses(opt)}
            onSave={(opt) =>
              handleRestFilterChange(
                opt.map((i) => i.id),
                'eventClass',
              )
            }
            options={eventClassesOptions}
          />
        </Stack>

        <Box display="flex" justifyContent="flex-end" mt={2}>
          <Button
            color="primary"
            onClick={() => {
              doMarkEventsListAsOutdated()
              onClose()
            }}
          >
            Refresh
          </Button>
          <Button
            color="primary"
            onClick={() => {
              handleClear()
              onClose()
            }}
          >
            Clear
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  )
}
