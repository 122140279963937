import { useEffect } from 'react'

import { useConnect } from 'redux-bundler-hook'

import { Box } from '@mui/material'

import { LoadingOverlay } from '@common/components'

import PhotoGrid from './PhotoGrid'

export default function DevicePhotos() {
  const { installationList, installationListIsLoading, doFetchInstallationList } =
    useConnect(
      'selectInstallationList',
      'selectInstallationListIsLoading',
      'doFetchInstallationList',
    )

  useEffect(() => {
    doFetchInstallationList()
  }, [])

  return (
    <Box sx={{ my: 2 }}>
      <LoadingOverlay loading={installationListIsLoading}>
        <PhotoGrid photos={installationList?.results} />
      </LoadingOverlay>
    </Box>
  )
}
