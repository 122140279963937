import {
  CATEGORY_CO2_TVOC,
  CATEGORY_ENVIRONMENT,
  CATEGORY_MODEL_SCORES,
  CATEGORY_PM,
  CATEGORY_PM_RATIOS,
  CATEGORY_SOUND_LEVELS,
  CATEGORY_VOC_NOX,
} from '@common/utils/grafanaViewDataProcessor'

// grid modes
export const GRID_IDLE_MODE = 'idle'
export const GRID_RESIZING_MODE = 'resizing'
export const GRID_DRAGGING_MODE = 'dragging'

// chart areas
export const ALARM_OPENED = 'alarm_opened'
export const ALARM_REJECTED = 'alarm_rejected'
export const RESERVATIONS = 'reservations'
export const SCENARIOS = 'scenarios'
export const NOISE = 'noise'

// available chart categories
export const chartCategories = [
  CATEGORY_PM,
  CATEGORY_SOUND_LEVELS,
  CATEGORY_MODEL_SCORES,
  CATEGORY_ENVIRONMENT,
  CATEGORY_PM_RATIOS,
  CATEGORY_CO2_TVOC,
  CATEGORY_VOC_NOX,
]
