import { createSelector } from 'redux-bundler'

import reduceReducers from 'reduce-reducers'

import { createListBundle, getEntityFromUrl, isAbortError } from '@common/utils'
import propertyUrls from '@portal/pages/Properties/urls'

import issuesUrls from './urls'

const entityName = 'issue'

const ISSUE_LIST_FETCH_ALL = 'ISSUE_LIST_FETCH_ALL'

const issueListBundle = createListBundle({
  entityName,
  fetchHandler: ({ apiFetch, store, params }) => {
    const { entityType, entityId } = getEntityFromUrl(store)
    return apiFetch(
      '/issues/',
      {
        ...params,
        ...(entityType && entityId && { [entityType]: entityId }),
      },
      { cancelationPrefix: entityName },
    )
  },
  urlTest: (url, pattern, hash) =>
    url === issuesUrls.list || (propertyUrls.entity === pattern && hash === 'property'),
})

export default {
  ...issueListBundle,
  reducer: reduceReducers(issueListBundle.reducer, (state, action) => {
    switch (action.type) {
      case ISSUE_LIST_FETCH_ALL:
        return { ...state, allIssues: action.payload }
      default:
        return state
    }
  }),
  doIssueListFetchAll:
    () =>
    async ({ apiFetch, dispatch }) => {
      try {
        const response = await apiFetch(
          `/issues/`,
          { isUnresolved: true, pageSize: 500 },
          { method: 'GET', cancelationPrefix: entityName },
        )
        return dispatch({ type: ISSUE_LIST_FETCH_ALL, payload: response?.results })
      } catch (err) {
        if (!isAbortError(err)) return err
        return null
      }
    },
  selectAllIssues: createSelector(
    'selectIssueListRaw',
    (propListRaw) => propListRaw?.allIssues || [],
  ),
}
