import { useMemo } from 'react'

import { omit } from 'ramda'

import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import { TimePicker } from '@mui/x-date-pickers/TimePicker'

import { DateTime } from 'luxon'

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {boolean} props.range
 * @param {boolean} props.clearable
 * @param {string} props.filterName
 * @param {Object} props.value
 * @param {'date'|'time'|'dateTime'} props.type
 * @param {Function} props.onChange
 * @param {string} props.label
 * @param {'gte'|'gt'|'After'} props.lowerCondition
 * @param {'lte'|'lt'|'Before'} props.upperCondition
 * @param {string} props.conditionSeparator
 */
export default function Picker({
  type,
  range = false,
  clearable = false,
  value = {},
  onChange = () => {},
  label,
  filterName = '',
  upperCondition = 'lte',
  lowerCondition = 'gte',
  conditionSeparator = '__',
  ...rest
}) {
  const processedValue = useMemo(
    () =>
      Object.keys(value).reduce((acc, key) => {
        const paramValue =
          typeof value[key] === 'string' ? DateTime.fromISO(value[key]) : value[key]
        return { ...acc, [`${key}`]: paramValue }
      }, {}),
    [value],
  )

  const handleChange = (key, rawTime) => {
    if (!DateTime.isDateTime(rawTime)) {
      onChange(omit([key], processedValue))
      return
    }
    const formatted = rawTime ? DateTime.fromISO(rawTime) : rawTime
    onChange({ ...processedValue, [key]: formatted })
  }

  const componentTypes = {
    dateTime: DateTimePicker,
    date: DatePicker,
    time: TimePicker,
  }

  const Component = componentTypes[type]

  const fromPickerKey = range
    ? `${filterName}${conditionSeparator}${lowerCondition}`
    : filterName

  const getFromPickerValue = () => processedValue?.[fromPickerKey] || null
  const getToPickerValue = () =>
    processedValue?.[`${filterName}${conditionSeparator}${upperCondition}`] || null

  const getMax = (compType) =>
    type === compType && getToPickerValue()
      ? DateTime.fromISO(getToPickerValue())
      : null
  const getMin = (compType) =>
    type === compType && getFromPickerValue()
      ? DateTime.fromISO(getFromPickerValue())
      : null

  const globalMinDT = DateTime.fromISO('2015-01-01T00:00:00-08:00')
  const globalMaxDT = DateTime.now().plus({ years: 1 })

  const getMaxProps = () => {
    const time = { maxTime: getMax('time') }
    const date = { maxDate: getMax('date') }
    const dateTime = {
      ...time,
      ...date,
      minDateTime: globalMinDT,
      maxDateTime: getMax('dateTime') || globalMaxDT,
    }
    return { time, date, dateTime }[type]
  }
  const getMinProps = () => {
    const time = { minTime: getMin('time') }
    const date = { minDate: getMin('date') }
    const dateTime = {
      ...time,
      ...date,
      minDateTime: getMin('dateTime') || globalMinDT,
      maxDateTime: globalMaxDT,
    }
    return { time, date, dateTime }[type]
  }

  return (
    <>
      <Component
        {...getMaxProps()}
        value={getFromPickerValue()}
        onChange={(t) => handleChange(fromPickerKey, t)}
        slotProps={{
          textField: {
            size: 'small',
            label: range ? `${label} range start` : label,
          },
          field: { clearable },
        }}
        {...rest}
      />
      {range && (
        <Component
          {...getMinProps()}
          value={getToPickerValue()}
          onChange={(rawTime) =>
            handleChange(`${filterName}${conditionSeparator}${upperCondition}`, rawTime)
          }
          slotProps={{
            textField: {
              size: 'small',
              label: `${label} range end`,
            },
            field: { clearable },
          }}
          {...rest}
        />
      )}
    </>
  )
}
