import { useCallback, useEffect, useRef, useState } from 'react'

import { useConnect } from 'redux-bundler-hook'

import {
  ApartmentOutlined as ApartmentIcon,
  CancelOutlined,
  CheckCircleOutline,
  Circle,
  NotificationsPaused as NotificationsPausedIcon,
} from '@mui/icons-material'
import { Box, Chip, Link, Typography } from '@mui/material'

import { ThemeProvider } from '@emotion/react'
import { titleize } from 'inflection'

import {
  Breadcrumbs,
  ClickableCell,
  ErrorComponent,
  MobileList,
  MobileListDefaultCard,
} from '@common/components'
import { DynamicSelect, StaticSelect } from '@common/components/Selects'
import { useQueryFilter, useSmallScreen } from '@common/utils'
import accountUrls from '@portal/pages/Accounts/urls'
import EventViewModal from '@portal/pages/EventDashboard/EventModal/EventViewModal'
import { homeUrls } from '@portal/pages/Home'
import orgUrls from '@portal/pages/Organizations/urls'
import propertyUrls from '@portal/pages/Properties/urls'
import thresholdManagementUrls from '@portal/pages/ThresholdManagement/urls'
import { BooleanCell } from '@portal/UI/components/cells'
import ConfirmationDialog from '@portal/UI/components/ConfirmationDialog'
import CSVPicker from '@portal/UI/components/CSVPicker'
import DeleteModal from '@portal/UI/components/DeleteModal'
import Filter from '@portal/UI/components/Filter'
import List from '@portal/UI/components/List'
import ListExportButton from '@portal/UI/components/ListExportButton'
import ListPageTitle from '@portal/UI/components/ListPageTitle'
import { darkTheme } from '@portal/UI/Theme'
import boolOptions from '@portal/Utils/constants'

import UnitForm from './UnitForm'
import unitUrls from './urls'

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {boolean} [props.renderAsTab]
 * @param {Object} [props.property]
 * @param {string} props.property.id
 * @param {string} props.property.account
 */
export default function UnitList({ renderAsTab = false, property = undefined }) {
  const [pageSize, setPageSize] = useState('')
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false)
  const [rowIdForDelete, setRowIdForDelete] = useState('')
  const [editFormOpen, setEditFormOpen] = useState(false)
  const [currentUnit, setCurrentUnit] = useState({})
  const [selectedIds, setSelectedIds] = useState([])
  const [snoozeStatus, setSnoozeStatus] = useState(undefined)
  const [error, setError] = useState('')
  const [filterModalOpen, setFilterModalOpen] = useState(false)
  const [grafanaViewUnit, setGrafanaViewUnit] = useState(null)

  const {
    doUnitImportCsv,
    doUnitListClearParams,
    doUnitListSetFilter,
    doUnitListSetPage,
    doUnitListSetPageSize,
    doUnitListSetOrdering,
    doUnitListBulkUpdateSnooze,
    doMarkUnitListAsOutdated,
    doMarkPropertyListAsOutdated,
    doShowSnackbar,
    doUnitDelete,
    doUpdateUrl,
    doUpdateQuery,
    unitList,
    unitListRaw: { ordering = [] },
    unitListIsLoading,
    systemPropertyTypes,
    unitListApiParams,
    queryObject,
    propertyDetails,
    systemUnitStatus,
  } = useConnect(
    'doUnitImportCsv',
    'doUnitListClearParams',
    'doUnitListSetFilter',
    'doUnitListSetPage',
    'doUnitListSetPageSize',
    'doUnitListSetOrdering',
    'doUnitListBulkUpdateSnooze',
    'doMarkUnitListAsOutdated',
    'doMarkPropertyListAsOutdated',
    'doShowSnackbar',
    'doUnitDelete',
    'doUpdateUrl',
    'doUpdateQuery',
    'selectUnitList',
    'selectUnitListRaw',
    'selectUnitListIsLoading',
    'selectSystemPropertyTypes',
    'selectUnitListApiParams',
    'selectQueryObject',
    'selectPropertyDetails',
    'selectSystemUnitStatus',
  )

  const queryHolder = useRef(null)

  useEffect(() => {
    if (queryObject?.grafanaUnit) {
      setGrafanaViewUnit(queryObject.grafanaUnit)
    }
  }, [queryObject])

  const isSmallScreen = useSmallScreen()

  const handleClear = () => {
    doUnitListClearParams()
    doUnitListSetFilter()
  }

  useQueryFilter({
    query: queryObject,
    apiParams: unitListApiParams,
    setFilter: doUnitListSetFilter,
    setPageSize: doUnitListSetPageSize,
    ignoreIf: (query) => query.grafanaUnit,
  })

  useEffect(() => {
    setCurrentUnit({})
    setRowIdForDelete(null)
    doMarkUnitListAsOutdated()
  }, [])

  const handlePageChange = async (page) => {
    doUnitListSetPage(page)
  }

  const handlePageSizeChange = (size) => {
    setPageSize((prevPageSize) => (prevPageSize === size ? pageSize : size))
    doUnitListSetPageSize(size)
  }

  const handleRowClick = (unit) => {
    const unitDetail = unitUrls.entity.replace(':id', unit.id)
    doUpdateUrl(unitDetail)
  }

  const handleUnitUpdate = (row) => {
    setCurrentUnit(row)
    setEditFormOpen(true)
  }

  const onFormClose = () => {
    setCurrentUnit({})
    doMarkUnitListAsOutdated()
    setEditFormOpen(false)
  }

  const openConfirmDeleteModal = (unitId) => {
    setRowIdForDelete(unitId)
    setDeleteConfirmOpen(true)
  }

  const handleDelete = async () => {
    try {
      await doUnitDelete(rowIdForDelete)
      doShowSnackbar('Successfully deleted Unit')
      await doMarkUnitListAsOutdated()
      setError('')
      setRowIdForDelete('')
      setDeleteConfirmOpen(false)
    } catch (e) {
      setError(e.message || JSON.stringify(e))
    }
  }

  const updateSnoozeStatus = (ids, status) => {
    setSelectedIds(ids)
    setSnoozeStatus(status)
  }

  const handleOnSnoozeConfirm = async () => {
    const snooze = snoozeStatus === 'snooze'
    await doUnitListBulkUpdateSnooze({ ids: selectedIds, snooze })
    setSelectedIds([])
    setSnoozeStatus(undefined)
    doMarkUnitListAsOutdated()
  }
  const handleOnSnoozeCancel = () => {
    setSelectedIds([])
    setSnoozeStatus(undefined)
  }

  const getStatusColor = (status) => {
    switch (status) {
      case 'ONLINE':
        return 'success'
      case 'FAILURE':
        return 'error'
      default:
        return 'warning'
    }
  }

  const unitStatusOptions = systemUnitStatus.map((status) => ({
    label: titleize(status),
    id: status,
  }))

  const exportListConfig = {
    apiParams: unitListApiParams,
    entity: 'units',
  }

  if (!unitList?.results) {
    return <ErrorComponent title="Units" callback={handleClear} />
  }

  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      renderCell: ({ row }) =>
        row.snooze ? (
          <Box display="flex">
            <ClickableCell
              label={row.name}
              url={unitUrls.entity.replace(':id', row.id)}
            />
            <NotificationsPausedIcon color="primary" sx={{ ml: 1 }} />
          </Box>
        ) : (
          <ClickableCell
            label={row.name}
            url={unitUrls.entity.replace(':id', row.id)}
          />
        ),
      flex: 1,
    },
    {
      field: 'active',
      headerName: 'Active',
      flex: 0.4,
      type: 'boolean',
      renderCell: BooleanCell,
      headerAlign: 'center',
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: property ? 0.7 : 1.7,
      sortable: false,
      renderCell: ({ row }) => {
        const { status } = row
        return (
          <Chip
            icon={<Circle />}
            variant="outlined"
            size="small"
            label={titleize(status || '')}
            color={getStatusColor(status)}
          />
        )
      },
      renderMobile: ({ row }) => (
        <Typography variant="caption" color={`${getStatusColor(row.status)}.main`}>
          {titleize(row.status || '')}
        </Typography>
      ),
    },
    ...(property
      ? [
          {
            field: 'groupName',
            headerName: 'Unit Group',
            sortField: 'group__name',
            flex: 0.4,
          },
          {
            field: 'effectiveNoiseThresholdProfileName',
            headerName: 'Threshold Profile',
            sortable: false,
            renderCell: ({ row }) => (
              <ClickableCell
                label={row.effectiveNoiseThresholdProfileName}
                url={thresholdManagementUrls.entity.replace(
                  ':id',
                  row.effectiveNoiseThresholdProfile,
                )}
              />
            ),
            flex: 0.4,
          },
          {
            field: 'unitSize',
            headerName: 'Size',
            flex: 0.15,
            type: 'number',
            sortable: false,
          },
          {
            field: 'floor',
            headerName: 'Floor',
            flex: 0.15,
            type: 'number',
            sortable: false,
          },
        ]
      : [
          {
            field: 'organizationName',
            headerName: 'Organization',
            sortField: 'property__account__organization__name',
            renderCell: ({ row }) => (
              <ClickableCell
                label={row.organizationName}
                url={orgUrls.entity.replace(':id', row.organization)}
              />
            ),
            flex: 1,
          },
          {
            field: 'accountName',
            headerName: 'Account',
            sortField: 'property__account__name',
            renderCell: ({ row }) => (
              <ClickableCell
                label={row.accountName}
                url={accountUrls.entity.replace(':id', row.account)}
              />
            ),
            flex: 1,
          },
          {
            field: 'propertyName',
            headerName: 'Property',
            sortField: 'property__name',
            renderCell: ({ row }) => (
              <ClickableCell
                label={row.propertyName}
                url={propertyUrls.entity.replace(':id', row.property)}
              />
            ),
            flex: 1,
          },
          {
            field: 'propertyType',
            headerName: 'Property Type',
            sortField: 'property__propertyType',
            flex: 1,
          },
          {
            field: 'groupName',
            headerName: 'Group',
            sortField: 'group__name',
            flex: 1,
          },
          {
            field: 'effectiveNoiseThresholdProfileName',
            headerName: 'Threshold',
            sortable: false,
            renderCell: ({ row }) => (
              <ClickableCell
                label={row.effectiveNoiseThresholdProfileName}
                url={thresholdManagementUrls.entity.replace(
                  ':id',
                  row.effectiveNoiseThresholdProfile,
                )}
              />
            ),
            flex: 1,
          },
          {
            field: 'deviceCount',
            headerName: 'Devices',
            flex: 0.5,
            type: 'number',
          },
        ]),
    {
      field: 'grafana',
      headerName: 'Grafana',
      headerAlign: 'right',
      align: 'right',
      flex: 0.5,
      renderCell: ({ row }) =>
        row.deviceCount > 0 ? (
          <Typography
            onClick={() => {
              queryHolder.current = queryObject
              doUpdateQuery({ grafanaUnit: row.id }, { maintainScrollPosition: true })
            }}
            sx={{
              fontSize: isSmallScreen ? 12 : 14,
              textDecoration: 'underline',
              cursor: 'pointer',
              color: '#4840ba',
            }}
          >
            View
          </Typography>
        ) : (
          '--'
        ),
    },
  ]

  const rows = unitList?.results?.map((unit) => ({
    id: unit.id,
    name: unit.name,
    organization: unit.organization,
    organizationName: unit.organizationName,
    account: unit.account,
    accountName: unit.accountName,
    property: unit.property,
    propertyName: unit.propertyName,
    propertyType: unit.propertyType,
    group: unit.group,
    groupName: unit.groupName,
    deviceCount: unit.deviceCount || 0,
    active: !unit.deletedOn,
    status: unit.status,
    floor: unit.floor,
    unitSize: unit.unitSize,
    snooze: unit.snooze,
    effectiveNoiseThresholdProfile: unit.effectiveNoiseThresholdProfile,
    effectiveNoiseThresholdProfileName: unit.effectiveNoiseThresholdProfileName,
  }))

  const listActions = {
    bulk: [
      {
        action: (ids) => updateSnoozeStatus(ids, 'snooze'),
        title: 'Snooze Units',
      },
      {
        action: (ids) => updateSnoozeStatus(ids, 'un-snooze'),
        title: 'Un-snooze Units',
      },
    ],
    create: () => setEditFormOpen(true),
    update: handleUnitUpdate,
    delete: openConfirmDeleteModal,
  }

  const handleCreateUnit = () => {
    setCurrentUnit({ property: property?.id })
    setEditFormOpen(true)
  }

  const AddUnitButton = useCallback(
    (rest) => (
      <CSVPicker
        entity="Units"
        title="Add units"
        uploadTitle="upload csv"
        startIcon={<ApartmentIcon />}
        overwrite={{ property: propertyDetails?.property.id }}
        onImport={(data) =>
          doUnitImportCsv({ data, account: propertyDetails?.property.account })
        }
        customActions={[{ title: 'Through form', call: handleCreateUnit }]}
        onClose={() => doMarkPropertyListAsOutdated()}
        {...rest}
      />
    ),
    [propertyDetails?.property],
  )

  const MobileItemHeader = useCallback(({ row }) => {
    const StatusIcon = row.active ? CheckCircleOutline : CancelOutlined
    return (
      <Box display="flex" alignItems="center" sx={{ '&&': { mb: 1 } }}>
        <Link href={unitUrls.entity.replace(':id', row.id)}>
          <Typography variant="caption" fontWeight="bold">
            {row.name}
          </Typography>
        </Link>
        <StatusIcon
          color={row.active ? 'success' : 'warning'}
          sx={{ fontSize: 16, ml: 0.5 }}
        />
      </Box>
    )
  }, [])

  const MobileListItem = useCallback(
    (row) =>
      MobileListDefaultCard({
        row,
        columns,
        ignoredFields: ['name', 'active'],
        header: <MobileItemHeader row={row} />,
      }),
    [columns],
  )

  return (
    <>
      <UnitForm open={editFormOpen} onClose={onFormClose} instance={currentUnit} />
      <ConfirmationDialog
        title={`${snoozeStatus && titleize(snoozeStatus)} Units`}
        message={`Are you sure you want to ${snoozeStatus} the selected units?`}
        open={snoozeStatus !== undefined}
        onConfirm={handleOnSnoozeConfirm}
        onCancel={handleOnSnoozeCancel}
      />
      <ThemeProvider theme={darkTheme}>
        <EventViewModal
          unit={grafanaViewUnit}
          entity="device"
          onClose={() => {
            doUpdateQuery(queryHolder.current, { maintainScrollPosition: true })
            setGrafanaViewUnit(null)
            queryHolder.current = null
          }}
        />
      </ThemeProvider>
      <Box
        m={renderAsTab ? 0 : 3}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '900px',
        }}
      >
        {!renderAsTab && (
          <>
            <Breadcrumbs
              links={[{ label: 'Home', href: homeUrls.home }, { label: 'Units' }]}
            />
            <ListPageTitle
              title="Units"
              onFilterPressed={isSmallScreen ? () => setFilterModalOpen(true) : null}
              menuItems={
                isSmallScreen
                  ? [
                      { label: 'Add Unit', onClick: () => setEditFormOpen(true) },
                      ...(selectedIds?.length
                        ? [
                            {
                              label: 'Snooze Units',
                              onClick: () => updateSnoozeStatus(selectedIds, 'snooze'),
                            },
                            {
                              label: 'Un-snooze Units',
                              onClick: () =>
                                updateSnoozeStatus(selectedIds, 'un-snooze'),
                            },
                          ]
                        : []),
                      {
                        label: 'Export',
                        onClick: () => {},
                        render: ({ onClose }) => (
                          <ListExportButton
                            mode="menuItem"
                            postExport={onClose}
                            {...exportListConfig}
                          />
                        ),
                      },
                    ]
                  : null
              }
              mb={2}
            />
          </>
        )}
        <Box display="flex">
          <Filter
            mode={isSmallScreen ? 'modal' : 'drawer'}
            disabled={unitListIsLoading}
            apiParams={unitListApiParams}
            setApiParams={doUpdateQuery}
            dialogOpen={filterModalOpen}
            dialogOnClose={() => setFilterModalOpen(false)}
          >
            {!renderAsTab && (
              <>
                <DynamicSelect
                  label="Organization Groups"
                  filterName="organization_group"
                  size="small"
                />
                <DynamicSelect
                  label="Organizations"
                  filterName="organization"
                  size="small"
                />
                <DynamicSelect label="Accounts" filterName="account" size="small" />
                <DynamicSelect label="Properties" filterName="property" size="small" />
                <DynamicSelect
                  label="Property Groups"
                  filterName="property_group"
                  size="small"
                />
                <StaticSelect
                  size="small"
                  label="Property Types"
                  filterName="propertyType"
                  options={systemPropertyTypes}
                />
                <DynamicSelect
                  size="small"
                  label="Unit Groups"
                  filterName="unit_group"
                />
                <StaticSelect
                  size="small"
                  label="With Threshold Profile"
                  filterName="hasEffectiveNoiseThresholdProfile"
                  options={boolOptions}
                />
              </>
            )}
            <StaticSelect
              size="small"
              label="Snoozed"
              filterName="snooze"
              options={boolOptions}
            />
            <StaticSelect
              size="small"
              label="Status"
              filterName="status"
              options={unitStatusOptions}
            />
            <StaticSelect label="Active" filterName="active" options={boolOptions} />
          </Filter>
          <Box ml={isSmallScreen ? 0 : 2} flex={1} overflow="hidden" minHeight="900px">
            {isSmallScreen ? (
              <MobileList
                queryDrivenSearch
                title="Units"
                loading={unitListIsLoading}
                actions={listActions}
                itemBuilder={MobileListItem}
                onFilterPressed={renderAsTab ? () => setFilterModalOpen(true) : null}
                listActions={
                  renderAsTab && [
                    {
                      label: 'Add Units',
                      onClick: () => {},
                      render: ({ onClose }) => (
                        <AddUnitButton mode="menuItem" postAction={onClose} />
                      ),
                    },
                    ...(selectedIds?.length
                      ? [
                          {
                            label: 'Snooze Units',
                            onClick: () => updateSnoozeStatus(selectedIds, 'snooze'),
                          },
                          {
                            label: 'Un-snooze Units',
                            onClick: () => updateSnoozeStatus(selectedIds, 'un-snooze'),
                          },
                        ]
                      : []),
                  ]
                }
                page={unitList?.current || 1}
                pageChange={handlePageChange}
                pageSize={unitList?.pageSize}
                pageSizeChange={handlePageSizeChange}
                rows={rows}
                rowClick={handleRowClick}
                rowCount={unitList?.count || 0}
                rowSelectionModel={selectedIds}
                onRowSelectionModelChange={setSelectedIds}
              />
            ) : (
              <List
                columnsAutosize
                queryDrivenSearch
                title="Units"
                searchTriggerLength={1}
                loading={unitListIsLoading}
                exportListConfig={renderAsTab ? null : exportListConfig}
                actions={listActions}
                customAddButton={renderAsTab ? <AddUnitButton /> : null}
                columns={columns}
                page={unitList?.current || 1}
                pageChange={handlePageChange}
                pageSize={unitList?.pageSize}
                pageSizeChange={handlePageSizeChange}
                rows={rows}
                rowClick={handleRowClick}
                rowCount={unitList?.count || 0}
                rowSelectionModel={selectedIds}
                onRowSelectionModelChange={setSelectedIds}
                sortChange={doUnitListSetOrdering}
                currentOrdering={ordering}
              />
            )}
            <DeleteModal
              open={deleteConfirmOpen}
              error={error}
              onConfirmDelete={handleDelete}
              onCancelDelete={() => setDeleteConfirmOpen(false)}
            />
          </Box>
        </Box>
      </Box>
    </>
  )
}
