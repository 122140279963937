import { useState } from 'react'

import { useConnect } from 'redux-bundler-hook'

import { ImageNotSupportedRounded, MoreVertRounded } from '@mui/icons-material'
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Dialog,
  Grid2,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from '@mui/material'

import { DateTime } from 'luxon'

import ConfirmationDialog from '~/portal/UI/components/ConfirmationDialog'

const formatDate = (date, format = DateTime.DATETIME_MED_WITH_SECONDS) =>
  date ? DateTime.fromISO(date).toLocaleString(format) : 'N/A'

export default function PhotoGrid({ photos }) {
  const {
    isAtLeastAdmin,
    doInstallationListVerify,
    doInstallationListRemove,
    doFetchInstallationList,
    doShowSnackbar,
  } = useConnect(
    'selectIsAtLeastAdmin',
    'doInstallationListVerify',
    'doInstallationListRemove',
    'doFetchInstallationList',
    'doShowSnackbar',
  )

  const [actionMenuData, setActionMenuData] = useState(null)
  const [viewPhotoData, setViewPhotoData] = useState(null)
  const [deletePhotoConfirmationId, setDeletePhotoConfirmationId] = useState(null)

  if (!photos?.length) {
    return (
      <Stack
        sx={{ mt: 2 }}
        alignItems="center"
        justifyContent="center"
        textAlign="center"
      >
        <ImageNotSupportedRounded sx={{ fontSize: 60, mb: 2 }} />
        No photos found
      </Stack>
    )
  }

  const handlePhotoVerify = async (id) => {
    setActionMenuData(null)
    const result = await doInstallationListVerify(id)
    if (!result?.error) {
      doFetchInstallationList()
      doShowSnackbar('Photo was successfully verified.')
    }
  }

  const handlePhotoDelete = async (id) => {
    setDeletePhotoConfirmationId(null)
    const result = await doInstallationListRemove(id)
    if (!result?.error) {
      doFetchInstallationList()
      doShowSnackbar('Photo was successfully deleted.')
    }
  }

  return (
    <>
      <Menu
        open={!!actionMenuData}
        anchorEl={actionMenuData?.anchor}
        onClose={() => setActionMenuData(null)}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      >
        {actionMenuData?.data?.reviewed === false && (
          <MenuItem onClick={async () => handlePhotoVerify(actionMenuData.data.id)}>
            <Typography>Verify</Typography>
          </MenuItem>
        )}
        <MenuItem
          onClick={() => {
            setDeletePhotoConfirmationId(actionMenuData.data.id)
            setActionMenuData(null)
          }}
        >
          <Typography color="error">Delete</Typography>
        </MenuItem>
      </Menu>
      <ConfirmationDialog
        title="Delete Photo"
        message="Are you sure you want to delete this photo?"
        open={!!deletePhotoConfirmationId}
        onConfirm={async () => handlePhotoDelete(deletePhotoConfirmationId)}
        onCancel={() => setDeletePhotoConfirmationId(null)}
      />
      <Dialog
        fullWidth
        open={!!viewPhotoData}
        onClose={() => setViewPhotoData(null)}
        maxWidth="md"
        scroll="body"
      >
        <Card>
          <CardMedia
            component="img"
            image={viewPhotoData?.url}
            alt={viewPhotoData?.mac}
          />
        </Card>
      </Dialog>

      <Grid2 container spacing={2}>
        {photos.map((install) => (
          <Grid2 key={install.id} item size={{ xs: 12, md: 6, lg: 4, xl: 3 }}>
            <Card sx={{ background: install.photoUrl }}>
              <Stack position="relative">
                <CardActionArea
                  onClick={() => {
                    setViewPhotoData({
                      url: install.photoUrl,
                      mac: install.deviceMac,
                    })
                  }}
                >
                  <CardMedia
                    component="img"
                    height={300}
                    image={install.photoUrl}
                    alt={install.deviceMac}
                  />
                </CardActionArea>
                <CardContent
                  sx={{
                    width: 1,
                    position: 'absolute',
                    top: 0,
                    background: `linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(0,0,0,0.3) 45%)`,
                    '&:last-child': { pb: 0 },
                  }}
                >
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography variant="caption" color="white" fontWeight="bold">
                      {install.category}
                    </Typography>
                    {isAtLeastAdmin && (
                      <IconButton
                        size="small"
                        sx={{ color: 'white' }}
                        onClick={(event) =>
                          setActionMenuData({
                            anchor: event.currentTarget,
                            data: install,
                          })
                        }
                      >
                        <MoreVertRounded />
                      </IconButton>
                    )}
                  </Box>
                </CardContent>
                <CardContent
                  sx={{
                    width: 1,
                    position: 'absolute',
                    bottom: 0,
                    pointerEvents: 'none',
                    background: `linear-gradient(0deg, rgba(0,0,0,0.3) 75%, rgba(255,255,255,0) 100%)`,
                    '&:last-child': { pb: 0 },
                  }}
                >
                  <Typography variant="caption" color="white">
                    <p>
                      <strong>Photo Uploaded On</strong>
                      <br /> {formatDate(install.createdOn)}
                    </p>
                    <p>
                      <strong>Reviewed</strong>
                      <br />{' '}
                      {install.reviewed
                        ? `By ${install.reviewedByName} on ${formatDate(
                            install.reviewedOn,
                            DateTime.DATETIME_SHORT,
                          )}`
                        : 'No'}
                    </p>
                  </Typography>
                </CardContent>
              </Stack>
            </Card>
          </Grid2>
        ))}
      </Grid2>
    </>
  )
}
