import { useCallback, useEffect, useMemo, useState } from 'react'

import { pick } from 'ramda'
import { useConnect } from 'redux-bundler-hook'

import { ListAlt } from '@mui/icons-material'
import { Box, Stack, Typography } from '@mui/material'

import { titleize } from 'inflection'
import { DateTime } from 'luxon'

import {
  Breadcrumbs,
  ClickableCell,
  Loading,
  MobileList,
  MobileListDefaultCard,
  Picker,
} from '@common/components'
import { StaticSelect } from '@common/components/Selects'
import { formatCurrency, useQueryFilter, useSmallScreen } from '@common/utils'
import { formatDayWithOrdinal } from '@common/utils/formatters'
import propertyUrls from '@rest/pages/Properties/urls'
import { List } from '@rest/UI/components'
import NavigationTabs from '@rest/UI/Navigation/NavigationTabs'

import { INVOICE_STATUSES_OPTIONS } from './constants'
import InvoiceActions from './InvoiceActions'
import InvoiceDetailModal from './InvoiceDetailModal'
import InvoiceStatusChip from './InvoiceStatusChip'

function InvoicesEmptyState() {
  return (
    <Stack
      sx={{ mt: 5 }}
      alignItems="center"
      justifyContent="center"
      textAlign="center"
    >
      <ListAlt sx={{ fontSize: 60, mb: 2 }} />
      No invoices found.
    </Stack>
  )
}

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {boolean} [props.renderAsTab]
 */
export default function Invoices({ renderAsTab = false }) {
  const isSmallScreen = useSmallScreen()

  const {
    me: { properties },
    currentOrganizationDetails: organization,
    currentAccountDetails: account,
    propertyInvoicesListRaw: { filter = {}, ordering = [] },
    propertyInvoicesList,
    propertyInvoicesListIsLoading,
    propertyInvoicesListApiParams,
    queryObject,
    doPropertyInvoicesListSetFilter,
    doPropertyInvoicesListSetOrdering,
    doPropertyInvoicesListSetPage,
    doPropertyInvoicesListSetPageSize,
    doUpdateQuery,
  } = useConnect(
    'selectMe',
    'selectCurrentOrganizationDetails',
    'selectCurrentAccountDetails',
    'selectPropertyInvoicesListRaw',
    'selectPropertyInvoicesList',
    'selectPropertyInvoicesListIsLoading',
    'selectPropertyInvoicesListApiParams',
    'selectQueryObject',
    'doPropertyInvoicesListSetFilter',
    'doPropertyInvoicesListSetOrdering',
    'doPropertyInvoicesListSetPage',
    'doPropertyInvoicesListSetPageSize',
    'doPropertyInvoicesListClearParams',
    'doUpdateQuery',
  )

  const [selectedInvoice, setSelectedInvoice] = useState(null)

  useQueryFilter({
    query: queryObject,
    apiParams: propertyInvoicesListApiParams,
    setFilter: doPropertyInvoicesListSetFilter,
    setPageSize: doPropertyInvoicesListSetPageSize,
    defaultParams: { page: 1, pageSize: 25 },
  })

  useEffect(() => {
    doPropertyInvoicesListSetOrdering(['invoiceCreatedDate', 'desc'])
  }, [])

  const propertiesOptions = useMemo(
    () => properties?.map((item) => ({ id: item.id, label: item.name })),
    [properties],
  )

  const columns = [
    ...(!renderAsTab && properties?.length > 1
      ? [
          {
            field: 'property',
            headerName: 'Property',
            flex: 2,
            valueGetter: (_, row) => {
              const propertyData = properties.find((item) => item.id === row.property)
              return propertyData?.name ?? row.property
            },
            sortable: false,
            renderCell: ({ row }) => {
              const propertyData = properties.find((item) => item.id === row.property)
              return (
                <ClickableCell
                  label={propertyData?.name ?? row.property}
                  url={propertyUrls.entity.replace(':id', row.property)}
                />
              )
            },
          },
        ]
      : []),
    {
      field: 'stripeInvoiceNumber',
      headerName: 'Invoice Number',
      flex: 0.8,
      sortable: false,
      renderCell: ({ row }) => (
        <Typography
          variant={isSmallScreen ? 'caption' : 'body2'}
          onClick={() => setSelectedInvoice(row)}
          sx={{
            color: 'primary.main',
            textDecoration: 'none',
            cursor: 'pointer',
            '&:hover': {
              textDecoration: 'underline',
            },
          }}
        >
          {row.stripeInvoiceNumber}
        </Typography>
      ),
    },
    {
      field: 'startDate',
      headerName: 'Invoice Period',
      flex: 1,
      renderCell: ({ row }) => {
        const start = DateTime.fromISO(row.startDate)
        const end = DateTime.fromISO(row.endDate)
        const isValidDate = start.isValid && end.isValid

        let formattedRange = '--'
        if (isValidDate) {
          if (start.month === end.month && start.year === end.year) {
            formattedRange = `${start.toFormat('MMM. d')}-${end.toFormat('d, yyyy')}`
          } else {
            const formattedStart = start.toFormat('MMM. d yyyy')
            const formattedEnd = end.toFormat('MMM. d yyyy')
            formattedRange = `${formattedStart} - ${formattedEnd}`
          }
        }

        return (
          <Typography variant={isSmallScreen ? 'caption' : 'body2'}>
            {formattedRange}
          </Typography>
        )
      },
    },
    {
      field: 'invoiceCreatedDate',
      headerName: 'Issued On',
      flex: 1,
      renderCell: ({ row }) => {
        const parsedDate = row.invoiceCreatedDate
          ? DateTime.fromISO(row.invoiceCreatedDate)
          : null
        return (
          <Typography variant={isSmallScreen ? 'caption' : 'body2'}>
            {parsedDate
              ? `${parsedDate.toFormat('MMM.')} ${formatDayWithOrdinal(
                  parsedDate.day,
                )}, ${parsedDate.toFormat('yyyy')}`
              : '--'}
          </Typography>
        )
      },
    },
    {
      field: 'amountDue',
      headerName: 'Amount Due',
      flex: 1,
      valueFormatter: (value) => (value ? formatCurrency(value) : 'N/A'),
      sortable: false,
    },
    {
      field: 'invoicePaymentStatus',
      headerName: 'Status',
      flex: 1,
      sortable: false,
      renderCell: ({ row }) => (
        <InvoiceStatusChip status={row.invoicePaymentStatus || ''} />
      ),
    },
    {
      field: 'actions',
      headerName: 'Actions',
      headerAlign: 'center',
      flex: 1,
      minWidth: 324,
      renderCell: ({ row }) => <InvoiceActions invoice={row} mode="list" />,
      sortable: false,
    },
  ]

  const MobileItemFooter = useCallback(
    ({ row }) => <InvoiceActions invoice={row} mode="list" />,
    [isSmallScreen],
  )

  const MobileListItem = useCallback(
    (row) =>
      MobileListDefaultCard({
        row,
        columns,
        ignoredFields: ['actions'],
        footer: <MobileItemFooter row={row} />,
      }),
    [columns],
  )

  const handleDateChange = (dateObj) => {
    const dates = Object.entries(dateObj).reduce(
      (acc, [attr, date]) => ({ ...acc, [attr]: date.toFormat('yyyy-dd-MM') }),
      [],
    )
    const updatedFilter = { ...filter, ...dates }
    if (!('invoiceCreatedDateBefore' in dateObj)) {
      delete updatedFilter.invoiceCreatedDateBefore
    }
    if (!('invoiceCreatedDateAfter' in dateObj)) {
      delete updatedFilter.invoiceCreatedDateAfter
    }
    doPropertyInvoicesListSetFilter(updatedFilter)
  }

  return (
    <>
      <InvoiceDetailModal
        open={!!selectedInvoice}
        onClose={() => setSelectedInvoice(null)}
        invoice={selectedInvoice}
      />

      <Box>
        {!renderAsTab && (
          <>
            <Breadcrumbs
              links={[
                { label: organization?.name || '--' },
                { label: account.name },
                { label: 'Invoices' },
              ]}
            />
            <NavigationTabs />
          </>
        )}

        <Stack
          direction={isSmallScreen ? 'column' : 'row'}
          alignItems="center"
          justifyContent="end"
          my={1}
          spacing={1.5}
        >
          <Picker
            range
            clearable
            type="date"
            label="Issued on"
            disableMaskedInput
            conditionSeparator=""
            filterName="invoiceCreatedDate"
            lowerCondition="After"
            upperCondition="Before"
            onChange={handleDateChange}
            sx={{ width: isSmallScreen ? 1 : null }}
            value={pick(
              ['invoiceCreatedDateBefore', 'invoiceCreatedDateAfter'],
              propertyInvoicesListApiParams,
            )}
          />
          {!renderAsTab && properties?.length > 1 && (
            <StaticSelect
              fullWidth={isSmallScreen}
              label="Property"
              options={propertiesOptions}
              value={filter.property}
              disabled={propertyInvoicesListIsLoading}
              onChange={(item) => {
                const updatedQuery = { ...queryObject, property: item }
                if (!item) {
                  delete updatedQuery.property
                }
                doUpdateQuery(updatedQuery)
              }}
              size="small"
              sx={{ minWidth: 300 }}
            />
          )}
          <StaticSelect
            fullWidth={isSmallScreen}
            label="Status"
            options={INVOICE_STATUSES_OPTIONS}
            value={filter.invoicePaymentStatus}
            disabled={propertyInvoicesListIsLoading}
            optionLabelFormatter={titleize}
            onChange={(status) => {
              const updatedQuery = { ...queryObject, invoicePaymentStatus: status }
              if (!status) {
                delete updatedQuery.invoicePaymentStatus
              }
              doUpdateQuery(updatedQuery)
            }}
            size="small"
            sx={{ minWidth: 250 }}
          />
        </Stack>

        <Box display="flex" mb={2}>
          <Box display="flex" width={1} justifyContent="center">
            <>
              {propertyInvoicesListIsLoading && !propertyInvoicesList && <Loading />}
              {propertyInvoicesList && (
                <Box width={1}>
                  {isSmallScreen ? (
                    <MobileList
                      loading={propertyInvoicesListIsLoading}
                      rows={propertyInvoicesList.results}
                      itemBuilder={MobileListItem}
                      page={propertyInvoicesList.current}
                      pageSize={propertyInvoicesList.pageSize}
                      pageChange={doPropertyInvoicesListSetPage}
                      pageSizeChange={doPropertyInvoicesListSetPageSize}
                      rowCount={propertyInvoicesList.count}
                      sortChange={doPropertyInvoicesListSetOrdering}
                      currentOrdering={ordering}
                      hideFooter={
                        propertyInvoicesList.count <= propertyInvoicesList.pageSize
                      }
                      slots={{ noRowsOverlay: () => InvoicesEmptyState() }}
                    />
                  ) : (
                    <List
                      autoHeight
                      disableColumnMenu
                      columns={columns}
                      loading={propertyInvoicesListIsLoading}
                      rows={propertyInvoicesList.results}
                      page={propertyInvoicesList.current}
                      pageSize={propertyInvoicesList.pageSize}
                      pageChange={doPropertyInvoicesListSetPage}
                      pageSizeChange={doPropertyInvoicesListSetPageSize}
                      rowCount={propertyInvoicesList.count}
                      sortChange={doPropertyInvoicesListSetOrdering}
                      currentOrdering={ordering}
                      hideFooter={
                        propertyInvoicesList.count <= propertyInvoicesList.pageSize
                      }
                      slots={{ noRowsOverlay: () => InvoicesEmptyState() }}
                    />
                  )}
                </Box>
              )}
            </>
          </Box>
        </Box>
      </Box>
    </>
  )
}
