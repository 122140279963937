import { useCallback } from 'react'

import { Box, Divider, Stack, Tooltip, Typography } from '@mui/material'

import { useSmallScreen } from '@common/utils'
import accountUrls from '@portal/pages/Accounts/urls'
import orgUrls from '@portal/pages/Organizations/urls'
import userUrls from '@portal/pages/Users/urls'
import DetailItem from '@portal/UI/components/DetailItem'

import DetailContainer from './DetailContainer'

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {string} props.label
 * @param {number} props.value
 */
function EntityCount({ label, value, total }) {
  const isSmallScreen = useSmallScreen()

  const pctValue = total ? Math.round((value / total) * 100) : 0
  const toolTipText = `${label}: ${pctValue}%`
  return (
    <Box sx={{ textAlign: 'center' }}>
      <Tooltip placement="top" title={toolTipText}>
        <Box>
          <Typography
            noWrap
            variant="body2"
            color="text.secondary"
            textTransform="uppercase"
            sx={{ fontSize: isSmallScreen ? 10 : 13 }}
          >
            {label}
          </Typography>
          <Typography variant="caption">{value || 0}</Typography>
        </Box>
      </Tooltip>
    </Box>
  )
}

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {Object} props.property
 * @param {string} [props.property.csmOwnerName]
 * @param {string} [props.property.csmOwnerId]
 * @param {string} props.property.organization
 * @param {string} props.property.organizationName
 * @param {string} props.property.account
 * @param {string} props.property.accountName
 * @param {string} [props.property.groupName]
 * @param {string} [props.property.timezone]
 * @param {number} [props.property.unitCount]
 * @param {number} [props.property.deviceCount]
 * @param {string} [props.property.address1]
 * @param {string} [props.property.address2]
 * @param {string} [props.property.city]
 * @param {string} [props.property.state]
 * @param {string} [props.property.zipCode]
 * @param {number} [props.margin]
 * @param {number} [props.gap]
 */
export default function DetailsComponent({ property, margin = 0, gap = 0 }) {
  const isSmallScreen = useSmallScreen()

  const buildAddressText = useCallback(() => {
    const addressArr = []
    const locationArr = []
    if (property?.address1) addressArr.push(property.address1)
    if (property?.address2) addressArr.push(property.address2)
    if (property?.city) locationArr.push(property.city)
    if (property?.state) locationArr.push(property.state)
    if (property?.zipCode) locationArr.push(property.zipCode)

    if (!addressArr.length && !locationArr.length) {
      return null
    }

    return [addressArr.join(', '), locationArr.join(' ')].join(', ')
  }, [property])

  return (
    <DetailContainer margin={margin} gap={gap}>
      <DetailItem
        label="CSM Owner"
        value={property?.csmOwnerName}
        linkTo={userUrls.entity.replace(':id', property?.csmOwnerId)}
      />
      <DetailItem
        label="Organization"
        value={property?.organizationName}
        linkTo={orgUrls.entity.replace(':id', property?.organization)}
      />
      <DetailItem
        label="Account"
        value={property?.accountName}
        linkTo={accountUrls.entity.replace(':id', property?.account)}
      />
      {property?.groupName && <DetailItem label="Group" value={property?.groupName} />}
      <DetailItem label="Timezone" value={property?.timezone} />
      <DetailItem label="Address" value={buildAddressText()} />
      <DetailItem
        label="Total Devices"
        value={property}
        renderValue={(value) => (
          <Box width="fit-content">
            <Typography
              variant="h6"
              whiteSpace="pre-line"
              sx={{ fontSize: isSmallScreen ? 14 : 20 }}
            >
              {value?.deviceCount || 0}
            </Typography>
            {value?.deviceCount > 0 && (
              <>
                <Divider />
                <Stack spacing={3} direction="row">
                  <EntityCount
                    label="Online"
                    value={value?.onlineSensorCount}
                    total={value?.deviceCount}
                  />
                  <EntityCount
                    label="Offline"
                    value={value?.offlineSensorCount}
                    total={value?.deviceCount}
                  />
                  <EntityCount
                    label="Installed"
                    value={value?.installedSensorCount}
                    total={value?.deviceCount}
                  />
                  <EntityCount
                    label="RMA"
                    value={value?.rmaSensorCount}
                    total={value?.deviceCount}
                  />
                  <EntityCount
                    label="RMA In Progress"
                    value={value?.rmaInProgressSensorCount}
                    total={value?.deviceCount}
                  />
                  <EntityCount
                    label="Shipped"
                    value={value?.shippedSensorCount}
                    total={value?.deviceCount}
                  />
                  <EntityCount
                    label="Other"
                    value={value?.otherSensorCount}
                    total={value?.deviceCount}
                  />
                </Stack>
              </>
            )}
          </Box>
        )}
      />
      <DetailItem
        label="Total Units"
        value={property}
        renderValue={(value) => (
          <Box width="fit-content">
            <Typography
              variant="h6"
              whiteSpace="pre-line"
              sx={{ fontSize: isSmallScreen ? 14 : 20 }}
            >
              {value?.unitCount || 0}
            </Typography>
            {value?.unitCount > 0 && (
              <>
                <Divider />
                <Stack spacing={3} direction="row">
                  <EntityCount
                    label="Online"
                    value={value?.onlineUnitCount}
                    total={value?.unitCount}
                  />
                  <EntityCount
                    label="Offline"
                    value={value?.offlineUnitCount}
                    total={value?.unitCount}
                  />
                  <EntityCount
                    label="Installed"
                    value={value?.installedUnitCount}
                    total={value?.unitCount}
                  />
                  <EntityCount
                    label="RMA"
                    value={value?.rmaUnitCount}
                    total={value?.unitCount}
                  />
                  <EntityCount
                    label="Provisioned"
                    value={value?.provisionedUnitCount}
                    total={value?.unitCount}
                  />
                  <EntityCount
                    label="Failure"
                    value={value?.failureUnitCount}
                    total={value?.unitCount}
                  />
                  <EntityCount
                    label="No Device"
                    value={value?.noDeviceUnitCount}
                    total={value?.unitCount}
                  />
                  <EntityCount
                    label="Disabled"
                    value={value?.disabledUnitCount}
                    total={value?.unitCount}
                  />
                  <EntityCount
                    label="Awaiting Service"
                    value={value?.awaitingServiceUnitCount}
                    total={value?.unitCount}
                  />
                </Stack>
              </>
            )}
          </Box>
        )}
      />
    </DetailContainer>
  )
}
