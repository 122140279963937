import { useCallback, useEffect, useMemo, useState } from 'react'

import { useConnect } from 'redux-bundler-hook'

import { InfoOutlined } from '@mui/icons-material'
import { Box, Chip, Link, Tooltip, Typography } from '@mui/material'

import { titleize, underscore } from 'inflection'

import {
  Breadcrumbs,
  ClickableCell,
  ErrorComponent,
  MobileList,
  MobileListDefaultCard,
} from '@common/components'
import { DynamicSelect, StaticSelect } from '@common/components/Selects'
import { humanizePropertyType, useQueryFilter, useSmallScreen } from '@common/utils'
import { homeUrls } from '@portal/pages/Home'
import propertiesUrls from '@portal/pages/Properties/urls'
import { smokeProfileEnsemblesUrls } from '@portal/pages/SmokeProfileEnsembles'
import smokeProfilesUrls from '@portal/pages/SmokeProfiles/urls'
import Filter from '@portal/UI/components/Filter'
import List from '@portal/UI/components/List'
import ListPageTitle from '@portal/UI/components/ListPageTitle'
import boolOptions from '@portal/Utils/constants'

import AlertingProfileForm from './AlertingEnsembleForm'
import ModifyProfileForm from './ModifyProfileForm'
import ShadowProfileForm from './ShadowProfileForm'

const ALERTING_ENSEMBLE_HINT =
  'The ensemble that is currently sending alerts out for this property visible to end-users. "Default" refers to the "Default Alerting" ensemble from the Ensembles List.'
const SHADOW_PROFILES_HINT =
  'Profiles currently running in the background, not visible to end-users, for internal evaluation.'

export default function PropertySmokeProfiles() {
  const defaultQueryParams = { page: 1, pageSize: 500, active: true }

  const [alertingFormData, setAlertingFormData] = useState(null)
  const [shadowFormData, setShadowFormData] = useState(null)
  const [editFormData, setEditFormData] = useState(null)
  const [selectedIds, setSelectedIds] = useState([])
  const [filterModalOpen, setFilterModalOpen] = useState(false)

  const {
    doPropertySmokeProfileListClearParams,
    doPropertySmokeProfileListSetFilter,
    doPropertySmokeProfileListSetPage,
    doPropertySmokeProfileListSetPageSize,
    doPropertySmokeProfileListSetOrdering,
    doMarkPropertySmokeProfileListAsOutdated,
    propertySmokeProfileList,
    propertySmokeProfileListRaw: { ordering = [] },
    propertySmokeProfileListIsLoading,
    propertySmokeProfileListApiParams,
    systemSmokeProfiles,
    systemSmokeProfileEnsembles,
    systemPropertyTypes,
    isSuperuser,
    doUpdateQuery,
    doUpdateUrl,
    queryObject,
  } = useConnect(
    'doPropertySmokeProfileListClearParams',
    'doPropertySmokeProfileListSetFilter',
    'doPropertySmokeProfileListSetPage',
    'doPropertySmokeProfileListSetPageSize',
    'doPropertySmokeProfileListSetOrdering',
    'doMarkPropertySmokeProfileListAsOutdated',
    'selectPropertySmokeProfileList',
    'selectPropertySmokeProfileListRaw',
    'selectPropertySmokeProfileListIsLoading',
    'selectPropertySmokeProfileListApiParams',
    'selectSystemSmokeProfiles',
    'selectSystemSmokeProfileEnsembles',
    'selectSystemPropertyTypes',
    'selectIsSuperuser',
    'doUpdateQuery',
    'doUpdateUrl',
    'selectQueryObject',
  )

  const isSmallScreen = useSmallScreen()

  useQueryFilter({
    query: queryObject,
    apiParams: propertySmokeProfileListApiParams,
    setFilter: doPropertySmokeProfileListSetFilter,
    setPageSize: doPropertySmokeProfileListSetPageSize,
    defaultParams: defaultQueryParams,
  })

  useEffect(() => {
    doPropertySmokeProfileListClearParams()
    doUpdateQuery(defaultQueryParams)
  }, [])

  const handleClear = () => {
    doPropertySmokeProfileListClearParams()
    doPropertySmokeProfileListSetFilter()
  }

  const handlePageChange = async (page) => {
    doPropertySmokeProfileListSetPage(page)
  }

  const handlePageSizeChange = (size) => {
    doUpdateQuery({
      ...queryObject,
      pageSize: size,
    })
  }

  const handleRowClick = (item) => {
    const propertyRoute = propertiesUrls.entity.replace(':id', item.id)
    doUpdateUrl(propertyRoute)
  }

  const handleModifyAlertingEnsemble = (ids) =>
    setAlertingFormData(
      propertySmokeProfileList.results.filter((item) => ids.includes(item.id)),
    )

  const handleAddProfile = (ids) =>
    setShadowFormData({
      properties: propertySmokeProfileList.results.filter((item) =>
        ids.includes(item.id),
      ),
      operation: 'add',
    })

  const handleRemoveProfile = (ids) =>
    setShadowFormData({
      properties: propertySmokeProfileList.results.filter((item) =>
        ids.includes(item.id),
      ),
      operation: 'remove',
    })

  const handleEditProperty = (row) => setEditFormData(row)

  const getProfileName = useCallback(
    ({ id }) => {
      const profileData = systemSmokeProfiles.find((profile) => profile.id === id)
      if (profileData) {
        return `${profileData.modelKey} - ${profileData.name}`
      }
      return null
    },
    [systemSmokeProfiles],
  )

  const getDefaultEnsemble = useCallback(
    () =>
      systemSmokeProfileEnsembles.find(
        (profile) => profile.status === 'DEFAULT_ALERTING',
      ),
    [systemSmokeProfileEnsembles],
  )

  const getEnsembleName = useCallback(
    ({ id, showDefault = true }) => {
      const profileData = systemSmokeProfileEnsembles.find(
        (ensemble) => ensemble.id === id,
      )
      if (profileData) {
        return profileData.name
      }
      if (showDefault) {
        const defaultProfile = getDefaultEnsemble()
        return defaultProfile ? `${defaultProfile.name} (default)` : id
      }
      return null
    },
    [systemSmokeProfileEnsembles],
  )

  const ensemblesOptions = useMemo(
    () =>
      systemSmokeProfileEnsembles
        .filter((item) => item.status !== 'ARCHIVED')
        .map((item) => ({ id: item.id, label: item.name })),
    [systemSmokeProfileEnsembles],
  )

  const renderInfoHeader = useCallback(
    ({ colDef, tooltip }) => (
      <Box display="flex" gap={0.5} overflow="hidden">
        <Tooltip title={tooltip}>
          <InfoOutlined fontSize="small" color="primary" />
        </Tooltip>
        <Typography
          variant="body2"
          fontWeight="500"
          textOverflow="ellipsis"
          overflow="hidden"
          whiteSpace="nowrap"
        >
          {colDef.headerName}
        </Typography>
      </Box>
    ),
    [],
  )

  const listActions = isSuperuser
    ? {
        bulk: [
          {
            action: handleModifyAlertingEnsemble,
            title: 'Modify Alerting Ensemble',
          },
          { action: handleAddProfile, title: 'Add Shadow Profile' },
          { action: handleRemoveProfile, title: 'Remove Shadow Profile' },
        ],
        update: handleEditProperty,
      }
    : null

  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      isMainCell: true,
      flex: 0.7,
    },
    {
      field: 'alertingSmokeProfileEnsemble',
      headerName: 'Alerting Ensemble',
      sortable: false,
      flex: 0.8,
      renderHeader: ({ colDef }) =>
        renderInfoHeader({ colDef, tooltip: ALERTING_ENSEMBLE_HINT }),
      renderCell: ({ row }) => (
        <ClickableCell
          label={getEnsembleName({ id: row.alertingSmokeProfileEnsemble })}
          url={smokeProfileEnsemblesUrls.entity.replace(
            ':id',
            row.alertingSmokeProfileEnsemble ?? getDefaultEnsemble()?.id,
          )}
          fontStyle={row.alertingSmokeProfileEnsemble ? 'normal' : 'italic'}
          color={row.alertingSmokeProfileEnsemble ? null : 'grey.700'}
          renderNoValue={() => (
            <Typography fontSize={14} fontStyle="italic" color="grey.700">
              Default
            </Typography>
          )}
        />
      ),
    },
    {
      field: 'shadowSmokeProfiles',
      headerName: 'Shadow Profiles',
      sortable: false,
      flex: 1,
      maxWidth: 400,
      renderHeader: ({ colDef }) =>
        renderInfoHeader({ colDef, tooltip: SHADOW_PROFILES_HINT }),
      renderCell: ({ row }) =>
        row.shadowSmokeProfiles.length > 0 ? (
          <Box
            display="flex"
            flexDirection="row"
            flexWrap="wrap"
            sx={{ px: 1, pt: 0.5, pb: 1 }}
          >
            {row.shadowSmokeProfiles.map((profile) => (
              <Chip
                key={profile}
                label={getProfileName({ id: profile })}
                variant="outlined"
                size="small"
                color="primary"
                onClick={() =>
                  doUpdateUrl(smokeProfilesUrls.entity.replace(':id', profile))
                }
                sx={{ mr: 1, mt: 0.5 }}
              />
            ))}
          </Box>
        ) : (
          '--'
        ),
      renderMobile: ({ row }) =>
        row.shadowSmokeProfiles.length > 0 ? (
          <Box
            display="flex"
            flexDirection="column"
            flexWrap="wrap"
            justifyContent="start"
            sx={{ py: 0.5 }}
          >
            {row.shadowSmokeProfiles.map((profile) => (
              <Typography
                key={profile}
                variant="caption"
                onClick={() =>
                  doUpdateUrl(smokeProfilesUrls.entity.replace(':id', profile))
                }
                color="primary"
                sx={{ textDecoration: 'underline' }}
              >
                {getProfileName({ id: profile }) || '--'}
              </Typography>
            ))}
          </Box>
        ) : (
          '--'
        ),
    },
  ]

  const rows = propertySmokeProfileList?.results?.map((data) => ({
    id: data.id,
    name: data.name,
    shadowSmokeProfiles: data.shadowSmokeProfiles,
    alertingSmokeProfileEnsemble: data.alertingSmokeProfileEnsemble,
  }))

  const MobileItemHeader = useCallback(
    ({ row }) => (
      <Box display="flex" alignItems="center" sx={{ '&&': { mb: 1 } }}>
        <Link href={propertiesUrls.entity.replace(':id', row.id)}>
          <Typography variant="caption" fontWeight="bold">
            {row.name}
          </Typography>
        </Link>
      </Box>
    ),
    [],
  )

  const MobileListItem = useCallback(
    (row) =>
      MobileListDefaultCard({
        row,
        columns,
        ignoredFields: ['name'],
        rowStyleBuilder: () => ({ mode: 'vertical' }),
        header: <MobileItemHeader row={row} />,
      }),
    [columns],
  )

  if (!propertySmokeProfileList.results) {
    return <ErrorComponent title="Property Smoke Profiles" callback={handleClear} />
  }

  return (
    <>
      <AlertingProfileForm
        open={!!alertingFormData}
        onClose={(success) => {
          setAlertingFormData(null)
          if (success === true) {
            setSelectedIds([])
            doMarkPropertySmokeProfileListAsOutdated()
          }
        }}
        properties={alertingFormData}
      />
      <ShadowProfileForm
        open={!!shadowFormData}
        onClose={(success) => {
          setShadowFormData(null)
          if (success === true) {
            setSelectedIds([])
            doMarkPropertySmokeProfileListAsOutdated()
          }
        }}
        operation={shadowFormData?.operation}
        properties={shadowFormData?.properties}
      />
      <ModifyProfileForm
        open={!!editFormData}
        onClose={(success) => {
          setEditFormData(null)
          if (success === true) {
            doMarkPropertySmokeProfileListAsOutdated()
          }
        }}
        data={editFormData}
      />
      <Box m={3} display="flex" flexDirection="column">
        <Breadcrumbs
          links={[
            { label: 'Home', href: homeUrls.home },
            { label: 'Property Smoke Profiles' },
          ]}
        />
        <ListPageTitle
          title="Property Smoke Profiles"
          onFilterPressed={isSmallScreen ? () => setFilterModalOpen(true) : null}
          menuItems={
            isSmallScreen && isSuperuser && selectedIds?.length
              ? [
                  {
                    label: 'Modify Alerting Ensemble',
                    onClick: handleModifyAlertingEnsemble,
                  },
                  { label: 'Add Shadow Profile', onClick: handleAddProfile },
                  { label: 'Remove Shadow Profile', onClick: handleRemoveProfile },
                ]
              : null
          }
          mb={2}
        />
        <Box display="flex">
          <Filter
            mode={isSmallScreen ? 'modal' : 'drawer'}
            disabled={propertySmokeProfileListIsLoading}
            apiParams={propertySmokeProfileListApiParams}
            setApiParams={doUpdateQuery}
            dialogOpen={filterModalOpen}
            dialogOnClose={() => setFilterModalOpen(false)}
          >
            <DynamicSelect label="Organizations" filterName="organization" />
            <DynamicSelect label="Accounts" filterName="account" />
            <StaticSelect
              label="Property Types"
              filterName="propertyType"
              options={systemPropertyTypes}
              optionLabelFormatter={humanizePropertyType}
            />
            <StaticSelect
              label="Integration enabled"
              filterName="integration"
              options={['email_ingestion', 'smoke_autocharge']}
              optionLabelFormatter={(val) => titleize(underscore(val))}
            />
            <StaticSelect
              multiple
              label="Alerting Ensemble"
              filterName="alertingSmokeProfileEnsemble"
              options={[{ id: 'null', label: 'Default' }, ...ensemblesOptions]}
            />
            <StaticSelect label="Active" filterName="active" options={boolOptions} />
          </Filter>
          <Box ml={isSmallScreen ? 0 : 2} flex={1} overflow="hidden" minHeight="900px">
            {isSmallScreen ? (
              <MobileList
                queryDrivenSearch
                dynamicRowHeight
                title="Properties"
                loading={propertySmokeProfileListIsLoading}
                actions={listActions}
                itemBuilder={MobileListItem}
                page={propertySmokeProfileList.current || 1}
                pageChange={handlePageChange}
                pageSize={propertySmokeProfileList.pageSize}
                pageSizeChange={handlePageSizeChange}
                rows={rows}
                rowClick={handleRowClick}
                rowCount={propertySmokeProfileList.count || 0}
                rowSelectionModel={selectedIds}
                onRowSelectionModelChange={setSelectedIds}
                pageSizeOptions={[10, 25, 50, 100, 250, 500]}
              />
            ) : (
              <List
                columnsAutosize
                queryDrivenSearch
                dynamicRowHeight
                title="Properties"
                showAddButton={false}
                loading={propertySmokeProfileListIsLoading}
                actions={listActions}
                columns={columns}
                page={propertySmokeProfileList.current || 1}
                pageChange={handlePageChange}
                pageSize={propertySmokeProfileList.pageSize}
                pageSizeChange={handlePageSizeChange}
                rows={rows}
                rowClick={handleRowClick}
                rowCount={propertySmokeProfileList.count || 0}
                rowSelectionModel={selectedIds}
                onRowSelectionModelChange={setSelectedIds}
                sortChange={doPropertySmokeProfileListSetOrdering}
                currentOrdering={ordering}
                pageSizeOptions={[10, 25, 50, 100, 250, 500]}
              />
            )}
          </Box>
        </Box>
      </Box>
    </>
  )
}
