import { path } from 'ramda'

import { isAbortError, toCamelCase } from '@common/utils'
import metrics from '@portal/pages/SmokeMetrics/metrics'

const PROPERTY_EVENTS_METRICS_LOADING = 'PROPERTY_EVENTS_METRICS_LOADING'
const PROPERTY_EVENTS_METRICS_LOADED = 'PROPERTY_EVENTS_METRICS_LOADED'
const PROPERTY_EVENTS_METRICS_FAILED = 'PROPERTY_EVENTS_METRICS_FAILED'

const PROPERTY_EVENTS_RESET_STATE = 'PROPERTY_EVENTS_RESET_STATE'

const defaultState = {}

const entityName = 'propertyEvents'

export const metricsSummaryEntries = [
  metrics.billableEventsTotal,
  metrics.billableEventsCharged,
  metrics.billableNetChargeRate,
  metrics.netCharges,
  metrics.netChargesPerBillableEvent,
]

async function fetchMetricsSummary({ apiFetch, payload }) {
  const { property, range } = payload
  const [startTime, endTime] = range

  const metricsToFetch = metricsSummaryEntries.map((item) => item.apiValue)

  const result = metricsToFetch.reduce(
    (acc, metric) => ({ ...acc, [toCamelCase(metric)]: 'N/A' }),
    {},
  )

  const metricsResponse = await apiFetch(
    `/reports/chart/`,
    {
      properties: [property],
      start: startTime.toFormat('yyyy-MM-dd'),
      end: endTime.toFormat('yyyy-MM-dd'),
      interval: 1,
      intervalType: 'days',
      includeTotals: true,
      metrics: metricsToFetch,
    },
    { method: 'GET', cancelationPrefix: entityName },
  )

  if (Array.isArray(metricsResponse)) {
    metricsResponse.forEach((metricData) => {
      const formattedMetricName = toCamelCase(metricData.metric)
      result[formattedMetricName] = metricData?.total || 'N/A'
    })
  }

  return result
}

export default {
  name: entityName,
  reducer: (state, action) => {
    if (action.type === PROPERTY_EVENTS_RESET_STATE) {
      return defaultState
    }
    if (action.type === PROPERTY_EVENTS_METRICS_LOADING) {
      return { ...state, metrics: { ...action.meta, payload: action.payload } }
    }
    if (action.type === PROPERTY_EVENTS_METRICS_LOADED) {
      return { ...state, metrics: { ...action.meta, data: action.payload } }
    }
    if (action.type === PROPERTY_EVENTS_METRICS_FAILED) {
      return { ...state, metrics: { ...action.meta, error: action.payload } }
    }
    return state || defaultState
  },
  selectPropertyEventsAdjustmentSummaryIsLoading: ({ propertyEvents }) => {
    const status = path(['summary', 'status'], propertyEvents)
    return status === 'loading'
  },
  selectPropertyEventsMetricsIsLoading: ({ propertyEvents }) => {
    const status = path(['metrics', 'status'], propertyEvents)
    return status === 'loading'
  },
  selectPropertyEventsAdjustmentSummary: ({ propertyEvents }) =>
    path(['summary', 'data'], propertyEvents),
  selectPropertyEventsMetrics: ({ propertyEvents }) =>
    path(['metrics', 'data'], propertyEvents),
  selectPropertyEventsAdjustmentSummaryError: ({ propertyEvents }) =>
    path(['summary', 'error'], propertyEvents),
  selectPropertyEventsMetricsError: ({ propertyEvents }) =>
    path(['metrics', 'error'], propertyEvents),
  doResetPropertyEventsState:
    () =>
    ({ dispatch }) =>
      dispatch({ type: PROPERTY_EVENTS_RESET_STATE }),
  doFetchPropertyEventsMetrics:
    (payload) =>
    async ({ dispatch, apiFetch }) => {
      try {
        dispatch({
          type: PROPERTY_EVENTS_METRICS_LOADING,
          payload,
          meta: { status: 'loading' },
        })

        const metricsResult = await fetchMetricsSummary({ apiFetch, payload })

        await dispatch({
          type: PROPERTY_EVENTS_METRICS_LOADED,
          payload: metricsResult,
          meta: { status: 'succeeded' },
        })
        return metricsResult
      } catch (err) {
        if (!isAbortError(err)) {
          dispatch({
            type: PROPERTY_EVENTS_METRICS_FAILED,
            payload: err,
            meta: { status: 'failed' },
          })
          throw err
        }
        return null
      }
    },
}
