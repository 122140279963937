import { createEntityBundle } from '@common/utils'

const entityName = 'event_feedback'

const eventFeedbackBundle = createEntityBundle({
  name: entityName,
  customActions: [
    {
      actionName: 'create',
      handler: ({ apiFetch, payload, store }) => {
        const { id: propertyId } = store.selectCurrentProperty()
        return apiFetch(
          '/event_feedback/',
          { property: propertyId, ...payload },
          { method: 'POST', cancelationPrefix: entityName },
        )
      },
    },
    {
      actionName: 'can_waive',
      handler: ({ apiFetch, payload }) =>
        apiFetch(`/events/${payload}/can_waive/`, null, {
          method: 'GET',
          cancelationPrefix: entityName,
        }),
    },
    {
      actionName: 'remove_event',
      handler: ({ apiFetch, payload }) =>
        apiFetch(`/events/${payload}/`, null, {
          method: 'DELETE',
          cancelationPrefix: entityName,
        }),
    },
  ],
})

export default eventFeedbackBundle
