import { memo } from 'react'

import { Grid2 } from '@mui/material'

import EntityCard from '../EntityCard'

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {Object} props.groupedByEntityData
 * @param {Object} props.selectedMetrics
 */
const GroupedByEntityView = memo(({ groupedByEntityData, selectedMetrics }) =>
  Object.entries(groupedByEntityData).map(([key, data]) => (
    <Grid2 key={key} size={{ xll: 6, sm: 12 }}>
      <EntityCard dataKey={key} selectedMetrics={selectedMetrics} data={data} />
    </Grid2>
  )),
)

export default GroupedByEntityView
