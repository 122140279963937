import { DownloadRounded, PaymentRounded, WysiwygRounded } from '@mui/icons-material'
import { Box, Button, Stack } from '@mui/material'

import { useSmallScreen } from '@common/utils'

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {Object} [props.invoice]
 * @param {'list'|'modal'} props.mode
 */
export default function InvoiceActions({ invoice, mode }) {
  const isSmallScreen = useSmallScreen()

  if (!invoice) return null
  if (invoice.invoicePaymentStatus === 'VOIDED') {
    return mode === 'list' && !isSmallScreen ? (
      <Box display="flex" justifyContent="center" width={1}>
        --
      </Box>
    ) : null
  }

  const actionType = ['WAITING_FOR_PAYMENT', 'PAST_DUE'].includes(
    invoice.invoicePaymentStatus,
  )
    ? 'pay'
    : 'view'

  return (
    <Stack
      direction="row"
      spacing={2}
      justifyContent="flex-end"
      width="100%"
      pt={isSmallScreen ? 1 : 0}
    >
      <Button
        fullWidth={isSmallScreen}
        variant="contained"
        startIcon={actionType === 'pay' ? <PaymentRounded /> : <WysiwygRounded />}
        size="small"
        target="_blank"
        href={invoice.hostedInvoiceUrl}
        disabled={!invoice.hostedInvoiceUrl}
        sx={{ textAlign: 'center' }}
      >
        {actionType === 'pay' ? 'Pay Invoice' : 'View Receipt'}
      </Button>
      <Button
        fullWidth={isSmallScreen}
        variant="contained"
        startIcon={<DownloadRounded />}
        size="small"
        target="_blank"
        href={invoice.invoicePdfUrl}
        disabled={!invoice.invoicePdfUrl}
        sx={{ textAlign: 'center' }}
      >
        Download PDF
      </Button>
    </Stack>
  )
}
