/* eslint-disable import/prefer-default-export */
import { titleize } from 'inflection'

import {
  CATEGORY_CO2_TVOC,
  CATEGORY_ENVIRONMENT,
  CATEGORY_PM,
  CATEGORY_PM_RATIOS,
  CATEGORY_VOC_NOX,
} from '@common/utils/grafanaViewDataProcessor'

export function humanizeCategory(category) {
  switch (category) {
    case CATEGORY_PM:
      return 'PM (subtracted)'
    case CATEGORY_PM_RATIOS:
      return 'PM Ratios'
    case CATEGORY_ENVIRONMENT:
      return 'Environment'
    case CATEGORY_CO2_TVOC:
      return 'CO2/TVOC'
    case CATEGORY_VOC_NOX:
      return 'VOC/NOX'
    default:
      return titleize(category)
  }
}
